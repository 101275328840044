@import "../../styles/colors";
@import "../../styles/typography";

.panel {
  flex: 1;
  position: relative;

  &--left {
    flex: 7;
  }

  &--left-long {
    flex: 8;
    display: flex;
  }

  &--center {
    flex: 3;
  }

  &--trades {
    flex: none;
    width: 550px;
    overflow-x: hidden;
    border-left: 6px solid $off-black;

    &.trades-with-composer {
      @media (max-width: $breakpoint-trades-with-composer-off) {
        width: 100%;

        .panel {
          &__inner {
            margin-top: 20px;
          }

          &--nav {
            display: unset;
          }
        }

        .table__wrapper {
          margin-top: 20px;
        }
      }
    }

    @media (max-width: $breakpoint-trades) {
      width: 100%;

      .panel__inner {
        margin-top: 20px;

        textarea {
          margin-top: 30px;
        }
      }

      .table__wrapper {
        margin-top: 20px;
      }
    }

    .panel {
      &__inner {
        overflow-x: hidden;

        @media (max-width: 767.98px) {
          overflow: scroll;
        }
      }

      &--nav {
        z-index: 1;
        width: calc(100% + 1.5rem - 2px);
        margin-left: -12px;
        margin-top: -20px;

        @media (min-width: $breakpoint-trades-off) {
          display: none;
        }

        .nav__item--minimize {
          padding-right: 2.5px;
        }
      }
    }
  }

  &--trader {
    flex: none;
    width: 500px;
    overflow-x: hidden;
    border-left: 6px solid $off-black;

    &.full-trader-panel {
      width: 100%;

      .panel__inner {
        margin-top: 20px;

        textarea {
          margin-top: 30px;
        }
      }

      .table__wrapper {
        margin-top: 20px;
      }
    }

    .panel {
      &__inner {
        overflow-x: hidden;

        @media (max-width: 767.98px) {
          overflow: scroll;
        }
      }

      &--nav {
        z-index: 1;
        width: calc(100% + 1.5rem - 2px);
        margin-left: -12px;
        margin-top: -20px;

        .nav__item--minimize {
          padding-right: 2.5px;
        }
      }
    }
  }

  &--right {
    flex: 3;
  }

  &--editor {
    .panel {
      &__scrollable {
        height: 100%;
      }
    }
  }

  &--bots {
    .panel {
      &__inner {
        overflow: unset;
        height: 100%;
      }
    }
  }

  &__inner {
    display: flex;
    flex-flow: column;
    flex: 1;
    margin: 0rem 0.75rem 0rem 0.75rem;
    height: 100%;
  }

  &__scrollable {
    height: unset;
    flex: unset;
  }
}

.panel-action {
  position: absolute;
  top: 0.75rem;
  right: 3.5rem;
  border: 1px solid $off-white;
  padding: 0.5rem 0.75rem;
  text-transform: capitalize;
  font-size: $font-size-sm;
  font-weight: 400;

  &:hover {
    background-color: $blue;
    border: 1px solid $blue;
    color: $white;
  }
}
