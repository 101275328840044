@import "styles/colors";

.track-horizontal,
.track-vertical {
  z-index: 2;
  position: absolute;
  border-radius: 2px;
  margin: 5px;
}

.track-horizontal {
  height: 8px !important;
  right: 2px;
  bottom: 4px;
  left: 2px;
}

.track-vertical {
  width: 8px !important;
  right: 4px;
  bottom: 2px;
  top: 2px;
}

.thumb-horizontal,
.thumb-vertical {
  position: relative;
  display: block;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #5b5b60;
  background-color: #171717;
  opacity: 0.5;
  transition: opacity 200ms ease 0s;
}

.panel {
  &:hover {
    & > div {
      & > .track-horizontal,
      & > .track-vertical {
        .thumb-horizontal,
        .thumb-vertical {
          opacity: unset !important;
          background-color: #5b5b60 !important;
        }
      }
    }
  }
}
