@import "../../../styles/colors";
@import "../../../styles/typography";

.user-page {
  &__scripts {
    display: flex;
    flex-direction: column;

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    .load-button {
      margin: 0 auto 20px;
    }
  }

  .public-script {
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    font-style: normal;
    width: 470px;
    margin-right: 30px;
    margin-bottom: 30px;
    background-color: $off-black;
    border-left: 6px solid $pine;
    border-radius: 15px;

    &:hover {
      cursor: pointer;
      background-color: $dark;
    }

    &.alternate {
      .public-script {
        &--row {
          .author {
            font-size: $font-size-sm;
            font-weight: 500;
            color: $off-white;
            margin-bottom: 10px;
          }

          .saved {
            margin-right: auto;
          }
        }
      }
    }

    @media (max-width: 767.98px) {
      width: 100%;
      margin-right: 0;
    }

    &--row {
      display: flex;
      font-weight: 500;

      .language {
        width: 15px;
        height: 11px;
        margin-right: 6px;
        margin-top: 4px;
      }

      .name {
        color: $light-gray;
        font-size: $font-size-mdlg;
        margin-right: auto;
        line-height: 19px;
      }

      .date,
      .type {
        color: $off-white;
        font-size: 13px;
        white-space: nowrap;
      }

      .saved {
        color: $light-gray;
        font-size: 13px;
      }
    }
  }
}
