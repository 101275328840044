@import "../../styles/colors";
@import "../../styles/typography";

.asset-explorer {
  max-width: 350px;
  border-left: 6px solid $off-black;
  margin-left: 2px;
  margin-bottom: -2px;
  position: relative;

  &--empty {
    display: flex;
    flex-direction: column;
    background: repeating-linear-gradient(
      0deg,
      $black,
      $black 60px,
      $off-black 62px,
      $black 1px
    );
  }

  &--title,
  &--exchange,
  &--account,
  &--total {
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-xs;
    padding: 7px 15px 3px;
    white-space: nowrap;
  }

  &--title,
  &--account,
  &--total {
    color: $off-white;
  }

  &--title {
    padding: 8px 9px 6px;
    height: 16px;
    background: $black;
    width: 20px;
  }

  &--exchange-and-credentials {
    display: flex;
    max-width: 180px;
    flex-flow: wrap;
    max-height: 30px;
  }

  &--exchange {
    color: $light-yellow;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--total {
    max-width: 100px;
    margin-left: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    img {
      margin-right: 3px;
      margin-bottom: -1px;
    }
    &:hover {
      cursor: pointer;
      color: $light-gray;
    }
  }

  &--close {
    padding: 0 10px;
    border-left: 2px solid $black;
    width: 12px;

    &.empty-asset-explorer {
      margin-left: auto;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(150%);
    }
  }

  &__table {
    table-layout: fixed;
    border-collapse: collapse;
    font-style: normal;

    thead,
    tbody {
      tr,
      td {
        height: 29px;

        th,
        td {
          padding-left: 20px;
          text-align: left;

          &.balance {
            border-left: 1px solid $off-black;
            border-right: 1px solid $off-black;
          }
        }
      }
    }

    thead {
      font-weight: bold;
      color: $steel;
      font-size: $font-size-xxs;

      tr {
        height: 29px;
        border-bottom: 1px solid $off-black;

        th {
          position: sticky;
          top: 0;
          background: $black;

          &.currency {
            width: 50px;
          }

          &.balance {
            width: 125px;
          }

          &.value {
            width: 170px;

            &.sort {
              color: $off-white;
            }

            &:hover {
              cursor: pointer;
              color: $light-gray;
            }

            i {
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 5px solid $off-white;
              display: inline-block;
              position: absolute;
              margin-left: 3px;

              &.asc {
                transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                margin-top: 3px;
              }
              &.desc {
                margin-top: 3px;
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
              }
            }
          }
        }
      }
    }

    tbody {
      font-weight: 500;
      color: $off-white;
      font-size: $font-size-ss;

      tr {
        &:nth-child(odd) {
          background: $odd-row-color;
        }

        &:nth-child(even) {
          background: $black;
        }

        td {
          &.currency {
            .currency--circle {
              font-size: $font-size-xxxs;
              color: $white;
              border-radius: 25px;
              width: 25px;
              height: 25px;
              line-height: 28px;
              text-align: center;
            }
          }

          &.value {
            img {
              margin-bottom: -2px;
              margin-right: 3px;
            }
          }

          &.balance,
          &.value {
            div {
              padding-top: 2px;
            }
          }
        }
      }
    }
  }

  .top-dropdowns {
    max-height: 30px;
  }

  .top-dropdown {
    max-width: 150px;
    height: 30px;
  }

  .panel {
    height: calc(100% - 60px);

    &__inner {
      margin: 0;
    }
  }

  .refresh {
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-ss;
    color: $steel;
    width: 260px;
    text-align: left;
    padding: 0;
    margin-bottom: 2px;
    position: absolute;
    bottom: 0;
    left: 10px;
    height: 20px;

    img {
      vertical-align: middle;
      margin-bottom: 2px;
      margin-right: 1px;
      padding: 2px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .asset-explorer {
    width: 100%;
    border-left: 0;

    &__table {
      width: 100%;
    }
  }
}
