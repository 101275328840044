@import "../colors";
@import "../typography";

@keyframes rowloading {
  from {
    opacity: 0.7;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

.table {
  &__wrapper {
    margin-left: -12px;

    .hidden-layer {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;

      &:hover {
        cursor: col-resize !important;
      }
    }
  }

  &__count-size {
    position: fixed;
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-xs;
    line-height: 19px;
    right: 15px;
    margin-top: -23px;
    color: $off-white;

    @media (max-width: 767.97px) {
      display: none;
    }

    &.scripts-count {
      position: relative;
      margin-top: 16px;
      margin-left: auto;
      margin-right: 9px;
      right: unset;
      flex-shrink: 0;
    }

    &--with-composer {
      right: 325px;

      &.table__count-size--with-trades {
        right: 325px;

        @media (min-width: $breakpoint-trades-with-composer) {
          right: 875px;
        }
      }
    }

    &.live-with-composer {
      right: 325px;
    }

    &--with-trades {
      right: 565px;

      @media (min-width: $breakpoint-trades-off) {
        &.live-with-composer {
          right: 875px;
        }
      }

      @media (max-width: $breakpoint-trades-off) {
        right: 15px;
      }
    }
  }

  &--compact {
    border-collapse: collapse;
    color: $white;
    text-align: center;
    width: 100%;
    table-layout: fixed;

    &.dragging {
      &:hover {
        cursor: col-resize;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background: $odd-row-color;
        }
      }
    }

    tr {
      .Name,
      .Strategy,
      .table-name-column {
        &:not(th):not(.Version) {
          color: $pale-gray !important;
        }
      }

      .Strategy:not(.Version),
      .Status {
        .as-link {
          padding-left: 22px;
        }
      }

      .Name {
        .as-link {
          padding-left: 53px;
        }
      }

      .add-column {
        width: 46px;
        border-right: 0 !important;

        &:hover,
        &.hover {
          cursor: pointer;
          background: #1e1d1baa;

          img {
            opacity: 1;
          }
        }

        img {
          vertical-align: middle;
          padding: 4px 17px;
          opacity: 0.5;
        }
      }

      td {
        &:not(.Name):not(.multivariant-name):not(.Tags):not(.Strategy):not(.Script.name):not(.TAG_ID):not(.NAME) {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      th,
      td {
        text-align: left;

        &.Candle,
        &.Profit\/Loss,
        &.Profitable.Trades,
        &.Profit,
        &.Buy\&Hold,
        &.Average.Position.Price,
        &.MDD,
        &.\#.Trades,
        &.max-buy,
        &.permutations,
        &.min-profit,
        &.stop-loss,
        &.take-profit,
        &.trailing-take-profit,
        &.Long.Leverage,
        &.Short.Leverage,
        &.Profitable.Months,
        &.Average.Lose.Month,
        &.Average.Win.Month,
        &.Average.Monthly.Profit,
        &.\#,
        &.execution-number,
        &.Allocation,
        &.Position.Amount,
        &.Balance,
        &.Risk.Score,
        &.Active.Subscribers,
        &.Sharpe.Ratio,
        &.Sortino.Ratio,
        &.Total.Realized.Gain,
        &.Total.Realized.Loss,
        &.Consistency.Score,
        &.align-right {
          text-align: right;

          .column-header {
            flex-direction: row-reverse;

            .column-filter {
              margin-right: 10px;
              margin-left: 0;
            }
          }
        }

        &.progress {
          text-align: center;
        }
      }

      .progress {
        padding-right: 20px !important;
        padding-left: 20px !important;
      }

      .blank {
        padding-right: 0 !important;
        padding-left: 0;
        width: 12px;
        background: $black;
      }

      &:hover {
        .action-menu-toggler,
        .action-menu--icon-button {
          visibility: visible;
        }
      }
    }

    tr {
      &.empty-row {
        height: 20px;
      }

      th {
        &:not(.blank) {
          border-bottom: 1px solid $off-black;
        }

        &:hover {
          .column-filter {
            img {
              &:hover {
                opacity: 1;
              }
              animation: show 0.9s;
              opacity: 0.7;
            }
          }
        }
        .column-filter {
          margin-left: 10px;

          img {
            cursor: pointer;
            opacity: 0;
            width: 13px;
            height: 13px;
          }
        }

        &.Creation.Date {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }

    th,
    td {
      font-size: $font-size-xs;
      padding: 2px 0.5rem 1px 0.5rem;

      &:not(last-child):not(.table-row-checkbox) {
        border-right: 1px solid $off-black;
      }

      i {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid $off-white;
        display: inline-block;
        position: absolute;

        &.asc {
          transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          margin-top: 3px;
          margin-left: 2px;
        }
        &.desc {
          margin-top: 3px;
          margin-left: 2px;
          transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
        }

        &.pack-toggle {
          width: 0;
          height: 0;
          margin-top: 7px;
          margin-right: 5px;
          position: relative;

          & + img {
            margin: 0 5px 0 10px;
          }
        }
      }
    }

    th {
      color: $gray;
      font-weight: bold;
      font-size: $font-size-ss;
      letter-spacing: 0.025rem;
      padding: 6px 0.5rem 6px 0.5rem;
      position: sticky;
      top: 0;
      background: $black;
      z-index: 2;

      @media (max-width: 767.98px) {
        padding: 0.1rem 0.5rem 0.1rem 0.5rem;
      }

      &.table-row-checkbox:not(.mvt-checkbox) {
        padding-top: 13px;
        padding-bottom: 13px;
      }

      .column-header {
        display: flex;
        min-height: 16px;

        .right-header {
          margin-left: auto;
          margin-right: 25px;
        }
      }

      .column-divider {
        width: 4px;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;

        &.left-side {
          left: -1px;
        }

        &:not(.left-side) {
          right: -1px;
        }

        &:hover,
        &:active {
          cursor: col-resize;
        }
      }

      .as-link {
        cursor: pointer;
        position: relative;

        &:hover {
          color: $pale-gray;
        }

        .sorted-column {
          color: $off-white;
          margin-right: 5px;

          &:hover {
            color: $pale-gray;
          }
        }
      }
    }

    td {
      color: $off-white;
      font-weight: 400;
      letter-spacing: 0.015rem;
      white-space: nowrap;

      & + div,
      & + span {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.table-row-checkbox {
        padding: 5px 0 0 0;
        height: 16px;

        button {
          height: 16px;
        }
      }

      &:first-child,
      &.table__cell--contract {
        color: $pale-gray;
        text-align: left;
      }

      &.table__cell--signal {
        color: $table__cell--signal;
      }

      &.table__cell--profit {
        color: $table__cell--signal;
      }

      &.table__cell--multi {
        padding: 0 !important;
      }

      &.loading {
        border-bottom: 0px;
      }

      .settings {
        @media (max-width: 767.98px) {
          display: none;
        }
      }

      .outer-status {
        width: 119px;
        text-align: left;
      }

      .execution-name,
      &.multivariant-name {
        display: flex;

        .name {
          flex: 1 1;
          margin-top: 2px;
          margin-right: auto;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .strat-name {
        display: flex;

        &.strat-pack-name {
          .name {
            margin-left: 70px;
          }
        }

        .stage-chevrons {
          padding-bottom: 1px;
          padding-right: 10px;
        }

        .subscription-img {
          padding-bottom: 2px;
          padding-right: 5px;
        }

        .name {
          margin-top: 3px;
          margin-right: 0;
          overflow: hidden;
          text-overflow: ellipsis;

          &.name-flex {
            flex: 1 1;
          }

          &.indent {
            margin-left: 22px;
          }
        }

        .infoIcon {
          flex: 1 1;
          margin-top: 4px;
          margin-right: auto;
          min-width: 14px;
          max-width: 14px;
        }

        .execution-notes-icon {
          padding: 0 0 3px 5px;
        }
      }

      &.multivariant-name {
        .delete {
          padding-right: 6px !important;
        }
      }

      .action-menu {
        position: relative;
        display: inline-flex;

        &-toggler,
        &--icon-button {
          @media (min-width: 1025px) {
            visibility: hidden;
          }
        }

        &--icon-button {
          &:hover {
            filter: grayscale(100%) brightness(232%);
          }

          button {
            padding-left: 5px;
            padding-right: 5px;
          }

          img {
            height: 12px;
            margin-bottom: -3px;
          }
        }

        .action-dropdown {
          position: absolute;
          left: calc(100% + 15px);
          z-index: 1;
          background: $black;
          display: flex;
          flex-direction: column;
          padding: 5px 0 5px 0;
          border: 2px solid $off-black;

          button {
            width: 100%;
            text-align: left;
            padding-top: 1px;
            padding-bottom: 1px;

            &.disabled {
              user-select: none;
              cursor: default;

              .action-option {
                color: $steel;
              }
            }

            &:hover:not(.disabled) {
              background: $iron;

              .action-option {
                color: $white;
              }
            }
          }

          .action-option {
            color: $off-white;
            width: 100%;
            margin-left: 15px;
            margin-right: 15px;
          }
        }

        .action-menu-toggler {
          float: right;
          width: 1px;
          height: 1px;
          border: 1px solid $off-white;
          background: $off-white;
          border-radius: 100px;
          margin-left: 10px;
          margin-top: 10px;
          margin-right: 5px;
          margin-bottom: 10px;
          position: relative;

          &:after,
          &:before {
            width: 1px;
            height: 1px;
            border: 1px solid $off-white;
            background: $off-white;
            border-radius: 100px;
            content: "";
            position: absolute;
            right: -1px;
          }

          &:after {
            bottom: 5px;
          }

          &:before {
            top: 5px;
          }

          &--outer {
            &:hover {
              .action-menu-toggler {
                border-color: $light-gray;
                background: $light-gray;

                &:after,
                &:before {
                  border-color: $light-gray;
                  background: $light-gray;
                }
              }
            }
          }
        }
      }
    }

    tr {
      th,
      td {
        padding-left: 10px;

        &:last-child {
          padding-right: 30px;
          border-right: 0;
        }

        &:not(:last-child) {
          padding-right: 10px;
        }
      }
    }

    table.algo_info {
      td {
        padding: 0 10px 0 0;
        width: unset !important;
        max-width: 250px;
      }

      .label {
        td {
          padding-top: 10px;
          font-size: $font-size-xxs;
          font-weight: bold;
          letter-spacing: 0.025rem;
          color: $gray;
        }
      }

      tr {
        &.clickable {
          td {
            padding-top: 2px;
            padding-left: 5px;
          }

          &:hover {
            background: $charcoal !important;
          }
        }
      }
    }

    tr.as-link {
      cursor: pointer;
      height: 30px;

      &.checked-for-pack {
        background: #121f1f;
      }

      &:hover:not(.not-hovering) {
        background: #1e1d1b;

        .select:not(:hover) {
          img {
            border: 1px solid $steel;
          }

          &.create-pack {
            img {
              border: 1px solid $pale-pine;
            }
          }

          &.create-syndication {
            img {
              border: 1px solid $light-green;
            }
          }
        }

        i {
          border-left: 5px solid $light-gray;
        }
      }
    }

    .vcell {
      padding: 5px 10px;
      white-space: pre;

      &:not(:first-child) {
        border-top: 1px solid $off-black;
      }
    }
  }

  a {
    img {
      opacity: 0.7;
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  &.trades-table {
    table-layout: unset;

    td:first-child.table__cell--contract {
      text-align: unset;
    }

    thead,
    tbody {
      tr {
        td,
        th {
          text-align: right !important;

          &:nth-child(2),
          &:nth-child(6) {
            text-align: left !important;
          }

          &:first-child,
          &:nth-child(3) {
            &:not(.event) {
              text-align: center !important;
            }
          }
        }

        td:not(.table__cell--multi),
        th {
          padding: 0.2rem 0.5rem 0.75rem !important;
        }
      }
    }

    .timestamp {
      &:hover {
        cursor: pointer;
      }

      &__ascending {
        transform: rotate(180deg);
        padding-right: 10px;
      }

      &__descending {
        padding-left: 10px;
      }
    }

    .event {
      text-align: left !important;
      width: 200px;
    }
  }
}

.empty-msg {
  font-weight: 500;
  text-align: left;
  font-size: $font-size-md;
  color: $off-white;
}

.table-row-checkbox {
  padding-right: 0 !important;
  padding-left: 10px !important;
  width: 16px;

  &.create-pack {
    img {
      border: 1px solid $pine;

      &:hover {
        border: 1px solid $pale-pine;
      }
    }
  }

  &.create-syndication {
    img {
      border: 1px solid $flat-green;

      &:hover {
        border: 1px solid $light-green;
      }
    }
  }

  &.mvt-checkbox {
    &:not(.header-checkbox) {
      padding-top: 5px !important;
    }

    &.header-checkbox {
      padding-top: 4px !important;
      padding-bottom: 0px !important;
    }

    button {
      height: 16px;
    }
  }

  .disabled {
    opacity: 0;
  }

  button {
    padding-left: 0;
    padding-right: 0;

    &.create-pack {
      img {
        border: 1px solid $pine;

        &:hover {
          border: 1px solid $pale-pine !important;
        }
      }
    }

    &.create-syndication {
      img {
        border: 1px solid $flat-green;

        &:hover {
          border: 1px solid $light-green;
        }
      }
    }
  }

  img {
    border: 1px solid $off-black;
  }

  img:hover {
    cursor: pointer;
    border: 1px solid $iron;
  }
}

.execution-type-img {
  padding-top: 5px;
}

.small-laptop {
  display: none;

  @media (max-width: $breakpoint-trades) {
    display: unset;

    &.disabled {
      opacity: 0.5;
    }
  }
}

.guest-panel {
  .table {
    &__count-size {
      display: none;
    }
  }
}

.resize__panels,
.desktop__panels,
.guest-panel {
  .panel__inner {
    overflow: visible;
  }
}

.resize__panels {
  .trades-table {
    th {
      top: 40px;
    }
  }

  .nav__item {
    &--minimize,
    &--icon {
      border-top: 2px solid $black;
    }
  }

  .panel {
    &--nav {
      width: calc(100% + 1.5rem - 2px);

      &.full-nav {
        position: sticky;
        top: 0;
        z-index: 1;
        width: calc(100% + 1.5rem - 2px);
        margin-left: -10px;
      }
    }

    &--trades {
      width: 100%;
      border-left: 0;
    }
  }
}

.multivariant-executions {
  .table {
    &__count-size {
      margin-top: -38px;
    }
  }
}
