@import "../../styles/colors";
@import "../../styles/typography";

.composer-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: $off-black;
  border-left: 2px solid $black;
  z-index: 2;

  &.preset {
    width: 400px;
    min-width: 400px;

    .composer-panel {
      width: 400px;
      min-width: 400px;
    }
  }

  &:not(.preset) {
    width: 310px;
    min-width: 310px;

    .composer-panel {
      width: 310px;
      min-width: 310px;
    }
  }

  @media (max-width: 1199.98px) {
    &.has-open-asset-explorer {
      position: absolute;
      right: 0;
    }
  }

  @media (max-width: 997.98px) {
    position: absolute;
    right: 0;
  }

  &.is-loading {
    .composer-panel {
      animation: rowloading 0.5s alternate infinite;
      filter: blur(3px);
    }
  }

  .icons {
    height: 30px;
    background-color: $black;

    &.subscribe-icon {
      background-color: $flat-purple;

      img {
        width: unset;
        height: unset;
        margin-top: 4px;
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }

    &.syndication-icon {
      background-color: $flat-green;

      img {
        width: unset;
        height: unset;
        margin-top: 6px;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 6px !important;
        padding-right: 6px !important;
      }
    }

    &:not(.syndication-icon):not(.subscribe-icon) {
      img:first-child {
        width: 14px;
        height: 14px;
        margin-top: 8px;

        &.strategy-pack-img {
          width: unset;
          height: unset;
          margin-top: 7px;
        }
      }
    }

    img:first-child {
      padding-left: 9px;
      padding-right: 9px;
    }

    &.is-preview {
      background-color: $off-black;
    }

    &.to-preview:hover {
      cursor: pointer;

      img {
        filter: brightness(150%);
      }
    }

    &.is-packs {
      background-color: $pine;
    }
  }

  .nav {
    flex: 1;
    margin-top: 0 !important;
    height: unset;

    &__container {
      background-color: $black !important;
      margin: 0 !important;
      width: 245px;
      flex-wrap: wrap;
    }

    &__item {
      &:hover {
        background-color: $pine !important;
      }

      &--minimize {
        opacity: 0.5;

        &:hover {
          background-color: $black !important;
          opacity: 1;
        }
      }

      &--icon {
        background-color: $black !important;
        margin-right: 4px;

        .icon {
          padding-left: 8px;
          padding-top: 7px;
        }
      }
    }

    &__link {
      height: 30px;
      padding: 3px 10.5px;

      @media (max-width: 767.98px) {
        padding: 5px 9px 3px;
      }

      &--active {
        background-color: $off-black !important;
        padding: 3px 12px;
      }

      div {
        display: flex;
      }

      .icons {
        padding: 0;
      }

      .live {
        height: unset;
        padding: 0 0 2px 8px;
      }
    }
  }
}

.settings-dropdown {
  position: absolute;
  z-index: 1;
  margin-left: 1px;

  div {
    background-color: $black !important;
    padding-left: 11px;
    width: 29px;
    height: 25px;
    border-top: 2.5px solid $black;
    border-bottom: 2.5px solid $black;
    display: flex;

    &:hover {
      background-color: rgba(55, 81, 93, 1) !important;
      cursor: pointer;
    }
  }
}

.composer-footer {
  position: fixed;
  bottom: 0;
  box-shadow: 0px -3px 6px #000000;
  clip-path: inset(-6px 0px 0px 0px);
  background-color: $off-black;
  margin-bottom: 5px;

  .backtest-message {
    padding: 18px 0 4px 0;

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-mdlg;
      line-height: 19px;
      text-align: center;
      color: $light-gray;
    }

    &__subtitle {
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-xxxs;
      line-height: 19px;
      text-align: center;
      color: $off-white;
    }
  }

  &.preset-footer {
    display: flex;
    width: 400px;
    height: 60px;

    .share-button {
      width: 85px;
      margin-right: 0;
      margin-left: 30px;
      background: unset !important;

      &:not(.Mui-disabled) {
        background: unset;
        border: 1px solid $pine;
      }

      &.Mui-disabled {
        border: 1px solid $steel;
        color: $steel;
      }

      &:hover {
        border: 1px solid $light-pine;
      }

      img {
        margin-right: 10px;
      }
    }

    button {
      height: 40px;

      &:not(.share-button) {
        flex: 1 1;
        width: unset;
        margin-right: 30px;
      }
    }
  }

  button {
    height: 30px;
    margin: 10px;
    width: 290px;
    color: $white;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-xs;
    line-height: 0%;
    text-transform: none;

    &.Mui-disabled {
      background-color: rgba(85, 85, 85, 0.5) !important;
      color: $off-white;
    }
  }

  .subscribe-button {
    background-color: $flat-purple;

    &:hover {
      background-color: $flat-purple;
    }
  }

  .syndication-button {
    background-color: $flat-green;

    &:hover {
      background-color: $flat-green;
    }
  }

  .go-live-button {
    background-color: #b6782b;

    &:hover {
      background-color: #b6782b;
    }
  }

  .run-test-button {
    background-color: $pine;

    &:hover {
      background-color: $pine;
    }

    &.exceeded {
      background-color: #656262 !important;
    }
  }

  .boost-button {
    background-color: rgba(121, 201, 18, 0.5);
    width: 80px;

    &:hover {
      background-color: rgba(121, 201, 18, 0.5);
    }
  }
}

.first-checkbox-display {
  position: relative;

  &:before {
    border-right: 1px solid rgba(101, 98, 98, 0.5);
    content: "";
    height: 100%;
    position: absolute;
    margin-left: -4px;
    margin-top: 20px;
  }

  .input-container {
    padding-right: 0 !important;
  }
}

.search-popup-icon {
  fill: $off-white !important;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    fill: $light-gray !important;
  }
}

.checkbox-tooltip {
  padding-left: 0 !important;
  margin-left: -6px !important;
}

.tooltip-container {
  width: 194px;
  display: flex;

  &__img {
    padding: 0 10px 0 10px;
  }

  &__text {
    width: 143px;
    padding: 13px 0;
  }

  &__body {
    height: auto;

    .short-line-height {
      display: block;
      line-height: 5px;
    }
  }
}

.copy-button {
  border: 1px solid #ffffff4b;
  z-index: 1;
  position: fixed;
  right: 30px;
  padding: 0;
  margin-top: 6px;
  padding-bottom: 6px;
  padding-right: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #1c1c1e;

  img {
    padding: 6px 8px 0 8px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(146deg)
      brightness(103%) contrast(104%);
  }

  span {
    display: inline-block;
    padding-top: 6px;
    font-size: 12px;
  }

  &:hover {
    border: 1px solid #fff;
  }
}

.share-copy-button {
  z-index: 1;
  position: fixed;
  right: 35px;
  padding: 8px 11px 8px 11px;
  margin-top: 6px;
}

.copy-tab {
  .nav {
    &__item:not(:first-child):not(.nav__item--active) {
      &:hover {
        &:before {
          content: " ";
          height: 40px;
          margin-top: 40px;
          border-left: 2px solid $off-white;
          position: absolute;
          margin-left: 1.77%;
        }

        &:after {
          content: "Copy to...";
          position: absolute;
          margin-top: 90px;
          margin-left: 15px;
          font-style: normal;
          font-weight: 600;
          font-size: $font-size-xs;
          line-height: 19px;
        }
      }
    }
  }
}

.has-value {
  label {
    color: #656262 !important;
    font-weight: 500 !important;
  }

  div {
    color: #656262 !important;

    input {
      color: $light-gray !important;
    }

    fieldset {
      border-color: #656262 !important;

      legend {
        font-size: 0.7rem;

        span {
          padding-right: 8px;
        }
      }
    }
  }
}

.webhook-panel {
  .single-column {
    margin-bottom: 12px;
  }

  .update-button-container {
    position: fixed;
    bottom: 8px;
    width: 100%;
    max-width: 270px;
  }
}

.backtest-creation-text {
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-xs;
  line-height: 20px;
  color: $steel;
  margin-top: 15px;

  div {
    padding-left: 19px;
  }

  .header {
    color: #e5e5e5;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .description {
    color: #a7a7a7;
    font-size: 11px;
    font-weight: 400;
  }

  ul {
    list-style-type: none;
    padding-left: 19px;

    &.list {
      padding-left: 0;
    }

    li {
      font-size: 14px;
      color: #a7a7a7;
      margin-bottom: 6px;
      font-weight: 400;
    }
  }
}

.check-user-tier {
  &__placeholder {
    backdrop-filter: blur(3px);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 12px;

    &.prevent-visibility {
      position: absolute;
      top: 0;

      & .check-user-tier__placeholder-text {
        font-size: 13px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 12px;
      background-color: #131313;
      border-radius: 8px;
    }

    &-text {
      text-align: center;
    }
  }
}
