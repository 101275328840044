@import "../../styles/colors";
@import "../../styles/typography";

@font-face {
  font-family: "Droid Sans Mono";
  src: url("../../styles/fonts/DroidSansMono.ttf") format("truetype");
}

.code-editor {
  display: flex;
  flex-flow: column;
  flex: 1;

  &__actions {
    position: relative;
    left: -0.75rem;
    display: flex;
    width: calc(100% + 1.5rem - 2px);

    .button {
      max-height: 40px;
      height: 30px;
      min-height: 30px;
      color: $pale-gray;
      margin: 0.125rem;
      width: auto;
      padding: 0; //.4rem;
      font-size: $font-size-sm;
      display: inline-block;
      vertical-align: middle;
      font-weight: 400;

      &--primary {
        background: $blue;
      }

      &--secondary {
        background: $off-black;
        border-color: transparent;
        border: 2px;
      }

      &--errors {
        background: $red;

        &:hover {
          background: $red;
        }
      }
    }

    .button.save {
      margin-right: 0;
      width: 75px;
    }

    .button.new-script {
      font-size: $font-size-xxl;
      padding: 0 0.75rem;
      margin-left: 0;
      display: flex;
      align-items: center;

      &:hover {
        img {
          filter: grayscale(100%) brightness(232%);
        }
      }
    }

    .button.script-rename {
      font-weight: 100;
      font-size: x-small;
      white-space: nowrap;
    }
  }

  &__left-actions {
    display: flex;
    flex: 1;

    .strategy-picker {
      margin-right: 0 !important;
    }

    .selected-script {
      height: 30px;
      display: flex;

      .remove-script {
        background: $off-black;
        height: 7px;
        padding: 11.5px;
        margin-top: 2px;
        margin-right: 2px;

        &:hover {
          cursor: pointer;
        }
      }

      .rename-script {
        background: $off-black;
        height: 10px;
        border: 10px solid $off-black;
        margin-top: 2px;
        margin-right: 2px;

        &:hover {
          cursor: pointer;
        }
      }

      &.current-script-step {
        position: absolute;
        max-width: calc(100% - 457px) !important;
        width: calc(100% - 457px) !important;
        left: 247px;

        @media (min-width: 1375px) {
          display: none !important;
        }
      }

      &.bg-selected-script {
        width: auto;
        max-width: 21%;

        @media (max-width: 1374.98px) {
          display: none !important;
        }
      }
    }

    .MuiMobileStepper {
      &-root {
        background: none !important;
        height: 30px !important;
        padding: 0 !important;
        margin-top: 2px;
        max-width: calc(100% - 395px) !important;
        width: calc(100% - 405px) !important;

        @media (min-width: 1375px) {
          display: none !important;
        }

        .MuiButton {
          &-root {
            width: 25px !important;
            min-width: 24px !important;
            background: $off-black !important;
            height: 30px !important;
            border-radius: 0 !important;
          }

          &-label {
            width: 25px !important;
          }
        }

        .MuiLinearProgress {
          &-root {
            display: none;
          }
        }
      }
    }
  }

  &__right-actions {
    height: 30px;
    min-height: 30px;
    display: flex;

    button {
      padding-top: 4px !important;
    }
  }

  &__wrapper {
    padding-top: 10px;
    background-color: $black;
    height: calc(100% - 45px);
    left: -0.75rem;
    margin-right: -1.5rem;
    width: calc(100% + 18px);
    position: relative;
    display: block;
  }

  &__monaco {
    height: 100%;
  }

  .navigation-away-alert {
    position: absolute;
    z-index: 999;
    width: auto;
    left: 25px;
    top: 50px;

    &__dialogue {
      display: inline-flex;

      button {
        padding: 0 5px !important;
        margin-left: 10px !important;
      }
    }
  }

  .scripts-dropdown {
    height: 30px;
    min-height: 30px;
    align-items: center;
    background: $nav--secondary-background-color;
    border: none;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    color: $steel;
    justify-content: space-evenly;
    font-size: $font-size-xs;
    font-weight: 200;
    margin: 0.125rem 0;
    max-height: 40px;

    &:not(.revisions-dropdown):not(.open-revisions) {
      width: auto;
      margin-right: 2px;

      &.MuiAutocomplete {
        &-root {
          width: 145px !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: $font-size-xs !important;
          line-height: 20px !important;
        }
      }

      .MuiFormControl {
        &-root {
          width: 100% !important;
        }
      }

      .MuiAutocomplete {
        &-inputRoot {
          padding-right: 0 !important;
        }

        &-input {
          padding: 0 10px 0 30px !important;
          height: 20px;
        }

        &-endAdornment {
          right: unset !important;
        }

        &-clearIndicator {
          display: none !important;
        }

        &-popupIndicator {
          transform: none;

          span {
            svg {
              height: 17px !important;
              margin-top: 4px;
            }
          }
        }
      }

      .MuiInput {
        &-underline {
          &:after,
          &:before {
            display: none !important;
          }
        }
      }
    }

    &.scripts-list {
      padding: 0 0 0 15px;
      margin-right: 0 !important;
      min-height: 30px;
      height: 30px;
      width: 100% !important;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      line-height: 30px;

      &.current-script {
        font-weight: 500;
        color: $light-gray;

        &:hover {
          text-decoration: underline;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    &.revisions-dropdown {
      width: 184px;
      border-left: 2px solid $black;
      margin-left: auto;
      margin-right: 2px;

      .scripts-dropdown {
        &__value-container {
          position: relative;
          padding-top: 15px;
        }

        &__single-value {
          padding-left: 13px;
          width: 100%;
          max-width: 100%;
          left: 0;
        }
      }
    }

    &.open-revisions {
      width: 100px;
      color: $steel;
      letter-spacing: 0.05rem;
      border-left: 2px solid $black;
      margin-right: 1px;
      background: $black;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-xs;
      line-height: 19px;
      margin-left: auto;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &__control {
      height: 30px;
      min-height: 30px;
      width: 100%;
      background: $nav--secondary-background-color;
      border: none;
      border-radius: 0;

      &--is-focused {
        box-shadow: none;
      }

      &--menu-is-open {
        .scripts-dropdown__indicators {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }

      .css-b8ldur-Input {
        width: 100% !important;
      }
    }

    &__value-container {
      background: none;
      text-align: center;
      position: static;
    }

    &__input {
      background: none;
      text-align: center;
      color: $pale-gray !important;
      width: inherit !important;

      #react-select-4-input,
      #react-select-5-input {
        width: 100% !important;
        text-align: center;
        color: $pale-gray;
        font-size: $font-size-sm;
        font-weight: 200;
        justify-content: space-evenly;
        letter-spacing: 0.0875rem !important;
        padding-left: 28px !important;
      }
    }

    &__menu {
      background: $nav--secondary-background-color;
      min-width: 5vw;
      white-space: nowrap;
      text-align: left;
      margin-top: 2px;
      border-radius: 0;

      &.new-script {
        position: absolute;
        top: 31px;
        left: 1px;
        z-index: 9;
        padding: 5px 0;
        font-size: $font-size-xs;
        font-weight: 400;

        .scripts-dropdown {
          &__option {
            padding: 1px 12px 0;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    &__indicator-separator {
      display: none !important;
    }

    &__dropdown-indicator {
      color: $pale-gray;
    }

    &__indicators {
      background: none;
      height: 30px;
      min-height: 30px;
    }

    &__placeholder {
      background: none;
    }

    &__option {
      background: none;
      color: $off-white;
      padding-top: 1px;
      padding-bottom: 0;
      height: 20px;

      &:hover {
        background-color: $steel !important;
        color: $white !important;
      }
    }

    &__single-value {
      background: none;
      color: $pale-gray;
      text-align: center;
      width: calc(100% - 8px);
      margin: 0;
      left: 4px;
    }
  }

  &__type {
    position: absolute;
    bottom: 10px;
    right: 20px;
    user-select: none;

    &--language {
      display: inline-flex;
      flex-direction: row-reverse;
      margin-top: 5px;
      float: right;
      position: relative;
      z-index: 1;
      background-color: $black;

      .language-name {
        color: $off-white;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        margin-top: auto;
        margin-bottom: auto;
        display: none;
        padding-top: 1px;
      }

      .language-image {
        height: 36px;
        width: 36px;
        border: 2px solid #252525;
        border-radius: 36px;
        padding: 5px;
        align-items: center;
        display: flex;
        margin-left: 10px;

        img {
          height: 50%;
          margin: auto;
        }

        &:hover {
          & + .language-name {
            display: unset;
          }
        }

        &.pine {
          img {
            height: calc(100% - 14px);
            padding-bottom: 2px;
          }
        }
      }

      &.active {
        z-index: 2;
      }

      &:hover,
      &.active {
        .language-name {
          color: $light-gray;
        }
      }

      &:hover:not(.disabled) {
        .language-image {
          border-color: $light-gray;
        }

        cursor: pointer;
      }
    }

    &--options {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      visibility: hidden;
      transition: transform 0.5s, visibility 0.25s;

      &.show-options {
        visibility: visible;
      }

      &:not(.show-options) {
        transform: translateY(50px);
      }
    }
  }
}

.editor-dropdown {
  width: auto !important;
  min-width: 145px !important;
  max-width: 300px !important;
  left: 33px !important;

  .MuiAutocomplete {
    &-paper {
      background-color: $off-black !important;
      color: $off-white !important;
      font-weight: 500 !important;
      font-size: $font-size-xs !important;
      line-height: 20px !important;
      margin-top: 2px;
      border-radius: 0;

      ul {
        li {
          padding: 2px 12px 0px 12px !important;

          &[data-focus="true"] {
            background-color: $steel !important;
            color: $white !important;
          }
        }
      }
    }
  }
}

.editor-error {
  position: absolute;
  margin: 10px 0 0 10px;
}
