@import "../../../styles/colors";
@import "../../../styles/typography";

.chart-toggle {
  @media (max-width: 991.98px) {
    &.open-asset-explorer {
      width: auto;
    }
  }

  @media (max-width: 767.98px) {
    width: 60px;

    .chart-toggle--option {
      img {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }
  }

  & + .portfolio-option {
    border-left: 0;
  }

  .MuiSelect {
    &-icon {
      color: $off-white;
    }
  }

  .MuiOutlinedInput {
    &-notchedOutline {
      border-width: 0 !important;
    }

    &-input {
      padding-top: 7px;
      padding-bottom: 6px;
    }
  }

  &--option {
    display: flex;

    img {
      margin-right: 10px;
    }

    span {
      font-weight: 600;
      font-size: $font-size-sm;
      margin-top: -1px;
    }

    &.for-portfolio {
      img {
        height: 11px;
      }
    }

    &.for-performance {
      img {
        height: 9px;
      }
    }

    &.for-market {
      img {
        height: 16px;
      }
    }

    &.for-portfolio,
    &.for-performance {
      img {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

.material-select {
  &:hover {
    .MuiSelect-select {
      color: $light-gray !important;

      img {
        filter: grayscale(100%) brightness(232%);
      }
    }

    .MuiSelect-icon {
      filter: grayscale(100%) brightness(232%);
    }
  }

  .MuiSelect-select {
    font-weight: 500 !important;
  }
}

.material-select-dropdown {
  .MuiPaper {
    &-root {
      &.MuiMenu {
        &-paper {
          border-radius: 0;
          background-color: $off-black;
        }
      }

      .MuiList {
        &-root {
          .select-option {
            font-size: $font-size-xs;
            font-weight: 500;
            color: $off-white;

            &:hover {
              background-color: $steel;
              color: $white;

              img {
                filter: grayscale(100%) brightness(232%);
              }
            }

            &.Mui {
              &-selected {
                color: $white;

                .chart-toggle--option {
                  img {
                    filter: grayscale(100%) brightness(232%);
                  }
                }
              }
            }

            img {
              width: 18px;
            }

            span {
              margin-bottom: -2px;
            }

            .chart-toggle--option {
              display: flex;
            }
          }
        }
      }
    }
  }
}
