@import "../../../styles/colors";
@import "../../../styles/typography";

.editor-side {
  border-left: 6px solid $off-black;

  @media (min-width: 2000.98px) {
    width: 550px;
    max-width: 550px;
  }

  &.script-side {
    width: 40%;
    max-width: 40%;
    flex: unset;
  }

  & .nav--tertiary {
    margin-left: 20px;
    & .nav__item {
      & .nav__link {
        border: none;
        color: $off-white;
        height: 20px;
        line-height: 20px;
        margin: 0 17px;
        padding: 0;

        &.nav__link--active {
          font-weight: 600;
          color: $white;
        }
      }
    }
  }

  .status {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    position: relative;
    display: inline-block;
    margin: auto;

    &_running-backtest {
      border: 6px solid $black;

      &--circle {
        position: absolute;
        top: -6px;
        left: -6px;
        color: $green;
        transform: rotate(-90deg) !important;
      }
    }
  }

  .panel {
    &__inner {
      height: 100%;
    }
  }
}
