@import "../../styles/colors";
@import "../../styles/typography";

.error-page {
  .container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .button {
    margin-top: 20px;
    background: #264e5a;
    padding: 10px 24px 10px 24px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    width: fit-content;

    &:hover {
      opacity: 0.6;
    }

    &.disabled {
      background: #264e5ab5;
      color: #ffffffb0;
      pointer-events: none;
    }
  }
}

@media (max-width: 1599.98px) {
  .user-page {
  }
}

@media (max-width: 1199.98px) {
  .user-page {
  }
}

@media (max-width: 991.98px) {
  .user-page {
  }
}

@media (max-width: 767.98px) {
  .user-page {
  }
}
