@import "../../styles/colors";
@import "../../styles/typography";

.composer-panel {
  position: absolute;
  background-color: $off-black;
  height: calc(100% - 90px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;

  &:not(.existing) {
    & > div {
      &:last-child {
        padding-bottom: 100px;
      }
    }
  }

  &.existing,
  &.external-edit {
    .input-container {
      &:not(.batch-display):not(.selected) {
        .Mui {
          &-disabled input {
            color: $off-white !important;
          }
        }
      }
    }
  }

  &.existing {
    .input-container {
      &.batch-display:not(.selected) {
        .Mui {
          &-disabled {
            color: $light-yellow !important;

            input {
              color: $light-yellow !important;
            }

            .MuiOutlinedInput {
              &-notchedOutline {
                border-color: $light-yellow !important;
              }
            }
          }
        }
      }
    }

    .field-information {
      display: none;
    }
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
  }

  .MuiTextField {
    &-root {
      .MuiInputBase {
        &-root {
          &:not(.MuiAutocomplete-inputRoot) {
            input {
              padding-top: 11.5px !important;
              padding-bottom: 9.5px !important;
            }
          }
        }
      }
    }
  }

  svg {
    fill: $off-white !important;
    opacity: 0.5;
  }
}

.MuiOutlinedInput {
  font-weight: normal !important;
  font-size: $font-size-xs !important;
  color: $off-white !important;

  &-notchedOutline {
    border-color: $off-white !important;
  }
}

.MuiFormControl-root {
  &.select-dropdown {
    .MuiSelect {
      &-root {
        color: $light-gray !important;
      }
    }

    .MuiOutlinedInput {
      &-notchedOutline {
        legend {
          visibility: visible;
          transform: translateY(-2px);

          span {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  label {
    color: $off-white !important;
    font-size: $font-size-xs !important;

    &.Mui-focused {
      color: $off-white !important;
    }

    &.MuiInputLabel-shrink {
      font-size: $font-size-wxs !important;
      transform: translate(14px, -1px) scale(0.85) !important;
    }
  }
  &.Mui-focused {
    label {
      color: $off-white !important;
    }
  }

  .MuiFormLabel-root {
    top: -2px !important;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-xs;
  }

  .MuiFormHelperText-root {
    color: $steel;
  }
}

.MuiOutlinedInput-adornedEnd {
  .MuiInputBase {
    &-input {
      padding-top: 5px !important;
    }
  }
}

.MuiInputBase {
  &-root {
    color: $off-white !important;
    height: 30px !important;
    font-style: normal !important;
    font-size: $font-size-xs !important;
  }

  &-input {
    font-weight: normal !important;
    font-size: $font-size-xs !important;
    color: $off-white !important;

    &::-webkit-clear-button,
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      margin-left: 0;
      color: transparent;
      background: url("../../images/calendar.svg") no-repeat;
    }
  }

  .Mui-focused {
    color: $off-white !important;
  }
}

.form-body {
  &.preset {
    .single-column {
      padding-left: 30px;
      padding-right: 30px;

      &.max-length {
        width: 340px;
      }

      .input-container {
        > div {
          > div {
            width: 340px;
          }
        }
      }

      .tags-column {
        width: 338px;
      }
    }
  }

  &.algorithm-settings {
    .two-columns {
      max-width: 275px;

      .input-container {
        &.display-checkbox {
          width: 115px;
        }
      }
    }
  }

  &.preset {
    padding-top: 14px;
  }

  > div {
    padding-bottom: 10px;
  }

  .two-line-columns {
    padding-bottom: 0;

    & > div {
      padding-bottom: 10px;
    }
  }

  .single-column {
    position: relative;

    &.text-area {
      padding-bottom: 5px;

      .input-container {
        height: auto;

        > div {
          > div {
            height: auto !important;

            textarea {
              height: 71px;
              resize: vertical;
            }
          }
        }
      }
    }

    &.max-length {
      width: 260px;
      text-align: right;
      font-size: $font-size-ss;
      color: $steel;

      .success {
        color: $green;
      }

      .error {
        color: $red-error;
      }
    }

    .input-container {
      > div {
        > div {
          width: 260px;
        }
      }
    }

    .two-columns {
      .input-container {
        padding: 0 0 5px 0;

        .MuiFormControlLabel {
          &-root {
            padding: 2.5px 0 0 11px;
          }
        }

        .MuiFormControl {
          &-root {
            padding-right: 5px;
          }
        }

        .MuiIconButton {
          &-root {
            align-self: center;
            padding-top: 1px !important;
          }
        }
      }
    }
  }
  .two-columns {
    display: inline-flex;

    > div:last-child {
      float: right;
    }

    &.only-checkbox {
      .MuiFormGroup {
        &-root {
          width: unset;
        }
      }
    }

    &.multi-coin-pack {
      .exchange {
        &.MuiAutocomplete {
          &-root {
            .MuiInputBase {
              &-root {
                padding-right: 6px;
              }
            }
          }
        }
      }
    }

    .input-container {
      &.display-checkbox {
        padding-right: 18px;

        input {
          padding-top: 9.5px !important;
        }

        .MuiFormControlLabel-root {
          margin-top: 3px;
          margin-right: auto;
          max-width: 144px;
        }

        &:not(.display-tooltip) {
          .MuiTextField-root {
            padding-right: 12px;
          }
        }
      }

      > div {
        fieldset {
          width: 106px;
        }

        > div {
          width: 115px;
        }
      }
    }

    .MuiFormLabel {
      &-root {
        &.MuiInputLabel-shrink {
          max-width: 120px;
        }
      }
    }
  }

  .field-information {
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-ss;
    color: $pale-yellow;
    width: 260px;
    text-align: right;
    padding: 0;
    line-height: 15px;

    &.refresh {
      color: $steel;
      margin-bottom: 3px;
    }

    img {
      vertical-align: middle;
      margin-bottom: 1px;
      margin-right: 3px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .info-for-composer {
    color: $off-white;
    font-size: $font-size-ss;
    font-style: normal;
    width: 260px;
    line-height: 20px;

    &.bold {
      font-weight: 600;
    }

    &.with-image {
      display: flex;
    }

    &.clickable {
      margin-top: 20px;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        color: $light-gray;
      }
    }

    a.info-for-composer {
      margin-left: 0;
      display: block;
      text-decoration: underline;
    }

    &.yellow {
      color: $pale-yellow;
    }

    img {
      margin-right: 5px;
      margin-bottom: auto;

      & + div {
        max-width: 220px;
      }
    }

    .error {
      color: $red-error;
    }
  }

  .empty-syndication-list {
    color: $steel;
    font-size: $font-size-ss;
    font-style: normal;
    width: 260px;
  }

  .input-container {
    &.selected {
      fieldset {
        border-color: $pale-pine !important;
      }

      label {
        color: $pale-pine !important;
      }

      .MuiFormControl-root {
        .MuiInputBase-input {
          color: $pale-pine !important;
        }
      }
    }
  }
}

.input-container {
  display: inline-flex;

  &.display-tooltip {
    padding-right: 14px;
  }

  &:not(.display-tooltip) {
    padding-right: 30px;
  }

  &.display-radio {
    .MuiFormControlLabel {
      &-root {
        margin-right: auto;
        margin-left: 0;
        margin-bottom: 19px;

        &:hover:not(.Mui-disabled) {
          .MuiRadio {
            &-root {
              border: 1px solid $iron;
            }
          }
        }
      }
    }

    .Mui-disabled {
      .radio-label {
        span {
          color: $steel;
        }
      }
    }

    .MuiRadio {
      &-root {
        padding: 0;
        border: 1px solid black;
      }
    }

    .radio-label {
      margin-left: 10px;
      margin-top: -1px;

      span {
        font-size: $font-size-md;
        font-style: normal;
        font-weight: 500;
        color: $off-white;
      }

      .MuiButtonBase-root {
        padding-top: 0 !important;
      }
    }
  }

  &__tooltip {
    padding: 10px 0 0 0 !important;
    margin-left: 4px !important;
    align-self: baseline;

    .MuiIconButton {
      &-label {
        .MuiSvgIcon {
          &-root {
            height: 12px !important;
            width: 12px !important;
          }
        }
      }
    }
  }

  .MuiFormHelperText {
    &-root {
      font-style: normal;
      font-weight: 500;
      font-size: $font-size-ss;
      line-height: 14px;
      margin-right: 0;
      width: calc(100% - 28px);
    }
  }

  .Mui {
    &-disabled {
      color: $steel !important;

      .MuiOutlinedInput {
        &-notchedOutline {
          border-color: $steel !important;
        }
      }
    }
  }

  .search-popup-icon {
    height: 15px !important;
    width: 15px !important;
    padding-top: 5px !important;
    padding-right: 3px !important;
  }

  .MuiAutocomplete {
    &-popupIndicator {
      transform: none !important;
    }
  }

  .for-popup {
    position: absolute;
    top: -25px;

    .new-user-experience {
      width: 210px !important;
    }
  }

  .MuiFormLabel {
    &-root {
      transform: translate(14px, 11px) scale(1) !important;
      max-width: 240px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.MuiInputLabel-shrink {
        max-width: 290px;
      }
    }
  }
}

.MuiAutocomplete {
  &-root {
    .MuiFormControl {
      &-root {
        .MuiInputBase {
          &-root {
            padding-top: 2.5px !important;
            padding-bottom: 2px !important;
          }
        }
      }
    }

    .MuiChip {
      &-label {
        line-height: 11px;
        padding-top: 1px;
      }
    }
  }

  &-popper {
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-xxxs;
    line-height: 14px;

    &.api-key-dropdown {
      .MuiAutocomplete-option[aria-disabled="true"] {
        opacity: 1;
        pointer-events: unset !important;

        span {
          opacity: 0.5;
        }
      }
    }
  }

  &-paper {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $font-size-xs !important;
    color: $white !important;
    background-color: $black !important;

    ul {
      li {
        padding: 2px 12px 0px 12px !important;

        &[data-focus="true"] {
          background-color: $steel !important;
        }
      }
    }
  }

  &-listbox {
    @media (min-height: 1000px) {
      max-height: 400px !important;
    }
  }

  &-noOptions {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $font-size-xs !important;
    color: $white !important;
    background-color: $black !important;
  }

  &-loading {
    color: $white !important;
  }
}

.display-error {
  div {
    .MuiOutlinedInput {
      &-notchedOutline {
        border-color: $red-error !important;
      }
    }

    label {
      color: $red-error !important;

      &.Mui-focused {
        color: $red-error !important;
      }

      &.MuiInputLabel-shrink {
        color: $red-error !important;
      }
    }

    p {
      color: $red-error !important;
      font-size: $font-size-ss;
    }
  }
}

.date-input {
  .MuiInputBase {
    &-input {
      padding-left: 6px !important;
      padding-right: 5px !important;

      &::-webkit-calendar-picker-indicator {
        margin-top: 5px;
        margin-right: -5px;
        filter: grayscale(100%) brightness(55%);
      }
    }
  }

  .has-value {
    input {
      &.Mui-disabled {
        color: $off-white !important;
      }
    }
  }
}

.display-textarea {
  .MuiInputBase {
    &-root {
      height: auto !important;
    }
  }
}

.display-checkbox {
  padding-left: 11px;
  width: 264px;

  .MuiFormControlLabel {
    &-root {
      height: 29px;
      margin-right: 10px;
      margin-top: 1px;

      .MuiButtonBase {
        &-root {
          height: 14px;
          width: 14px;
          padding: 0 7px 5px 0;

          span:hover {
            border: 1px solid $iron;
          }
        }
      }

      .MuiTypography {
        &-root {
          font-style: normal;
          font-weight: 500;
          font-size: $font-size-xs;
          line-height: 19px;
          color: $off-white;
          margin-bottom: 2px;
        }
      }
    }
  }

  .input-container {
    &__tooltip {
      padding-top: 8px !important;
    }
  }

  .Mui-error {
    margin-left: 0;
    width: 115px;
  }
}

.multiple-values {
  height: 30px !important;

  .MuiAutocomplete {
    &-tagSizeSmall {
      margin-top: 5px !important;
      height: 18px !important;
      font-size: 0.6rem !important;
    }
  }
}

.Mui-focused {
  fieldset {
    legend {
      font-size: 0.7rem;
    }
  }
}

.remove-adornment {
  .MuiAutocomplete {
    &-endAdornment {
      display: none;
    }
  }
}
