@import "../../styles/colors";
@import "../../styles/typography";

.settings-page,
.subscription-modal--container {
  .settings {
    &__general {
      &__loading {
        text-align: center;
        margin: 230px 0;

        &--image {
          display: flex;
          margin: auto;

          &.spin {
            animation: rotateInfinite 2s infinite;
            animation-delay: 1s;
          }

          &.success {
            border: 2px solid $light-green;
            border-radius: 100px;
            padding: 7px;
          }
        }

        &--body {
          color: $steel;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          width: 166px;
          margin: auto;
          margin-top: 15px;

          &.success {
            color: $light-green;
          }
        }
      }
    }

    &__add-card-container {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(28, 28, 30, 0.6);
      z-index: 2;
    }

    &__add-card {
      left: calc(50% - 260px);
      top: calc(50% - 315px);
      position: fixed;
      z-index: 3;
      background: $white;
      width: 520px;
      height: 581px;

      &--close {
        position: absolute;
        top: 30px;
        right: 30px;

        &:hover {
          cursor: pointer;
        }
      }

      .settings {
        &__general {
          height: 100%;
          margin-left: 0;
          overflow: auto;

          &--header {
            padding-top: 25px;
            margin-bottom: 10px;
            color: $off-black;
            font-size: $font-size-md;
            font-weight: 600;

            &:first-child {
              padding-top: 50px !important;
            }
          }

          &--body {
            margin: 0 50px 54px;
            height: unset;
            width: calc(100% - 50px);
            padding: 0;

            .credit-card-field {
              width: 420px;

              .settings__general--field {
                margin-bottom: 5px;

                &:first-child {
                  width: 200px;
                }

                &:nth-child(2) {
                  width: 90px !important;
                }

                &:last-child {
                  width: 90px !important;
                }
              }

              img {
                position: absolute;
                top: 13.5px;
                left: 10px;

                &.credit-card {
                  top: 15px;
                }
              }
            }

            .Mui-error {
              color: $red-error;
              font-weight: 600;
              font-size: 13px;
              font-style: normal;
            }
          }

          &--field {
            width: 200px;
            margin-right: 0;
            margin-bottom: 20px;

            &.full-width {
              width: 385px;
              margin-right: 200px;
            }

            &.field-button {
              text-transform: none;
              width: unset;
              padding-bottom: 1px;

              &:not(.reset-button) {
                background: $pine;
                color: $white;
                font-weight: 600;
                padding-top: 9px;
                padding-bottom: 6px;
              }
            }

            &.reset-button {
              color: $steel;
              font-weight: 500;
              padding-top: 4px;
            }

            &.field-message {
              display: inline-flex;
              font-size: $font-size-md;
              font-weight: 500;
              color: $light-gray;
              vertical-align: middle;
              margin-right: 0;

              img {
                margin-right: 10px;
              }

              span {
                margin-top: 2px;
              }

              &.large {
                font-size: $font-size-xxl;
                display: block;
              }
            }

            &.field-message,
            &.field-button {
              font-style: normal;
            }

            &.dropdown-field {
              .MuiAutocomplete {
                &-input {
                  padding-top: 9.5px !important;
                  background: transparent;
                }
              }
            }

            &.is-mini {
              width: 60px !important;
              margin-right: 20px !important;

              &.full-width {
                width: 386px !important;
                margin-right: 0 !important;
              }
            }

            &.display-error {
              .MuiFormLabel {
                &-root {
                  color: $red-error !important;
                }
              }
            }

            .MuiInputBase {
              &-root {
                height: 40px !important;
              }

              &-input {
                background: transparent;
                padding-top: 12.5px !important;
                padding-bottom: 9.5px !important;
                height: auto;
              }
            }

            .MuiFormLabel {
              &-root {
                top: -5px !important;
              }
            }

            .MuiInputLabel {
              &-shrink {
                transform: translate(12px, 3px) scale(0.85) !important;
              }
            }

            .MuiFormHelperText {
              &-root {
                font-style: normal;
                font-weight: 500;
                font-size: $font-size-xs;
                color: $red-error;
              }
            }

            &.has-value {
              .MuiInputBase {
                &-input {
                  color: $black !important;
                }
              }

              .MuiFormLabel {
                &-root {
                  top: -7px !important;
                }
              }
            }

            &:not(.has-value):not(.display-error) {
              * {
                color: $steel !important;
                border-color: $steel !important;
              }
            }

            &.has-spacing {
              margin-right: 20px;
            }

            label {
              text-transform: none !important;
            }

            .frame-wrapper {
              height: 100%;
              padding-top: 0 !important;
              padding-bottom: 3px !important;
              padding-left: 30px;
            }

            .MuiFormLabel-root {
              &.Mui-focused {
                color: $steel !important;
              }
            }

            .Mui-focused {
              &.MuiInputLabel-root {
                top: -7px !important;
              }
            }
          }

          &--buttons {
            position: relative;
            right: unset;
            top: unset;
            width: 175px;
            margin-left: auto;
            margin-right: 49px;
            margin-top: 10px;

            .back-button,
            .submit-button {
              font-style: normal;
              font-weight: 600;
              font-size: $font-size-xs;
              width: auto;
              text-transform: none;
              padding-top: 9px;
              box-shadow: unset;
            }

            .submit-button {
              background-color: $pine;
              color: $white;

              &:hover {
                background-color: $pine;
                color: $light-gray;
              }

              &.Mui-disabled {
                background-color: rgba(85, 85, 85, 0.5);
                color: $steel;
              }
            }

            .back-button {
              background-color: transparent;
              color: $steel;
              margin-right: 15px;
              border: 1px solid $off-white;
              padding-left: 16px;

              &:hover {
                color: $off-white;
              }

              img {
                margin-right: 5px;
              }
            }
          }
        }
      }

      .wide-field {
        .settings__general--field {
          width: 420px;
        }
      }

      .settings__general--field {
        .is-mini {
          width: 60px;
        }
      }

      .input-group {
        .settings__general--field {
          &:first-child {
            margin-right: 0 !important;

            fieldset {
              border-right: 0;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-width: 1px !important;
            }
          }

          &:last-child {
            fieldset {
              border-left: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-width: 1px !important;
            }

            &:before {
              color: $black;
              position: absolute;
              top: 9px;
              margin-left: -5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .settings {
    &__add-card {
      .settings {
        &__general {
          max-height: 100%;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .settings {
    &__add-card {
      width: 90% !important;
      height: 85% !important;
      left: 5% !important;
      top: 5% !important;

      .wide-field {
        width: 100% !important;

        & > div {
          width: 100% !important;
        }
      }

      .settings {
        &__general {
          overflow: scroll;

          &--body {
            display: block !important;

            .credit-card-field {
              width: 100% !important;

              .settings__general--field {
                &:first-child {
                  width: 100% !important;
                }
              }
            }
          }

          &--header {
            &:first-child {
              padding-top: 70px !important;
            }
          }

          &--field {
            width: 47.5% !important;

            &.has-spacing {
              margin-right: 5% !important;
            }
          }

          &--buttons {
            height: 30px;
            top: unset !important;
            margin: 0 !important;
            width: unset !important;
            display: flex;

            .back-button {
              margin-right: auto !important;
            }
          }
        }
      }
    }
  }
}
