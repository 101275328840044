@import "../../../styles/typography";
@import "../../../styles/colors";

.form__field--select {
  .select-field {
    align-items: center;
    background: $nav--secondary-background-color;
    border: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
    color: $pale-gray;
    justify-content: space-evenly;
    letter-spacing: 0.0875rem;
    font-size: $font-size-sm;
    font-weight: 200;
    margin: 0.125rem;
    max-height: 40px;

    &__control {
      display: flex;
      width: 100%;
      background: $nav--secondary-background-color;
      border: none;
      border-radius: 0;

      &--is-focused {
        box-shadow: none;
      }

      &--menu-is-open {
        box-shadow: 0 0 0 1px $dark-slate;
        background: $darker-blue;
      }
    }

    &__value-container {
      background: none;
      text-align: center;
    }

    &__input {
      background: none;
      text-align: center;
      color: $pale-gray !important;
    }

    &__menu {
      background: $nav--secondary-background-color;
      min-width: 5vw;
      white-space: nowrap;
    }

    &__indicator-separator {
      display: none !important;
    }

    &__dropdown-indicator {
      color: $pale-gray;
    }

    &__indicators {
      background: none;
    }

    &__placeholder {
      background: none;
    }

    &__option {
      background: none;
      &:hover {
        color: $blue;
      }
    }

    &__single-value {
      background: none;
      color: $pale-gray;
      text-align: center;
      width: calc(100% - 8px);
      margin: 0;
      left: 4px;
    }
  }
}
