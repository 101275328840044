$blue: #339ef3;
$dark-blue: #d8d8d8;
$darker-blue: #334150;
$pale-blue: #73cef3;
$bold-blue: #3185fc;
$peacock: #0a2835;
$navy: #0b2029;
$green: #1a8018;
$dark-green: #59870f;
$pale-green: #7daf7c;
$light-green: #7db47b;
$flat-green: #356933;
$bright-green: #25a962;
$bold-green: #1df0a9;
$aquamarine: #13c4a3;
$success: #4cc38e;
$pine: #264e5a;
$pine-ish: #1a3741;
$off-pine: #142c39;
$dark-pine: #112129;
$black-pine: #101c20;
$darkish-pine: #121b1f;
$light-pine: #316373;
$lightish-pine: #173740;
$bold-pine: #37515d;
$pale-pine: #4f7775;
$flat-pine: #183746;
$pine-muted: #1a2d32;
$sacramento: #0c171c;
$slate: #3e526b;
$dark-slate: #26303b;
$yellow: #b8953b;
$dark-yellow: #856d2f;
$pale-yellow: #998552;
$light-yellow: #b3a086;
$bright-yellow: #e5ff47;
$bold-yellow: #f4c95d;
$read-only: #88705b;
$teal: #35959b;
$orange: #cd7272;
$pale-orange: #dc851f;
$light-orange: #ff9330;
$bold-orange: #f85e00;
$bronze: #b6782b;
$orange-banner: #986c2b;
$cantaloupe: #a59683;
$purple: #bf99fe;
$flat-purple: #685dc9;
$bold-purple: #a800ff;
$red: #cc6464;
$light-red: #d14962;
$dark-red: #990033;
$red-error: #cc6464;
$red-banner: #932027;
$error: #9a5b66;
$mostly-black: #081f28;
$camel: #baa78f;

$black: #131313;
$black-ish: #121212;
$off-black: #1c1c1e;
$black-tooltip: #222222;
$dark: #181818;
$off-dark: #1d1d1d;
$placeholder: #13262f;
$soot: #151d20;
$steel: #656262;
$metal: #6d7579;
$white: #ffffff;
$white-ish: #ebeff3;
$off-white: #a7a7a7;
$pearl: #bababa;
$silver: #b1c6c6;
$iron: #7c7c7c;
$pale-gray: #c4c4c4;
$gray: #706f6f;
$off-gray: #9d9d9d;
$light-gray: #e5e5e5;
$dark-gray: #262f39;
$charcoal: #333333;
$body-background-color: #131313;
$text-color: #ffffff;
$odd-row-color: #1a1e1f;

$transparent-black: rgba(17, 17, 17, 0.9);

$status-pending-color: $yellow;
$status-running-color: $green;
$status-ended-color: $gray;
$status-failed-color: $red;
$status-cancelled-color: $gray;
$status-stopping-color: $dark-yellow;
$status-liquidated-color: $blue;

.color {
  &--profit {
    color: $pale-green;
  }
}

$form__input--dark-background-color: #16191b;
$form__input--dark-border-color: $dark-gray;

$nav--primary-background-color: #131313;
$nav--primary-border-color: $dark-gray;
$nav--secondary-background-color: $off-black;
$nav--secondary-border-color: $nav--primary-background-color;

$nav__link-color: #ebeff3;
$nav__link--active-color: $blue;
$nav__link--active-background-color: transparent;
$nav__link-border-color: $off-black;

$table__cell--contract: $pale-yellow;
$table__cell--signal: $light-yellow;
