@import "../../styles/colors";
@import "../../styles/typography";

$header-height: 40px;

.nav {
  &--primary {
    height: $header-height;
    align-items: center;
    background: $off-black;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 2px solid $black;

    .nav__link {
      font-size: $font-size-sm;
      padding: 0 1.25rem;
      line-height: $header-height;
    }

    .nav__container {
      height: $header-height;
    }
  }

  &__container {
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    &--center {
      justify-content: space-evenly;
    }

    &--right {
      flex: none;
    }

    &--portfolio {
      flex: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 767.98px) {
  .nav {
    &__container {
      &--dropdown {
        top: 40px;
      }

      &--portfolio {
        margin-right: auto;
        margin-left: auto;
      }

      &--mobile-portfolio {
        position: relative;
        z-index: 5;
      }

      &--right {
        position: relative;
        z-index: 4;
      }
    }
  }
}
