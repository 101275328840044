@import "../../../styles/colors";
@import "../../../styles/typography";

.syndication {
  &__updates {
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 440px);
    max-width: 1570px;

    @media (max-width: 1474.98px) {
      width: calc(100% - 80px);
    }

    @media (max-width: 767.98px) {
      width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;
    }

    & > * {
      &:last-child {
        margin-bottom: 100px;
      }
    }

    &--title {
      color: $light-gray;
      font-size: $font-size-mdlg;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &__add-update {
      padding-bottom: 30px;
      border-bottom: 1px solid $steel;

      &__field {
        display: flex;

        &--textarea {
          width: 100%;
          min-height: 100px;

          .MuiInputBase {
            &-root {
              min-height: 100px !important;
            }
          }
        }

        .user-photo {
          margin-bottom: auto;
        }
      }

      &__actions {
        margin-left: 36px;
        margin-top: 10px;
        display: flex;

        @media (max-width: 767.98px) {
          flex-direction: column;

          .syndication__updates__add-update__actions {
            &--button {
              width: 100%;
            }

            &--checkbox {
              &.MuiFormControlLabel {
                &-root {
                  margin-top: 5px;
                  margin-left: 0;
                }
              }

              .MuiButtonBase {
                &-root {
                  padding-left: 0;
                }
              }
            }
          }
        }

        &--button {
          &.load-button {
            margin: auto;

            &.MuiButtonBase {
              &-root {
                background-color: $pine !important;
              }
            }
          }

          &.MuiButtonBase {
            &-root {
              background-color: $bronze !important;
              color: $light-gray;
              text-transform: unset;
              font-size: $font-size-mdlg;
              font-weight: 400;
              padding: 6px 60px 4px;
              margin-right: auto;
            }
          }
        }

        &--checkbox {
          &.MuiFormControlLabel {
            &-root {
              margin-right: 0;
              margin-left: 10px;

              .unchecked {
                border: 1px solid $off-black;
              }
            }
          }

          .MuiButtonBase {
            &-root {
              color: $off-white;
              padding: 8px;
              transition: unset;

              &.Mui-checked {
                color: $light-gray;
              }
            }
          }

          .MuiTypography {
            &-root {
              font-style: normal;
              font-weight: 500;
              font-size: $font-size-md;
              letter-spacing: unset;
              color: $light-gray;
              padding-top: 2px;
            }
          }
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .syndication-update-card {
        position: relative;
        background-color: $off-black;
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 10px;

        &.pinned {
          padding-top: 0;
        }

        &:hover {
          .syndication-update-card-link,
          .action-menu-toggler {
            visibility: visible !important;
            cursor: pointer;
          }
        }

        &--pinned {
          display: flex;
          background-color: $pine;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-bottom: 20px;
          width: 72px;
          height: 20px;

          img {
            height: 11px;
            margin: auto 4px auto auto;
          }

          div {
            font-weight: 500;
            font-size: $font-size-ss;
            color: #95bdbb;
            margin: auto auto auto 0;
            padding-top: 1px;
          }
        }

        &__header {
          display: flex;

          &__info {
            &--nickname {
              font-weight: 600;
              font-size: $font-size-md;
              color: $light-gray;
              line-height: 15px;
            }

            &--time {
              line-height: 12px;
              display: flex;
              font-size: $font-size-ss;
              color: $off-white;

              .dot {
                background-color: $pale-gray;
                border-radius: 4px;
                width: 4px;
                height: 4px;
                opacity: 0.2;
                margin: auto 5px;
              }

              div {
                margin-top: 2px;
              }
            }
          }

          &--action {
            display: flex;
            margin-left: auto;

            .syndication-update-card-link {
              margin-top: 0;
              margin-bottom: auto;
              visibility: hidden;
            }

            .action-menu {
              position: relative;
              display: inline-flex;

              &-toggler {
                @media (min-width: 1025px) {
                  visibility: hidden;
                }
              }

              .action-dropdown {
                width: 170px;
                position: absolute;
                right: -10px;
                top: 30px;
                z-index: 1;
                background: $black;
                display: flex;
                flex-direction: column;
                padding: 5px 0 5px 0;
                border: 2px solid $off-black;

                button {
                  width: 100%;
                  text-align: left;
                  padding-top: 2px;
                  padding-bottom: 0;

                  &:hover {
                    background: $iron;

                    .action-option {
                      color: $white;

                      img {
                        filter: grayscale(100%) brightness(232%);
                      }
                    }
                  }
                }

                .action-option {
                  color: $off-white;
                  width: 100%;
                  margin-left: 15px;
                  margin-right: 15px;

                  img {
                    width: 10px;
                    margin-right: 8px;
                    margin-bottom: -2px;

                    &.delete,
                    &.edit {
                      margin-bottom: -1px;
                    }

                    &.eye {
                      margin-bottom: 1px;
                    }
                  }
                }
              }

              .action-menu-toggler {
                float: right;
                width: 1px;
                height: 1px;
                border: 1px solid $off-white;
                background: $off-white;
                border-radius: 100px;
                margin-left: 10px;
                margin-top: 10px;
                margin-right: 5px;
                margin-bottom: 10px;
                position: relative;

                &:after,
                &:before {
                  width: 1px;
                  height: 1px;
                  border: 1px solid $off-white;
                  background: $off-white;
                  border-radius: 100px;
                  content: "";
                  position: absolute;
                  right: -1px;
                }

                &:after {
                  bottom: 5px;
                }

                &:before {
                  top: 5px;
                }

                &--outer {
                  &:hover {
                    cursor: pointer;

                    .action-menu-toggler {
                      border-color: $light-gray;
                      background: $light-gray;

                      &:after,
                      &:before {
                        border-color: $light-gray;
                        background: $light-gray;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &--body {
          font-size: $font-size-mdlg;
          color: $off-white;
          margin-top: 10px;

          @media (max-width: 767.98px) {
            font-size: $font-size-md;
          }

          p {
            overflow: hidden;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: $bronze;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .user-photo {
      flex-shrink: 0;
      margin-right: 10px !important;
    }
  }
}
