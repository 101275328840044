@import "../../../styles/colors";
@import "../../../styles/typography";

.top-dropdowns {
  .chart-toggle {
    max-width: 150px;

    &.market-chart-toggle {
      border-right: 2px solid $black;
    }
  }

  .portfolio-option {
    border-left: 2px solid $black;
    min-width: 45px;
    display: flex;
    color: $off-white;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;

    @media (max-width: 767.98px) {
      width: 42px;
    }

    &.chart-picker {
      width: auto;

      .chart-picker-option {
        width: 30px;
        height: 30px;
        display: flex;
        margin-top: 0;
        margin-bottom: 0;

        & + .chart-picker-option {
          border-left: 2px solid $black;
        }
        &:hover,
        &.selected {
          img {
            filter: grayscale(100%) brightness(232%);
          }
        }

        &.selected {
          background-color: $black;
        }

        img {
          margin: auto;
        }
      }
    }

    // not used for now
    &.live {
      width: unset;

      div {
        height: 14px;
        width: 34px;
        margin-left: 10px;
        margin-right: 10px;
        background-color: #2f2f2f;
        text-align: center;
        line-height: 17px;
      }
    }

    &.currency {
      border-right: 2px solid $black;
    }

    &.active {
      background-color: $black;
      color: $light-gray;
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
      color: $light-gray;
    }

    div {
      margin: auto;
    }
  }

  .portfolio-options-dropdown {
    border-right: 2px solid $black;

    .portfolio-options-list {
      position: absolute;
      top: 32px;
      margin-left: -2px;
      z-index: 2;
      height: auto;

      .portfolio-option {
        height: 30px;
        background-color: $off-black;
      }
    }
  }

  .portfolio-selected-log {
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-xs;
    color: $light-gray;
    display: flex;
    margin: auto 0;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;

    @media (max-width: 767.98px) {
      max-width: calc(100% - 180px);
      margin-left: 10px;
    }

    .title {
      color: $off-white;
      margin-left: 15px;
      margin-right: 10px;
      font-weight: 600;

      @media (max-width: 767.98px) {
        display: none;
      }
    }

    .value {
      margin-right: 10px;

      img {
        margin-bottom: -1px;
      }
    }

    .value-change {
      color: $off-white;
      margin-right: 10px;

      &.pos {
        color: $light-green;
      }

      &.neg {
        color: $red;
      }
    }

    .growth {
      margin-right: 5px;
    }
  }

  .bitcoin-image {
    margin-bottom: -2px;
    margin-right: 2px;
  }
}
