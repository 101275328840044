@import "../../styles/colors";
@import "../../styles/typography";

.portfolio {
  margin: auto;
  max-width: calc(100vw - 200px);

  .visibility-button,
  .metric,
  .metrics {
    display: inline-flex;
  }

  .visibility-button {
    vertical-align: middle;

    .eye {
      height: 10px;
      width: 17px;
    }
  }

  .neg {
    color: $red;
  }

  .pos {
    color: $pale-green;
  }

  .metric {
    border-radius: 23px;
    padding: 4px 15px 1px 15px;
    height: 20px;
    margin-left: 20px;
    cursor: pointer;

    &--value {
      white-space: nowrap;
    }

    div {
      align-self: center;
      color: $light-gray;
      font-weight: 500;
      font-size: $font-size-wxs;
      letter-spacing: 0.025rem;

      &.title {
        color: $off-white;
        margin-right: 10px;
        letter-spacing: 0;
        font-weight: 600;
      }

      &.hidden-metrics {
        height: 14.5px;
      }

      &:not(.hidden-metrics) {
        height: 19px;
        min-height: 19px;
      }
    }
  }

  img.btc {
    vertical-align: middle;
    padding-bottom: 2px;
  }

  img.eye {
    margin-left: 12px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
      cursor: pointer;
      filter: grayscale(100%) brightness(232%);
    }
  }
}

@media (min-width: 992px) {
  .portfolio {
    .visibility-button {
      &.mobile {
        display: none;
      }
    }

    .metric {
      margin-left: 10px;
      padding-right: 10px;
    }

    .mobile-toggle-button {
      display: none;
    }

    &--mobile-background {
      display: none;
    }
  }
}

@media (max-width: 991.98px) {
  .portfolio {
    display: flex;
    max-width: calc(100vw - 140px);
    overflow: hidden;
    height: 100px;
    margin: 4px auto 0;

    &--mobile {
      z-index: 3;

      .metric {
        &:not(.metric--value) {
          background-color: $black;
        }
      }
    }

    &--mobile-background {
      position: absolute;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background: transparent;
      z-index: 3;
    }

    .metrics {
      display: flex;
      flex-direction: column;
      position: static;
      z-index: 4;
      max-width: calc(100% - 20px);
    }

    .metric {
      margin: 5px auto;
      padding: 2px 15px 1px 15px;
      white-space: nowrap;

      &--value {
        position: relative;
        padding: 2px;
      }

      &--mobile {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 37px;
        width: 100%;
        left: 0;
      }

      div {
        &:not(.title) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .mobile-toggle-button {
      margin: 9px 3px 2px;
      height: 16px;
      position: relative;
      z-index: 4;
      display: inline-flex;
      vertical-align: middle;

      &:hover {
        cursor: pointer;

        .toggle {
          filter: grayscale(100%) brightness(232%);
        }
      }

      .toggle {
        height: 6px;
        margin: 5px;

        &.open {
          transform: rotate(180deg);
          filter: brightness(1.5);
        }
      }
    }

    &:not(.portfolio--mobile) {
      .metrics {
        z-index: 0;
      }

      .metric {
        &:not(.metric--value) {
          display: none;
        }
      }
    }

    .visibility-button {
      &:not(.mobile) {
        display: none;
      }

      .eye {
        margin: 8px 5px 5px;
      }
    }
  }
}
