@import "../../../../styles/typography";
@import "../../../../styles/colors";

.filter-list {
  max-width: calc(100% - 150px);

  @media (max-width: 767.97px) {
    max-width: 100%;
  }

  & > * {
    margin-top: 10px;
  }

  .filter-menu {
    position: absolute;
    z-index: 3;

    @media (min-width: 768px) {
      &.has-filters {
        margin-top: 50px;
        left: 10px;
      }

      &:not(.has-filters) {
        max-width: calc(100% - 110px);
      }
    }

    .filter-options {
      span {
        display: inline-block;
      }
    }
  }

  .add-filter {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-right: 43px !important;

    input {
      height: 16px;
    }

    .okay,
    .remove {
      position: absolute;
      margin-top: 2px !important;
    }

    .remove {
      margin-left: 20px;
    }
  }

  .filter-icon {
    padding: 1px 10px 2px 0;
    display: inline-block;

    img {
      vertical-align: middle;
      opacity: 0.725;

      &:hover {
        opacity: 1;
      }
    }
  }

  .clickable {
    cursor: pointer;

    &.filters-dropdown {
      margin-top: 10px;
      padding: 3px 3px 3px 0;
    }

    &:hover {
      color: $white;
    }

    .tag--value {
      padding: 1px 7px 0 7px;
      border-radius: 3px;
    }
  }

  .multi-tag-filter {
    max-width: calc(100% - 110px);
  }

  div:not(.filter-icon) {
    font-weight: 600;
    font-size: $font-size-wxs;
    min-height: 19px;
    letter-spacing: 0.05em;
    display: inline-block;
    margin-right: 10px;
    background-color: $off-black;
    padding: 3px 23px 3px 8px;
    border-radius: 5px;
    border: 1px solid #313131;
    color: $light-gray;
    user-select: none;
    box-sizing: border-box;

    &.clickable {
      padding: 3px 8px;
    }

    div {
      padding: 0px;
    }

    span.choice:not(:last-child):after {
      content: "|";
      padding-left: 4px;
    }

    span:not(:last-child) {
      margin-right: 5px;
    }

    .type {
      color: $light-gray;
    }

    input,
    select {
      color: $light-gray;
      background: $form__input--dark-background-color;
      border: 0;
    }

    .okay {
      display: inline-grid;
      cursor: pointer;

      img {
        height: 15px;
        width: 15px;
      }

      &:hover {
        color: $green;
        border-color: $green;
      }

      &.error {
        color: #9a5b66;
        border-color: #9a5b66;
      }
    }

    .script-tags {
      .okay {
        margin-left: 3px;
      }
    }

    .remove {
      margin-top: 1px;
      position: absolute;
      display: inline-block;
      fill: $red;
      color: $red;
      font-size: $font-size-xxxs;
      text-align: center;
      cursor: pointer;
      font-family: "Helvetica", "Arial", sans-serif;

      &:hover {
        color: $red;
        fill: $red;
      }
    }
  }

  .save-filters {
    border: 1px solid $off-black !important;
    border-radius: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: $steel !important;

    &.save,
    &.clear,
    &.preset,
    &.delete,
    &.loading {
      border: 1px solid transparent !important;
      font-weight: 500;
      font-size: $font-size-xs;
    }

    &.save,
    &.clear,
    &.preset {
      &:hover {
        color: $light-gray !important;
        cursor: pointer;
      }
    }

    &.preset {
      color: $off-white !important;

      @media (max-width: 767.97px) {
        display: none;
      }
    }

    &.delete {
      img {
        opacity: 0.5;
        vertical-align: middle;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    input {
      font-size: $font-size-xs;
      width: 130px;
      height: 22px;
      padding: 0 5px;
      vertical-align: middle;
      color: $off-white;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.05em;
    }
  }

  .syndication-settings {
    .settings-gear {
      margin-bottom: -3px;
      margin-right: 13px;

      &:hover {
        filter: grayscale(100%) brightness(232%);
      }
    }

    .title {
      color: $off-white;
      margin-right: 7px;

      &:hover {
        color: $light-gray;
      }
    }

    &:hover {
      .settings-gear {
        filter: grayscale(100%) brightness(232%);
      }

      .title {
        color: $light-gray;
      }
    }
  }
}

.filter-preset-dropdown {
  position: absolute;
  top: 5px;
  border: 1px solid $off-black;
  background-color: $black;
  z-index: 3;
  left: 0;
  width: 230px;

  div {
    font-weight: 500;
    font-size: $font-size-xs;
    background-color: $black;
    padding: 1px 15px;
    height: 18px;
    color: $off-white;
    width: 200px;
    height: auto;

    &:hover {
      cursor: pointer;
      background-color: $iron;
      color: $white;
    }
  }
}

.filter-error {
  position: absolute;
  z-index: 999;
  left: 35px;
  top: 50px;
}
