@import "../../../../styles/colors";
@import "../../../../styles/typography";

.user-page {
  &__advanced-search {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1;

    &__tabs {
      display: flex;
      background-color: $off-black;
      border-radius: 8px;
      padding: 5px;
      margin-top: 30px;

      &--tab {
        border-radius: 5px;
        padding: 10px 15px;
        line-height: 20px;
        color: $off-white;
        font-size: $font-size-md;
        font-weight: 600;

        &:hover {
          background-color: $black;
          cursor: pointer;
        }

        &.active {
          background-color: $black;
          color: $light-gray;
        }
      }

      &:not(.categories) {
        height: 40px;
        margin-right: 100px;
        margin-left: 100px;
      }

      &.categories {
        background-color: unset;
        overflow-x: auto;

        .category {
          display: flex;
          flex-shrink: 0;
          color: $light-gray;
          border-radius: 20px;
          border: 1px solid $steel;
          padding: 0 10px 0 5px;
          margin-right: 10px;
          margin-bottom: 10px;
          height: 38px;
          user-select: none;

          &:first-child {
            margin-left: 100px;
          }

          &:hover {
            cursor: pointer;
            background-color: $steel;
          }

          &.active {
            border-color: $white;
            background-color: $white;
            color: $off-black;
          }

          img {
            width: 28px;
            height: 28px;
            margin-top: auto;
            margin-right: 10px;
            margin-bottom: auto;
          }

          div {
            line-height: 38px;
            font-size: $font-size-sm;
            font-weight: 500;
          }
        }
      }
    }

    &__filters {
      margin-left: 80px;
      padding: 0 20px;
      width: 260px;

      &--title {
        position: sticky;
        top: 0;
        padding-top: 25px;
        z-index: 1;
        background-color: $black;
        font-size: $font-size-xxl;
        font-weight: 500;
        line-height: 38px;
        color: $light-gray;
      }

      &--fieldname {
        font-size: $font-size-md;
        font-weight: 600;
        color: $white;
        margin-top: 20px;

        img {
          margin-bottom: -1px;
        }

        .infoIcon {
          transform: translateY(-1px);
        }
      }

      &--field,
      &--multi-field {
        width: 260px;

        .MuiInputBase {
          &-root {
            width: 260px;
          }
        }
      }

      &--options {
        display: flex;

        .option {
          width: 65px;
          height: 60px;

          &.active {
            .body {
              background-color: $light-gray !important;
              color: $black !important;
              border: 1px solid $light-gray;
            }
          }

          &:first-child {
            .body {
              border-left: 1px solid $steel;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
          }

          &:last-child {
            .body {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }

          .body {
            font-size: $font-size-md;
            font-weight: 500;
            height: 38px;
            text-align: center;
            line-height: 38px;
            color: $off-white;
            border-right: 1px solid $steel;
            border-top: 1px solid $steel;
            border-bottom: 1px solid $steel;
            padding-top: 2px;

            &:hover {
              background-color: $off-black;
              cursor: pointer;
            }
          }

          .footer {
            color: $off-white;
            font-weight: 500;
            font-size: 11px;
            text-align: center;
          }
        }
      }

      &--field {
        height: 40px;

        &.checkbox-field {
          margin-top: 20px;
          margin-right: 0;
          width: 270px;

          .MuiButtonBase {
            &-root {
              color: $steel;
              padding: 8px;
              transition: unset;
            }
          }

          .MuiTypography {
            &-root {
              font-style: normal;
              font-weight: 600;
              font-size: $font-size-md;
              letter-spacing: unset;
              color: $steel;
              padding-top: 1px;
            }
          }
        }

        .MuiInputBase {
          &-root {
            height: 40px !important;
          }

          &-input {
            &.MuiInputBase-inputAdornedStart {
              padding-top: 15.5px !important;
            }

            &:not(.MuiInputBase-inputAdornedStart) {
              padding-top: 17.5px !important;
            }
          }

          &-adornedStart {
            padding-top: 3px;

            img {
              margin-right: 8px;
              margin-top: -3px;
            }
          }

          &-adornedEnd {
            img {
              margin-left: 8px;
              margin-top: -3px;
            }
          }
        }
      }

      &--fields {
        display: flex;

        .user-page__advanced-search__filters--field {
          width: 120px;

          .MuiInputBase {
            &-root {
              width: 120px;
            }
          }
        }

        .field-seperator {
          margin: auto;
        }
      }

      &--multi-field {
        .MuiInputBase {
          &-root {
            height: unset !important;
            min-height: 40px;
          }
        }

        .MuiAutocomplete {
          &-popupIndicator {
            color: $pale-gray;
          }

          &-clearIndicator {
            color: $off-white;

            &:hover {
              color: $light-gray;
            }
          }
        }
      }

      &--button {
        font-style: normal !important;
        font-size: $font-size-mdlg !important;
        font-weight: 500 !important;
        text-transform: none !important;
        color: $light-gray !important;
        background-color: $bronze !important;
        box-shadow: none !important;
        width: 260px;
        height: 40px;
        margin-top: 35px !important;
        margin-bottom: 10px !important;
        padding-top: 6px !important;
        padding-bottom: 4px !important;

        &.Mui-disabled {
          color: $off-white !important;
          background-color: $steel !important;
        }

        &.clear-button {
          margin-top: 0 !important;
          margin-bottom: 25px !important;
          background-color: $pine !important;
        }
      }

      &--popper {
        .MuiAutocomplete {
          &-paper {
            border: 1px solid $steel !important;
          }
          &-listbox {
            padding-top: 0;

            .group-label {
              position: sticky;
              font-weight: 500;
              font-size: $font-size-sm;
              height: 33px;
              background-color: $bold-pine;
              color: white;
              line-height: 33px;
              top: 0;
            }
          }
        }
      }
    }

    &__list {
      position: relative;
      display: flex;
      flex: 1 1;
      width: 100%;

      .filters-panel {
        flex: unset;
        width: 380px !important;
        flex-shrink: 0;
      }

      &--container {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        width: 100%;

        .panel {
          &__scrollable {
            height: 100%;
          }
        }

        .spinner {
          margin-top: 0;
          padding: 0;
        }

        .categories {
          .category {
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      &__header {
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 100px;

        &--left {
          display: flex;
          flex: 1 1;
        }

        &--right {
          display: flex;
        }

        &--count {
          font-size: $font-size-mdlg;
          color: $off-white;
          line-height: 22px;
          margin-right: auto;
        }

        &--legend {
          display: flex;

          .availability {
            display: flex;
            margin-right: 20px;

            .circle {
              border-radius: 10px;
              margin-right: 10px;
              margin-top: 5px;
              height: 10px;
              width: 10px;
              background-color: $bright-yellow;

              &.full {
                background-color: $light-orange;
              }
            }

            .title {
              color: $light-gray;
              font-weight: 600;
              font-size: $font-size-md;
            }
          }
        }

        &--sort {
          display: flex;

          .user-page__advanced-search__filters--multi-field {
            width: 175px;
            margin-top: -10px;
            margin-left: 20px;

            &.narrow-field {
              width: 150px;

              .MuiInputBase-root {
                width: 150px;
              }
            }

            .MuiInputBase-root {
              width: 175px;
            }
          }

          .title {
            color: $light-gray;
            font-weight: 600;
            font-size: $font-size-md;
          }
        }
      }

      &--users,
      &--syndications,
      &--scripts {
        .loading-row {
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          position: relative;
        }
      }

      &--users,
      &--syndications {
        & > div {
          &:last-child {
            margin-bottom: 50px;
          }
        }
      }

      &--users,
      &--scripts {
        display: flex;
        flex-wrap: wrap;

        .loading-row {
          animation-name: placeHolderShimmer;
        }
      }

      &--users {
        margin-right: 75px;

        .loading-row {
          background: linear-gradient(
            to right,
            $black 8%,
            $charcoal 18%,
            $black 33%
          );
          background-size: 910px 410px;
        }
      }

      &--scripts {
        margin-right: 100px;

        .public-script {
          width: 399px;

          &.loading-row {
            height: 73px;
            background: linear-gradient(
              to right,
              $off-black 8%,
              $charcoal 18%,
              $off-black 33%
            );
            background-size: 910px 410px;

            &::after,
            &::before {
              content: "";
              height: 21px;
              border-radius: 5px;
              background-color: $charcoal;
              margin-bottom: 5px;
            }
          }
        }
      }

      &--syndications {
        .loading-row {
          animation-name: placeHolderShimmerLong;
          background: linear-gradient(
            to right,
            $black 8%,
            $charcoal 18%,
            $black 33%
          );
          width: 100%;
          min-width: 1100px;
          border-left: 5px solid $placeholder;

          &::after,
          &::before {
            position: absolute;
            content: "";
            height: 15px;
            width: 220px;
            border-radius: 5px;
            background-color: $charcoal;
            margin-left: 15px;
          }

          &::after {
            bottom: 15px;
          }

          &::before {
            top: 15px;
          }
        }

        .name-shadow {
          position: sticky;
          left: 245px;
          box-shadow: 1px 1px 3px 2px $black;
          margin-top: 2px;
          height: 64px;
          width: 0;
          padding: 0 !important;
        }

        .shadow-cover {
          position: absolute;
          left: 244px;
          width: 5px;
          height: 70px;
          background-color: $off-black;
          padding: 0 !important;
        }

        .header {
          position: sticky;
          top: 0;
          z-index: 2;
          background-color: $black;
          display: flex;
          width: 1155px;
          padding-left: 0;
          padding-bottom: 0;
          padding-right: 100px;
          color: $off-white;
          font-size: 13px;
          font-weight: 500;

          & > div {
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 15px;
            text-align: right;
            display: flex;
            flex-wrap: nowrap;
            flex-shrink: 0;
            line-height: 18px;

            &.sorted {
              font-weight: 600;
            }

            &.sortable {
              &:hover {
                color: $light-gray;
                cursor: pointer;
              }
            }

            .sort {
              margin-right: 5px;
              margin-top: auto;
              margin-bottom: auto;
              width: 12px;
              height: 12px;

              &.asc {
                transform: rotate(180deg);
              }
            }

            & > *:first-child {
              margin-left: auto;
            }

            span {
              margin-top: auto;
              margin-bottom: auto;
            }

            .infoIcon {
              flex-shrink: 0;
              margin-top: auto;
              margin-bottom: auto;
              transform: translateY(-1px);
            }
          }

          .name-shadow {
            height: 30px;
          }

          .shadow-cover {
            height: 36px;
            background-color: $black;
          }

          .name {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: $black;
            width: 220px;
            padding-left: 20px;
            text-align: left;

            & > *:first-child {
              margin-left: unset;
            }
          }

          .exchange {
            width: 165px;

            & > *:last-child {
              margin-right: auto;
            }
          }

          .performance,
          .mdd {
            width: 100px;
          }

          .asset {
            width: 125px;
            text-align: left;

            & > *:first-child {
              margin-left: unset;
            }
          }

          .runtime,
          .allocation {
            width: 90px;
          }

          .chart {
            width: 95px;

            span {
              margin: auto;
            }
          }

          .min-allocation {
            width: 120px;
          }

          .fees {
            width: 50px;
            padding-right: 10px;

            span {
              margin: auto;
            }
          }
        }

        .table-body {
          display: table;
        }

        .syndication-card {
          position: relative;
          display: flex;
          flex-shrink: 0;
          height: 70px;
          background-color: $off-black;
          border-radius: 15px;
          margin-bottom: 10px;

          &:hover {
            cursor: pointer;
            background-color: $dark;

            .syndication-card--name {
              background-color: $dark;
            }

            .shadow-cover {
              background-color: $dark;
            }
          }

          &.fully-allocated {
            .syndication-card {
              &--name {
                border-left: 5px solid $light-orange;
              }
            }
          }

          & > div {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-wrap: nowrap;
            flex-shrink: 0;
          }

          &--name {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: $off-black;
            display: flex;
            flex-direction: column;
            width: 220px;
            border-left: 5px solid $bright-yellow;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding-left: 15px;
            padding-right: 5px;
            padding-top: 2px;

            .syndication-name {
              color: $light-gray;
              font-weight: 500;
              font-size: $font-size-mdlg;
              margin-top: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .owner-name {
              color: $off-white;
              font-weight: 500;
              font-size: $font-size-sm;
              margin-top: 0;
              margin-bottom: auto;
            }
          }

          &--exchange {
            width: 165px;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: auto;
            margin-bottom: auto;

            img {
              &:not(:last-child) {
                margin-right: 8px;
              }
            }

            div {
              margin: auto;
            }
          }

          &--tags {
            width: 125px;
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 5px;
            padding-right: 5px;
          }

          &--performance {
            .pos {
              color: $light-green;
            }

            .neg {
              color: $red-error;
            }
          }

          &--performance,
          &--mdd {
            width: 100px;
            padding-left: 5px;
            padding-right: 5px;
          }

          &--runtime,
          &--allocation {
            width: 90px;
            padding-left: 5px;
            padding-right: 5px;
          }

          &--chart {
            height: 45px;
            width: 95px;
            padding: 13px 5px 12px;

            *:hover {
              cursor: pointer;
            }
          }

          &--min-allocation {
            width: 120px;
            padding-left: 5px;
            padding-right: 5px;
          }

          &--fees {
            width: 55px;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;

            .fees {
              display: flex;
              flex-wrap: nowrap;

              img {
                &:first-child {
                  margin-right: 6px;
                }

                &:not(:last-child):not(:first-child) {
                  margin-right: 5px;
                }
              }
            }
          }

          &--performance,
          &--mdd,
          &--runtime,
          &--allocation,
          &--min-allocation,
          .empty-chart {
            height: unset;
            color: $light-gray;
            font-weight: 500;
            font-size: $font-size-mdlg;
            padding: 1px 9px 0 1px;
            margin-top: auto;
            margin-bottom: auto;

            span {
              text-align: right;
              width: 100%;
            }
          }

          .empty-chart {
            span {
              text-align: center;
            }
          }
        }
      }

      &--empty {
        margin: 150px auto;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        text-align: center;

        .empty-icon {
          width: 57px;
          height: 67px;
          margin: 0 auto;
        }

        .empty-header {
          color: $white;
          font-weight: 600;
          font-size: $font-size-xxxl;
        }

        .empty-body {
          color: $light-gray;
          font-weight: 500;
          font-size: $font-size-xlg;
        }
      }
    }
  }
}

@media (min-width: 1770px) {
  .user-page {
    &__advanced-search {
      &__list {
        &--syndications {
          .name-shadow,
          .shadow-cover {
            display: none !important;
          }

          .header {
            width: unset;

            & > div {
              margin-left: auto;
              margin-right: auto;
            }
          }

          .table-body {
            display: flex;
            flex-direction: column;
            margin-right: 100px;

            .syndication-card {
              border-left: 5px solid $bright-yellow;

              &.fully-allocated {
                border-left: 5px solid $light-orange;

                .syndication-card {
                  &--name {
                    border-left: 0;
                  }
                }
              }

              &--name {
                border-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .user-page {
    &__advanced-search {
      &__tabs {
        &:not(.categories) {
          margin-right: 20px;
          margin-left: 20px;
        }

        &.categories {
          .category {
            &:first-child {
              margin-left: 20px;
            }
          }
        }
      }

      &__filters {
        margin-left: 0px;
      }

      &__list {
        padding-left: 0;

        .filters-panel {
          flex: unset;
          width: 300px !important;
        }

        &--container {
          margin-left: 20px;
        }

        &__header {
          margin-right: 20px;
        }

        &--users {
          margin-right: 0;
        }

        &--syndications {
          .header {
            padding-right: 20px;
          }
        }

        &--scripts {
          margin-right: 0;

          .public-script {
            width: calc(100% - 76px);
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .user-page {
    &__advanced-search {
      &__list {
        &__header {
          &.users-header {
            margin: 0;
          }

          &--mobile-filters-button {
            display: none !important;
          }
        }

        &--container {
          .categories {
            margin-bottom: 30px;
            margin-top: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .user-page {
    &__advanced-search {
      &__list {
        .filters-panel {
          display: none;
        }

        &__header {
          &--left {
            flex-direction: column;
          }

          &--count {
            margin-bottom: 10px;
          }

          &--mobile-filters-button {
            font-size: $font-size-md !important;
            font-weight: bold !important;
            text-transform: none !important;
            color: $light-gray !important;
            background-color: $bronze !important;
            box-shadow: none !important;
            width: auto;
            height: 40px;
            margin-top: -10px !important;
            margin-left: 20px !important;

            img {
              margin-right: 15px;
            }

            div {
              position: absolute;
              top: -5px;
              right: -5px;
              background-color: $white;
              height: 15px;
              width: 15px;
              border-radius: 15px;
              font-size: $font-size-xxs;
              color: $peacock;
              border: 3px solid $peacock;
            }
          }
        }

        .categories {
          @media (min-width: 768px) {
            margin-bottom: -75px;
          }
        }
      }
    }
  }

  .mobile-filters-modal {
    height: 100%;
    max-height: unset !important;
    width: 100%;
    max-width: unset !important;
    padding: 0 !important;
    position: absolute !important;
    background-color: $off-black !important;

    .filters-panel {
      height: 100%;

      .panel {
        &__inner {
          height: 100%;
        }
      }

      .close-button {
        position: sticky;
        top: 20px;
        margin-left: auto;
        margin-right: 8px;
        width: 12px;
      }

      .user-page {
        &__advanced-search {
          &__filters {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: calc(100% - 200px);
            margin-left: 100px;
            margin-right: 100px;
            margin-top: 100px;
            padding: 0;

            &--body {
              display: flex;
            }

            &--bottom {
              margin-left: auto;

              .field-seperator {
                width: 20px;
              }
            }

            &--title {
              position: relative;
              background-color: unset;
              padding-top: 0;
            }

            &--field {
              &.checkbox-field {
                margin-top: 43px;
              }
            }

            &--button {
              width: 100%;
              margin-bottom: 0 !important;
              margin-top: auto !important;

              & + .user-page__advanced-search__filters--button {
                margin-top: 20px !important;
                margin-bottom: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .user-page {
    &__advanced-search {
      &__list {
        &__footer {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .user-page {
    &__advanced-search {
      &__tabs {
        padding: 0;
        border-radius: 0;

        &:not(.categories) {
          height: 50px;
          margin: 0;
        }

        &--tab {
          flex: 1 1;
          padding: 15px 0;
          border-radius: 0;
          text-align: center;
          vertical-align: middle;

          &:not(:last-child) {
            border-right: 1px solid $peacock;
          }
        }
      }

      &__list {
        &--container {
          margin-left: 0;
        }

        &__header {
          position: sticky;
          left: 20px;
          flex-direction: column;
          margin-top: 20px;
          margin-left: 20px;
          margin-bottom: 15px;

          &--left {
            flex-direction: row;
            margin-bottom: 15px;

            &.no-sort {
              margin-bottom: 0px;
            }
          }

          &--legend {
            display: none;
          }

          &--sort {
            display: flex;
            position: relative;
            margin-right: 10px;
            margin-top: 15px;

            &.scripts-sort {
              .user-page__advanced-search__filters--multi-field {
                width: 135px !important;

                .MuiInputBase-root {
                  width: 135px !important;
                }
              }
            }

            .title {
              position: absolute;
              top: -38px;
            }

            .user-page__advanced-search__filters--multi-field {
              margin-left: 0;

              & + .user-page__advanced-search__filters--multi-field {
                margin-left: 10px;
                width: 85px !important;

                .MuiInputBase-root {
                  width: 85px !important;
                }
              }
            }
          }

          &--mobile-filters-button {
            width: 50px;
            min-width: unset !important;
            margin-top: 5px !important;
            margin-left: 0 !important;

            .MuiButton {
              &-label {
                img {
                  margin-right: 0;
                }

                span {
                  display: none;
                }
              }
            }
          }
        }

        &--users,
        &--scripts {
          margin-left: 20px;
        }

        &--users {
          margin-right: 20px;
          width: calc(100% - 40px);
        }

        &--syndications {
          .header {
            position: relative;
            top: unset;

            .name {
              position: relative;
              padding-left: 40px;
            }
          }

          .syndication-card {
            margin-right: 20px;
            margin-left: 20px;

            &--name {
              position: relative;
            }
          }

          .name-shadow {
            display: none !important;
          }

          .shadow-cover {
            display: none !important;
          }
        }

        &--empty {
          margin: 50px 20px 50px 0;
          display: flex;
          flex-direction: column;
          text-align: center;
        }

        &__footer {
          display: flex;
          flex-direction: column;

          &--legend {
            display: flex;
            margin: auto;

            &:not(:last-child) {
              margin-top: 10px;
              margin-bottom: 5px;
            }

            &:last-child {
              margin-bottom: 10px;
            }

            .item {
              display: flex;

              &:not(:last-child) {
                margin-right: 15px;
              }

              img {
                margin-right: 5px;
                margin-top: -2px;
              }

              .circle {
                border-radius: 10px;
                margin-right: 5px;
                margin-top: 4px;
                height: 10px;
                width: 10px;
                background-color: $bright-yellow;

                &.full {
                  background-color: $light-orange;
                }
              }

              .title {
                color: $off-white;
                font-weight: 500;
                font-size: $font-size-sm;
              }

              .infoIcon {
                margin-top: 2px;
              }
            }
          }
        }

        .mobile-syndications-header {
          display: flex;
          width: 1155px;
        }
      }
    }
  }

  .mobile-filters-modal {
    .filters-panel {
      .panel {
        &__scrollable {
          height: unset;
        }
      }

      .close-button {
        margin-right: 20px;
      }

      .user-page {
        &__advanced-search {
          &__filters {
            height: 100%;
            width: calc(100% - 40px);
            margin: 20px;
            padding: 0;

            &--body {
              flex-direction: column;
              padding-bottom: 40px;
            }

            &--bottom {
              margin-left: unset;

              .field-seperator {
                width: 20px;
              }
            }

            &--title {
              background-color: unset;
              padding-top: 0;
            }

            &--field {
              &.checkbox-field {
                margin-top: 20px;
              }
            }

            &--field,
            &--multi-field {
              width: 100%;

              .MuiInputBase {
                &-root {
                  width: 100%;
                }
              }

              .MuiFormControl {
                &-root {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -455px 0;
  }
  100% {
    background-position: 455px 0;
  }
}

@keyframes placeHolderShimmerLong {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
