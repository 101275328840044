@import "../../../styles/colors";
@import "../../../styles/typography";

.launch-checklist {
  border-left: 13px solid $pine;
  width: 587px;
  height: 410px;

  &--container {
    width: 600px;
    height: 410px;
    padding: 0 !important;
    background-color: $off-black !important;
  }

  &--close {
    position: absolute;
    right: 16px;
    top: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  &__header {
    display: inline-flex;
    margin: 45px 40px 20px;
    font-style: normal;
    font-weight: 500;

    &--logo {
      height: 27px;
      width: 27px;
      margin-right: 15px;
    }

    &--title {
      color: $light-gray;
      font-size: $font-size-lg;
    }

    &--subtitle {
      color: $off-white;
      font-size: $font-size-mdlg;
    }
  }

  &__body {
    &--row {
      display: inline-flex;
      font-weight: 500;
      font-size: $font-size-md;
      color: $light-gray;
      margin: 0 29px 15px 45px;

      &:last-child {
        margin-bottom: 0;
      }

      &.mobile-prompt {
        display: none;
      }

      &:hover {
        cursor: pointer;
      }

      img {
        margin-right: 20px;
        margin-top: 4px;
        height: 14px;
        border: 1px solid $off-black;

        &:hover {
          border: 1px solid $steel;
        }
      }

      div {
        span {
          color: $light-yellow;
        }
      }
    }

    &--link {
      margin-left: 7px;

      img {
        position: absolute;
        margin: 3px 0 0;
        border: 0;
        opacity: 0.75;

        &:hover {
          opacity: 1;
          border: 0;
        }
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 40px;
    left: 40px;

    &--button {
      height: 50px;
      width: 132px;
      font-style: normal;
      font-weight: 600 !important;
      background-color: #b57834 !important;
      color: $white !important;
      text-transform: none !important;
      padding-top: 8px !important;
      padding-bottom: 4px !important;

      &.Mui-disabled {
        background-color: #393939 !important;
        color: $off-white !important;
      }
    }

    &--checkbox {
      display: inline-flex;
      font-weight: 600;
      font-size: $font-size-md;
      color: $off-white;
      margin-left: 20px;
      vertical-align: middle;
      padding-top: 1px;
      margin-top: 1px;

      &:hover {
        cursor: pointer;
      }

      img {
        margin-right: 10px;
        border: 1px solid $off-black;
        height: 14px;
        width: 14px;

        &:hover {
          border: 1px solid $steel;
        }
      }

      div {
        line-height: 19px;
      }
    }
  }
}

@media (max-width: 767.98px), (max-height: 560px) {
  .launch-checklist {
    max-width: 100%;
    width: 600px;
    border-left: 0;
    border-top: 13px solid $pine;
    height: unset;

    &--container {
      height: unset;

      @media (max-height: 660px) {
        max-height: calc(100% - 0px) !important;
      }
    }

    &--close {
      top: 29px;
    }

    &__header {
      text-align: center;
      width: calc(100% - 40px);
      margin: 35px 20px 15px;

      &--head {
        width: 100%;
      }

      &--subtitle {
        line-height: 22px;
        margin-top: 3px;
      }

      &--logo {
        position: absolute;
        left: calc(50% - 100px);
        margin: 0;
      }
    }

    &__body {
      margin-bottom: calc(50px + 12.5%);

      &--row {
        margin: 0 15px 5%;

        &.mobile-prompt {
          display: inline-flex;
        }
      }
    }

    &__footer {
      left: unset;
      bottom: 5%;
      width: 100%;

      &--button {
        display: flex !important;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 90%;
      }

      &--checkbox {
        display: none;
      }
    }
  }
}

@media (max-height: 560px) {
  .launch-checklist {
    &__body {
      margin-bottom: 20px;
    }

    &__footer {
      position: relative;
    }
  }
}
