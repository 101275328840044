@import "../styles/colors";

$font-size-xxxs: 9px;
$font-size-xxs: 0.625rem;
$font-size-sm: 0.85rem;
$font-size-wxs: $font-size-sm;
$font-size-xs: $font-size-sm;
$font-size-ss: 0.75rem;
$font-size-s: 0.725rem;
$font-size-md: 0.9rem;
$font-size-mdlg: 1rem;
$font-size-lg: 1.1rem;
$font-size-xlg: 1.2rem;
$font-size-xmlg: 1.5rem;
$font-size-xxl: 1.7rem;
$font-size-xxxl: 2.1rem;

a {
  color: $off-white;
}

$breakpoint-trades: 1281px;
$breakpoint-trades-off: 1281.98px;
$breakpoint-trades-stage: 1282px;
$breakpoint-trades-with-composer: 1542px;
$breakpoint-trades-with-composer-off: 1541.98px;
