@import "../../styles/colors";
@import "../../styles/typography";

.nav__item {
  &.stage {
    clip-path: polygon(
      calc(100% - 10px) 0%,
      100% 50%,
      calc(100% - 10px) 100%,
      10px 100%,
      20px 50%,
      10px 0%
    );
    border-right: 0;
    transform: translateX(0);

    &--experiments {
      clip-path: polygon(
        calc(100% - 10px) 0%,
        100% 50%,
        calc(100% - 10px) 100%,
        0% 100%,
        0% 0%
      );
      background: #21283b !important;

      &:hover {
        background: #2046ad !important;
      }

      .nav__link {
        padding: 3px 20px 1px 12px;

        &--active {
          background-color: #2046ad !important;
        }
      }
    }

    &--candidates {
      transform: translateX(-18px);
      background: #283c50 !important;

      &:hover {
        background: #3e77ac !important;
      }

      .nav__link {
        &--active {
          background-color: #3e77ac !important;
        }
      }
    }

    &--release-candidates {
      margin-left: -36px;
      background: #1b2629 !important;

      &:hover {
        background: #156166 !important;
      }

      .nav__link {
        &--active {
          background-color: #156166 !important;
        }
      }

      & + .nav__item {
        &--divider:not(.middle-right) {
          &:before {
            content: "";
            width: 20px;
            height: 30px;
            margin-left: -19px;
            background: $nav--secondary-background-color;
            clip-path: polygon(
              100% 0%,
              100% 50%,
              100% 100%,
              10px 100%,
              20px 50%,
              10px 0%
            );
          }
        }
      }
    }

    &--live {
      transform: translateX(-54px);
      background: #2e251a !important;

      &:hover {
        background: #975503 !important;
      }

      .nav__link {
        &--active {
          background-color: #975503 !important;
        }

        @media (max-width: 409.97px) {
          padding-right: 10px !important;
        }
      }
    }

    &--syndication {
      margin-left: -72px;
      background: #1c251d !important;

      &:hover {
        background: #356933 !important;
      }

      .nav__link {
        &--active {
          background-color: #356933 !important;
        }
      }
    }

    &:not(.stage--experiments) {
      .nav__link {
        padding: 3px 20px 1px 30px;
      }
    }

    .new-tests {
      position: absolute;
      right: 8px;
      top: 3px;
      background: #aa3d3d;
      height: 12px;
      width: 12px;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      vertical-align: middle;
      border-radius: 11px;
    }
  }

  .stage {
    &--desktop {
      @media (max-width: 956.98px) {
        display: none;
      }
    }

    &--mobile {
      @media (min-width: 957px) {
        display: none;
      }

      &.experiments {
        @media (max-width: 956.98px) {
          margin-left: 5px;
        }
      }
    }

    &--non-small-mobile {
      @media (max-width: 409.97px) {
        display: none;
      }
    }
  }
}

.strategy-index {
  &.open-composer {
    .main-nav {
      &.visible-right-nav:not(.scripts) {
        &.on-live-or-syndication {
          .nav {
            &__item {
              .stage {
                &--desktop {
                  @media (max-width: $breakpoint-trades-with-composer) {
                    display: none;
                  }
                }

                &--mobile {
                  @media (max-width: $breakpoint-trades-with-composer) {
                    display: unset;

                    &.experiments {
                      margin-left: 5px;
                    }
                  }
                }
              }
            }
          }
        }

        .nav {
          &__item {
            .stage {
              &--desktop {
                @media (max-width: 1781.98px) and (min-width: $breakpoint-trades-with-composer) {
                  display: none;
                }
              }

              &--mobile {
                @media (min-width: 1782px) {
                  display: none;
                }

                @media (max-width: 1781.97px) and (min-width: $breakpoint-trades-with-composer) {
                  display: unset;
                }

                &.experiments {
                  @media (max-width: 1781.97px) and (min-width: $breakpoint-trades-with-composer) {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .nav {
      &__item {
        .stage {
          &--desktop {
            @media (max-width: 1110.98px) {
              display: none;
            }
          }

          &--mobile {
            @media (min-width: 1111px) {
              display: none;
            }

            @media (max-width: 1110.98px) {
              display: unset;
            }

            &.experiments {
              @media (max-width: 1110.98px) {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }

    .scripts {
      .nav__container:not(.right-nav) {
        .nav {
          &__item {
            &--divider {
              @media (max-width: 806.98px) {
                border-right: 2px solid $black;
              }
            }

            &.stage {
              &--live {
                .nav__link {
                  @media (max-width: 935.97px) {
                    padding-right: 10px;
                  }
                }
              }
            }

            .stage {
              &--desktop {
                @media (max-width: 1547.97px) {
                  display: none;
                }
              }

              &--mobile {
                @media (min-width: 1548px) {
                  display: none;
                }

                @media (max-width: 1547.97px) {
                  display: unset;
                }

                &.experiments {
                  @media (max-width: 1547.97px) {
                    margin-left: 5px;
                  }
                }
              }

              &--non-small-mobile {
                @media (max-width: 935.97px) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .main-nav {
    &.scripts {
      .nav {
        &__item {
          .stage {
            &--desktop {
              @media (max-width: 1235.97px) {
                display: none;
              }
            }

            &--mobile {
              @media (min-width: 1236) {
                display: none;
              }

              @media (max-width: 1235.97px) {
                display: unset;
              }

              &.experiments {
                @media (max-width: 1235.97px) {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
