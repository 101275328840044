@import "../../styles/colors";
@import "../../styles/typography";

.settings-page {
  .settings {
    &__general {
      &--body {
        &.referrals {
          display: flex;
          flex-direction: column;
          height: calc(100% - 70px);

          .referral-input {
            display: flex;

            &--field {
              margin-right: 10px;

              .Mui-error {
                color: $red-error !important;

                .MuiOutlinedInput {
                  &-input {
                    color: $red-error !important;
                  }

                  &-notchedOutline {
                    border-color: $red-error !important;
                  }
                }
              }

              .MuiInputBase {
                &-input {
                  padding: 7px 14px;
                }
              }

              .MuiInputLabel {
                &-outlined {
                  transform: translate(14px, 11px) scale(1);
                }
              }
            }

            .field-button {
              margin-bottom: 0;

              &.Mui-disabled {
                background-color: rgba(85, 85, 85, 0.5);
                color: $steel;
              }
            }
          }

          .panel {
            &__inner {
              margin: 0;
            }
          }

          .settings__general {
            &--header {
              margin-bottom: 0;
            }

            &--field {
              &.field-button {
                width: 200px;
              }
            }

            &--info {
              margin-top: 20px;

              &.error {
                margin-top: 10px;
                margin-bottom: 15px;
                color: $red-error;
                line-height: 15px;
              }

              & + .settings__general--info {
                margin-top: 0;
              }

              a {
                color: $bronze;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 997.98px) and (min-width: 768px) {
  .settings-page {
    .settings {
      &__general {
        &--body {
          &.referrals {
            width: calc(100% - 70px);
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .settings-page {
    .settings {
      &__general {
        &--body {
          &.referrals {
            padding-right: 0;

            .referral-input {
              flex-direction: column;

              &--field {
                margin-right: 0;
                margin-bottom: 10px;
              }
            }

            .settings {
              &__general {
                &--header {
                  text-align: center;
                }
              }
            }

            .panel {
              margin-left: 25px;
              margin-right: 25px;
            }
          }
        }

        &--referral-link {
          width: calc(100% - 50px);
          margin: auto;
          text-align: center;

          .allow-list__header__input--field {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
}
