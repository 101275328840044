@import "../../../styles/colors";
@import "../../../styles/typography";

.multivariantDetails {
  .header {
    .settings-gear {
      position: absolute;
      padding-top: 3px;
    }

    span.title {
      color: $off-white;
      font-size: $font-size-md;
      cursor: pointer;
      user-select: none;
      padding-left: 20px;
      padding-right: 10px;
    }

    .copy {
      float: right;
      margin-right: 3px;
      margin-left: auto;
      color: $blue;
      cursor: pointer;

      &:hover {
        color: $dark-blue;
      }
    }

    .multivariantProgress {
      margin-top: 10px;
    }

    i {
      cursor: pointer;
      border: solid $blue;
      border-width: 0 5px 5px 0;
      display: inline-block;
      padding: 2px;
      margin-right: 5px;
      position: absolute;
      margin-left: 10px;

      &.collapsed {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-top: 9px;
      }
      &.expanded {
        margin-top: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
  .details {
    display: flex;
    background-color: $off-black;

    div {
      flex: 1;
    }
  }
}
