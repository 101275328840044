@import "../../styles/colors";
@import "../../styles/typography";

.subscription-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 620px;
  max-width: 100%;
  max-height: 100%;
  margin: auto;

  .syndication-tags {
    display: inline-flex;
    flex-wrap: wrap;

    .syndication-tag {
      height: 16px;
      background-color: $light-orange;
      color: $dark-pine;
      font-size: $font-size-sm;
      padding: 0 5px;
      margin-right: 5px;
      line-height: 19px;
      font-weight: 600;

      &.gray {
        background-color: $steel;
      }

      &.white {
        background-color: $white;
      }
    }
  }

  &--container {
    background-color: transparent !important;
    padding: 0 !important;

    // &.slide-up {
    //     animation: topSlide 1s;
    // }
    //
    // &.slide-left {
    //     animation: leftSlide 1s;
    // }
  }

  &--close {
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__overview {
    &.direct-payment-overview {
      .subscription-modal__overview__info {
        margin-top: 0;

        .title {
          margin-bottom: 25px;
        }
      }
    }

    &.direct-payment-info {
      .subscription-modal {
        &__overview {
          &__info {
            margin-top: 0;
            padding-bottom: 0;

            .title {
              display: flex;
              margin-bottom: 25px;

              .coin-select {
                margin-top: -2px;
                margin-left: auto;

                .MuiInputBase {
                  &-root {
                    height: 40px !important;
                  }
                }

                .MuiSelect {
                  &-root {
                    padding-top: 15px;
                    padding-bottom: 13px;

                    img {
                      margin-right: 5px;
                      margin-bottom: -5px;
                    }
                  }

                  &-icon {
                    color: $pale-gray;
                  }
                }
              }
            }
          }
        }

        &--button {
          &.MuiButtonBase {
            &-root {
              margin-top: 30px !important;
            }
          }
        }
      }

      .qr-code {
        background-color: white;
        border-radius: 5px;
        width: 100px;
        height: 100px;
        margin: auto 20px auto 0;
        padding: 10px;
      }
    }

    &__header {
      background-color: black;
      display: flex;
      padding: 0 20px;
      margin-bottom: 45px;
      color: $light-gray;
      font-size: $font-size-mdlg;
      height: 60px;

      &--left {
        font-weight: normal;
        margin: auto auto auto 0;
      }

      &--right {
        font-weight: bold;
        margin: auto 0 auto auto;
      }
    }

    &__subinfo {
      .title {
        font-weight: 600;
        font-size: $font-size-lg;
      }

      .info {
        font-weight: normal;
        font-size: $font-size-md;
        color: $off-white;
      }
    }

    &__direct-payment-container {
      display: flex;
      padding: 25px 0;

      & + .subscription-modal__overview__direct-payment-container {
        border-top: 1px solid black;
      }
    }

    &__direct-payment {
      display: flex;
      flex-wrap: wrap;

      &--fieldname {
        width: 100%;
        font-size: $font-size-sm;
        font-weight: 600;
        margin-top: 20px;
      }

      &--field {
        &.MuiTextField {
          &-root {
            width: 100%;
            height: 40px;
            flex: 1 1;
            min-width: 80%;
            margin-right: 10px;
          }
        }

        .MuiInputBase {
          &-root {
            height: 40px !important;
          }

          &-input {
            padding-top: 13px;
            padding-bottom: 11px;
          }
        }
      }

      &--field-info {
        display: flex;
        font-weight: normal;
        font-size: $font-size-md;
        color: $off-white;
        width: 100%;
        line-height: 15px;
        margin-top: 5px;

        &.warning {
          color: $pale-yellow;
        }

        div {
          padding-top: 5px;
        }

        img {
          margin-right: 5px;
          margin-top: auto;
          margin-bottom: auto;
          height: 16px;
          width: 16px;
          padding-top: 2px;
        }
      }

      &--wallet-id {
        display: inline-flex;
        position: relative;
        width: 100%;
        flex: 1 1;
        min-width: 80%;
        margin-right: 10px;
        font-weight: normal;
        font-size: $font-size-md;
        color: $off-white;

        .wallet-id {
          display: flex;
          width: calc(100% - 20px);
          border: 1px solid $off-white;
          border-radius: 5px;
          padding: 7px 10px 3px;
          height: 28px;
          line-height: 28px;

          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      &--button {
        &.MuiButtonBase-root {
          background-color: $pine !important;
          text-transform: none;
          color: $light-gray !important;
          height: 40px;
          min-width: unset;
          padding-top: 8px;
          padding-bottom: 4px;
          margin-right: 10px;
          box-shadow: none;

          @media (max-width: 419.98px) {
            margin-top: 10px;
          }
        }

        &.Mui-disabled {
          opacity: 0.5;
        }

        + .subscription-modal__overview__direct-payment--button {
          margin-right: 0;
        }
      }
    }

    &__column {
      height: 65px;
      display: flex;
      background-color: $charcoal;
      padding: 15px 20px 5px;
      margin-bottom: 10px;
      position: relative;

      &.tuned-subscription {
        .title {
          margin-top: auto;
        }

        .description {
          margin-bottom: auto;
          padding-bottom: 4px;
        }
      }

      &--left {
        margin-right: auto;
        margin-top: -1px;
        display: flex;
        flex-direction: column;

        .title {
          font-weight: 600;
          color: $white;
          font-size: $font-size-lg;
          line-height: 20px;
          margin-bottom: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          img {
            height: 14px;
            margin-right: 5px;
          }
        }

        .description {
          color: $off-white;
          font-size: $font-size-ss;
        }

        .owner-name {
          font-weight: normal;
          color: $silver;
          font-size: $font-size-xs;
          line-height: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .syndication-info {
          display: flex;
          margin-top: auto;
          margin-bottom: -2px;

          .syndication-exchange {
            margin-right: 8px;
            margin-top: -2px;
          }
        }
      }

      &--right {
        color: $light-gray;
        font-size: $font-size-xxxl;
        font-weight: 600;
        align-items: center;
        display: flex;
        margin-bottom: 4px;

        &.crossed-out {
          text-decoration: line-through;
        }

        div {
          font-size: $font-size-md;
          font-weight: normal;
          margin-left: 7px;
        }

        span {
          font-size: $font-size-xlg;

          @media (min-width: 768px) {
            margin-right: 30px;
          }
        }
      }

      .banner {
        position: absolute;
        bottom: 0;
        right: 0;
        font-weight: 600;
        font-size: $font-size-ss;
        text-align: center;
        background-color: $light-green;
        padding: 0 6px 3px;
        height: 15px;
      }
    }

    &__info {
      margin-top: 35px;
      padding-bottom: 30px;
      border-bottom: 1px solid $steel;

      &.no-divider {
        border-bottom: 0;
      }

      .title {
        font-weight: 600;
        color: $light-gray;
        font-size: 25px;
      }

      .checklist {
        color: $light-gray;
        font-size: $font-size-md;
        font-weight: normal;
        display: flex;

        img,
        .circle {
          margin-right: 10px;
        }

        span {
          font-weight: 600;
          margin-left: 3px;
          margin-right: 3px;
        }

        .circle {
          width: 9px;
          height: 9px;
          background-color: $light-green;
          border-radius: 9px;
          margin-top: 5px;
          margin-bottom: auto;
          min-width: 9px;
        }
      }
    }

    &__footer {
      margin: 35px 0;
      color: $off-white;
      font-size: $font-size-md;

      .header {
        font-weight: 900;
        margin-bottom: 10px;

        img {
          margin-right: 5px;
          margin-bottom: -2px;
        }
      }

      .allocation-fee {
        font-weight: normal;
        line-height: 19px;
      }
    }
  }

  &__overview,
  &__payments-details,
  &__loading {
    width: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    padding: 70px;
    background-color: $off-black;
    position: relative;
  }

  &__payments-details {
    color: $light-gray;

    &.credit-card {
      padding-bottom: 30px;
    }

    &--header {
      font-weight: 500;
      font-size: $font-size-xxl;
    }

    &--subheader {
      font-weight: 600;
      font-size: $font-size-mdlg;
    }

    &__methods {
      height: 78px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      &--method {
        width: 226px;
        border: 2px solid $steel;
        border-radius: 5px;

        &.active {
          background-color: rgba(255, 147, 48, 0.1);
        }

        &:hover,
        &.active {
          cursor: pointer;
          border-color: $light-orange;
        }

        &.direct-payment-method {
          display: flex;

          @media (max-width: 990.98px) and (min-width: 768px) {
            width: 110px;
          }

          .method {
            margin: auto;
          }
        }

        .method {
          align-items: center;
          text-align: center;
          letter-spacing: 0.01em;
          font-size: $font-size-xs;
          font-weight: 600;
          margin-top: 5px;
        }

        .options {
          display: flex;
          justify-content: space-between;
          margin-left: 16px;
          margin-right: 16px;
          margin-top: 5px;
        }

        &.disabled {
          opacity: 0.5;

          &:hover {
            cursor: unset;
            border-color: $steel;
          }
        }
      }
    }

    &__credit-card-title {
      margin-top: 25px;
      margin-bottom: 5px;
      color: $light-gray;
      font-weight: 600;
      font-size: $font-size-mdlg;
    }

    &__credit-card {
      height: 50px;
      background-color: $black;
      align-items: center;
      display: flex;
      padding-left: 20px;
      padding-right: 20px;

      .credit-card-type {
        height: 20px;
        margin-right: 10px;
      }

      &--name {
        color: $off-white;
        font-size: $font-size-s;
        margin-top: 3px;
        margin-right: auto;
        display: flex;
      }

      &--button {
        color: $white;
        font-size: $font-size-s;
        margin-top: 3px;
        padding: 1px 0;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        &.no-card {
          margin-left: auto;
        }
      }
    }

    &--footer {
      color: #496b6a;
      font-weight: normal;
      font-size: $font-size-md;
      text-align: center;
      margin-top: 10px;

      img {
        margin-right: 5px;
      }
    }
  }

  &__confirmation-footer {
    height: 135px;
    background-color: $black;
    padding: 45px 70px;

    &__info {
      display: flex;
      justify-content: space-between;

      &--left {
        .title {
          font-weight: 600;
          color: $white;
          font-size: $font-size-lg;
          line-height: 20px;
        }

        .owner-name {
          font-weight: normal;
          color: $silver;
          font-size: $font-size-xs;
          line-height: 13px;
        }
      }

      &--right {
        display: flex;
        align-items: center;
        color: $light-gray;
        font-size: $font-size-lg;
        font-weight: 600;

        div {
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }
  }

  &__loading {
    margin: auto;
    text-align: center;
    height: 190px;

    &--title {
      color: $light-gray;
      font-weight: 500;
      font-size: $font-size-xxl;

      .pending {
        color: $light-orange;
      }
    }

    &--description {
      color: $off-white;
      font-size: $font-size-md;
    }

    &--button {
      &.MuiButtonBase {
        &-root {
          width: auto;
          height: 30px;
          margin: auto auto 0;
          font-style: normal;
          font-weight: 600;
          text-transform: none;
          color: $white !important;
          background-color: $pine !important;
          padding-top: 8px;
          padding-bottom: 4px;
        }
      }
    }

    &--icon {
      &.tuned-logo {
        height: 30px;
      }
      height: 35px;
    }

    &--footer {
      position: absolute;
      bottom: 20px;
      width: calc(100% - 140px);
      text-align: center;
      font-size: $font-size-xs;
      color: $white;
      display: flex;

      a {
        margin: auto;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  &--button {
    width: 100%;
    height: 45px;
    border-radius: 0 !important;
    text-transform: none !important;
    background-color: $bronze !important;
    color: $white !important;
    margin-top: 15px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    font-weight: 400 !important;
    font-size: $font-size-mdlg !important;
    box-shadow: unset !important;
    line-height: 20px !important;
    border-radius: 5px !important;

    &.disabled {
      background-color: rgba(85, 85, 85, 0.5) !important;
      color: $off-white !important;
    }
  }

  &--checkout-link {
    position: fixed;
    display: flex;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    background-color: $off-black;

    iframe {
      border: 0;
    }
  }

  &--cancel-button {
    color: $white;
    font-size: $font-size-xs;
    margin-top: 15px;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 767.98px) {
  .subscription-modal {
    height: 100%;

    &--container {
      position: fixed !important;
      width: 100%;
      height: 100%;
      max-height: 100% !important;
    }

    &__overview,
    &__payments-details,
    &__loading {
      padding: 45px;
      width: calc(100% - 90px);
    }

    &__overview,
    &__loading {
      height: 100%;
    }

    &__overview {
      overflow: auto;

      &.direct-payment-info {
        .qr-code {
          margin: 15px auto;
        }
      }

      &__header {
        flex-direction: column;
        height: 80px;

        &--left {
          margin: auto auto 0 0;
        }

        &--right {
          margin: 0 auto auto 0;
        }
      }

      &__column {
        flex-direction: column;
        height: unset;
        text-align: center;

        &--left {
          margin-right: 0;
          max-width: unset;

          .syndication-info {
            margin: 10px auto;
            flex-wrap: wrap;
            height: auto;

            .syndication-exchange {
              position: relative;
              margin-top: 0;
              max-height: 19px;

              img {
                margin-bottom: 2px;
              }
            }
          }
        }

        &--right {
          margin: auto;
          height: auto;

          div {
            white-space: nowrap;
          }
        }
      }

      &__info {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 35px;

        .title {
          font-size: $font-size-mdlg;
        }

        .checklist {
          line-height: 0.9rem;
          margin-top: 10px;

          .circle {
            margin-top: 1px;
          }
        }
      }

      &__footer {
        border-top: 1px solid $steel;
        padding-top: 35px;
        margin-top: 0;
        margin-bottom: auto;
      }

      &__direct-payment-container {
        flex-direction: column-reverse;
      }
    }

    &__payments-details {
      &__methods {
        flex-direction: column;
        height: unset;

        &--method {
          margin: auto auto 15px;
          padding-bottom: 10px;

          &.direct-payment-method {
            height: 71px;
            padding-bottom: 0;
          }
        }
      }

      &__credit-card {
        height: unset;
        flex-wrap: wrap;
        padding-top: 14px;
        padding-bottom: 15px;
      }
    }

    &__confirmation-footer {
      padding: 30px 45px 45px;
      height: 100%;

      &__info {
        flex-direction: column;
        text-align: center;

        &--left {
          .owner-name {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }

        &--right {
          margin-left: auto;
          margin-right: auto;
          margin-top: 5px;
          font-size: $font-size-mdlg;
        }
      }
    }

    &__loading {
      &--icon {
        margin-top: auto;
      }

      &--title {
        font-size: 1.4rem;
      }

      &--description {
        margin-bottom: auto;
        padding-bottom: 65px;
      }

      &--button {
        margin-top: unset !important;

        &.open-button {
          margin-bottom: 20px !important;
        }
      }

      &--footer {
        width: calc(100% - 90px);
      }
    }
  }
}

@keyframes leftSlide {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }

  32% {
    transform: translateX(200px);
    opacity: 1;
  }

  100% {
    transform: unset;
  }
}

@keyframes topSlide {
  0% {
    margin-top: 200px;
    opacity: 0;
  }

  30% {
    margin-top: 200px;
    opacity: 1;
  }

  100% {
    margin-top: 0;
  }
}
