@import "../../../../styles/colors";
@import "../../../../styles/typography";

.advanced-search-getting-started {
  background-color: $off-black !important;
  padding: 60px 70px !important;
  max-width: 480px;
  border-radius: 15px;

  @media (max-width: 767.98px) {
    padding: 30px 30px !important;

    .user-page {
      &__advanced-search {
        &__getting-started {
          &--header {
            margin-top: 25px;
            font-size: $font-size-mdlg !important;
          }

          &__body {
            &--row {
              .header {
                font-size: $font-size-md !important;
              }
            }
          }
        }
      }
    }
  }

  .user-page {
    &__advanced-search {
      &__getting-started {
        display: flex;
        flex-direction: column;

        &--header {
          color: $light-gray;
          font-weight: 600;
          font-size: $font-size-xxl;
          margin-bottom: 30px;
          line-height: 29px;
        }

        &__body {
          &--row {
            margin-bottom: 20px;

            .header {
              display: flex;
              font-size: $font-size-lg;
              font-weight: 500;
              line-height: 132%;
              margin-bottom: 10px;

              .number {
                height: 18px;
                width: 18px;
                border: 2px solid $light-green;
                border-radius: 22px;
                text-align: center;
                font-size: $font-size-sm;
                font-weight: bold;
                color: $light-green;
                margin-right: 10px;
                margin-top: -1px;
                line-height: 18px;
                flex-shrink: 0;
              }
            }

            .info {
              margin-left: 33px;
              color: $off-white;
              font-size: $font-size-md;
              line-height: 132%;
            }
          }
        }

        .user-page__advanced-search__filters--button {
          position: relative !important;
          bottom: unset !important;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          bottom: 65px;
          font-weight: 400 !important;
        }

        .checkbox-field {
          width: unset;
          margin: auto;

          .MuiButtonBase {
            &-root {
              padding-left: 0;
            }
          }

          .MuiTypography {
            &-root {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
