@import url("https://fonts.googleapis.com/css?family=Noto+Sans:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700");

.noto-theme {
  * {
    &:not(.monaco-editor *) {
      font-family: "Noto Sans" !important;
    }
  }
}

.roboto-theme {
  * {
    &:not(.monaco-editor *) {
      font-family: "Roboto" !important;
    }
  }
}
