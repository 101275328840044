@import "../../styles/colors";
@import "../../styles/typography";

button {
  cursor: pointer;
}

.execution-table-tooltip {
  .MuiTooltip {
    &-tooltip {
      background-color: $black-tooltip !important;
    }
  }

  &__title {
    text-align: left;
    font-weight: 600;
    font-size: $font-size-ss;
    padding: 10px 5px;
    color: $white;
  }

  &__body {
    padding: 10px 5px;
    text-align: left;
    max-width: 280px;
    color: $off-white;
    font-weight: 500;
    font-size: $font-size-ss;

    div {
      div {
        display: inline-block;
      }

      .squared {
        transform: scale(0.7) translateY(-5px);
      }

      .multiplication {
        transform: scale(0.7);
      }
    }
  }
}

.MuiTooltip {
  &-tooltip {
    background-color: $white !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: $steel !important;
    line-height: 15px !important;
  }

  &-arrow {
    color: $white !important;
  }
}

.strategy-index {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  position: relative;

  &.open-composer {
    @media (max-width: 1058.98px) {
      .execution-name {
        display: none;
      }

      .nav__item {
        &--icon {
          .editor-button {
            border-right: 2px solid $black;
          }

          & + .nav__item--icon {
            border-left: 0;
          }
        }
      }

      .revisions-dropdown,
      .open-revisions {
        display: none;
      }

      .right-nav {
        .nav__link {
          padding-left: 7px;
          padding-right: 7px;
        }
      }

      .selected-script {
        max-width: unset !important;
        width: calc(100% - 273px) !important;
      }

      .MuiPaper-root {
        max-width: unset !important;
        width: calc(100% - 222px) !important;
      }
    }

    @media (max-width: 1022.98px) {
      .left-delete-icon {
        display: none;
      }
    }

    @media (max-width: 979.98px) {
      .main-nav {
        &:not(.scripts) {
          .nav__item {
            &--divider.middle-right {
              display: none;
            }
          }
        }
      }
    }

    @media (max-width: 767.98px) {
      .nav {
        &.main-nav {
          display: unset;

          .nav {
            &__item {
              height: 29px;

              &--right {
                margin-left: 0;
                border-right: 2px solid $black;
              }
            }
          }
        }
      }
    }
  }

  .main-panel {
    flex: 1;
    flex-direction: row;
    display: flex;

    &--tables {
      flex: 1;
      flex-direction: column;
      display: flex;
    }

    &.top-panel {
      &.open-portfolio-and-composer {
        .open-portfolio-chart {
          &.open-asset-explorer {
          }
        }
      }
    }

    @media (max-width: 997.98px) {
      width: 100vw;
    }

    .main-nav {
      margin: 0.75rem 0.75rem 0rem 0.75rem;
    }

    .mvt-filters {
      .filter-list {
        padding: 0 0 0 10px;
      }
    }

    .mvt-empty-state {
      margin-top: 39px;
      margin-left: 12px;
    }
  }

  .filter-list-container {
    padding-left: 10px;

    &.scripts-filter {
      flex: 1;

      .filter-list {
        width: unset;
        max-width: unset;
        flex-shrink: 1;

        .has-filters {
          left: unset;
        }
      }
    }

    @media (max-width: 767.97px) {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .narrow-filter-list {
    @media (min-width: $breakpoint-trades-off) {
      width: calc(100% - 566px);
      border-right: 6px solid $off-black;
    }
  }

  .filter-list-loading {
    height: 39px;
  }

  &.collapsed {
    .strategy-index {
      &__panels {
        display: none;
      }
    }
  }

  .button.show-panels {
    position: fixed;
    bottom: 0;
    right: 7rem;
    width: 100px;
    border-color: $gray;
    background-color: $off-black;
    cursor: pointer;
    font-size: $font-size-sm;
    padding: 8px 0;
    border-width: 1px;
    border-bottom: 0;

    &:hover {
      background-color: $dark-gray;
    }

    &.open-composer {
      right: calc(310px + 2rem);
    }

    &.editor-summary {
      z-index: 100;
      right: calc(310px + 2rem + 60px);
    }
  }

  &__panels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    min-height: 20px;
  }

  &__chart {
    flex: 1;

    .TVChartContainer {
      height: 100%;
      display: flex;
    }
  }

  &__top-left-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    &.open-asset-explorer {
    }

    &.open-portfolio-chart:not(.open-asset-explorer) {
    }
  }

  .minimize {
    position: absolute;
    top: 0.75rem;
    right: 1.5rem;
    z-index: 99;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}

.main-total {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.Resizer {
  background: $off-black;
  cursor: row-resize;

  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.noninteractive {
  pointer-events: none;
}

.Pane2 {
  display: flex;
}

.Resizer.horizontal {
  height: 6px;
  width: 100%;
  margin-top: 2px;
}

.Resizer.horizontal::before {
  color: $black;
  content: ".....";
  padding-left: 50%;
  display: block;
  position: relative;
  top: -7px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 8pt;
}

.snackbar-alerts {
  .MuiPaper {
    &-root {
      margin: 10px 0;
    }
  }
}

@media (max-width: 767.98px) {
  .mobile-hidden {
    display: none;
  }
}

@media (min-width: 768px) {
  * {
    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background: $dark-slate;
      border-radius: 10rem;
      border: 5px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
      background-color: transparent;
    }
  }
}
