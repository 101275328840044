@import "../../../../styles/typography";

$checkbox-size: 28px;
$checkbox-font-size: $font-size-sm;

.form__field--boolean {
  input:not(:checked),
  input:checked {
    position: absolute;
    left: -9999px;
  }
  input:not(:checked) + label,
  input:checked + label {
    align-items: center;
    color: $off-white;
    cursor: pointer;
    display: flex;
    font-size: $checkbox-font-size;
    min-height: calc(#{$checkbox-font-size} + 12px);
    position: relative;
    padding: 0 0.5rem 0 ($checkbox-size * 1.5);
    text-transform: none;
  }

  input:not(:checked) + label:before,
  input:checked + label:before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: $checkbox-size;
    height: $checkbox-size;
    border: 2px solid $form__input--dark-border-color;
    background: transparent;
  }

  input:checked + label {
    color: white;

    &:after {
      background: $yellow;
      box-sizing: border-box;
      content: "";
      height: $checkbox-size / 2;
      left: $checkbox-size / 4;
      position: absolute;
      top: $checkbox-size / 4;
      transition: all 0.2s;
      width: $checkbox-size / 2;
    }
  }

  input:disabled:not(:checked) + label:before,
  input:disabled:checked + label:before {
    border-color: $gray;
    background-color: $dark-gray;
  }

  input:disabled:checked + label:after {
    color: $dark-gray;
  }

  input:disabled + label {
    color: $gray;
  }

  input:checked:focus + label:before,
  input:not(:checked):focus + label:before {
    border-color: $gray;
  }
}
