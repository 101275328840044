@import "../../styles/colors";
@import "../../styles/typography";

.settings-container {
  width: 100%;
  height: 100%;
  max-height: unset !important;
  padding: 0 !important;
  border: 0 !important;
}

.settings-page {
  background: $off-black;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .settings {
    &__toggler {
      display: none;
    }

    &__header {
      height: 50px;
      border-bottom: 1px solid $black;
      margin-left: 230px;
      width: calc(100% - 230px);
      position: relative;
      flex-shrink: 0;

      &--breadcrumbs {
        color: $light-gray;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-xs;
        margin-left: 30px;
        padding-top: 17px;

        li:first-child:hover {
          cursor: pointer;
        }
      }

      .icon {
        position: absolute;
        right: 15px;
        top: 16px;
        height: 14px;
      }

      &:after {
        content: "";
        background: #272829;
        position: absolute;
        margin-top: 16px;
        height: 1px;
        width: 100%;
      }
    }

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: $font-size-mdlg;
      margin-top: 37px;
      margin-left: 272px;
      color: $off-white;

      &--button {
        color: $white;
        background-color: $pine;
        border-radius: 5px;
        height: 30px;
        font-weight: 600;
        padding: 2px 10px 0;

        img {
          margin-right: 10px;
          margin-bottom: 1px;
        }
      }
    }

    &__main-panel {
      height: 100%;
      display: flex;
      flex-direction: column;

      .form {
        &--dark {
          overflow: auto;

          label {
            text-transform: none;
          }
        }
      }
    }

    &__alert {
      position: absolute;
      left: calc(50% - 240px);
      margin-top: -60px;
      width: auto;
    }

    &__general {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      margin-left: 230px;

      &--body {
        margin-left: auto;
        margin-right: auto;
        margin-top: 70px;
        max-width: 645px;
        width: 100%;

        .MuiAutocomplete {
          &-popupIndicatorOpen {
            transform: none;
          }
        }

        &.second-body {
          margin-top: 0;
        }

        .http-label {
          display: inline-flex;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-sm;
          color: $off-white;
          margin: 11px 15px 0 0;
        }
      }

      &--info {
        font-weight: 500;
        font-size: $font-size-sm;
        color: $off-white;
        margin-bottom: 15px;
      }

      &--header {
        color: $light-gray;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-md;
        margin-bottom: 15px;
      }

      &--sub-header {
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-sm;
        color: $off-white;
        margin-bottom: 15px;
        max-width: 460px;
      }

      &--max-length {
        position: relative;
        font-size: $font-size-ss;
        color: $off-white;
        width: 440px;
        text-align: right;
        margin-bottom: 32px;

        .success {
          color: $green;
        }
      }

      &--divider {
        height: 1px;
        background: $black;
        margin-bottom: 32px;
      }

      &--field {
        width: 240px;
        margin-right: 40px;
        margin-bottom: 32px;

        &.full-width {
          width: 385px;
          margin-right: 200px;
        }

        &.field-button {
          text-transform: none;
          width: unset;
          padding-bottom: 1px;

          &:not(.reset-button) {
            &.orange {
              background: $bronze;
            }

            background: $pine;
            color: $white;
            font-weight: 600;
            font-size: $font-size-sm;
            padding: 6px 25px;
          }
        }

        &.reset-button {
          color: $off-white;
          font-weight: 500;
          padding-top: 4px;

          &:hover {
            color: $light-gray;
          }
        }

        &.field-message {
          display: inline-flex;
          font-size: $font-size-md;
          font-weight: 500;
          color: $light-gray;
          vertical-align: middle;
          margin-right: 0;

          img {
            margin-right: 10px;
          }

          span {
            margin-top: 2px;
          }

          &.large {
            font-size: $font-size-xxl;
            display: block;
          }
        }

        &.confirmation-message {
          display: flex;
          flex-direction: column;
          width: 100% !important;

          & .title {
            font-size: 0.9rem;
            font-weight: 500;
            color: #e5e5e5;
          }

          & .header {
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: bold;
            font-size: 1.5rem;
            width: 100%;
          }

          & .description {
            font-size: 0.9rem;
            font-weight: 400;
            color: #e5e5e5;
          }
        }

        &.field-message,
        &.field-button {
          font-style: normal;
        }

        &.dropdown-field {
          .MuiAutocomplete {
            &-input {
              background: transparent;
            }
          }
        }

        &.is-mini {
          width: 60px !important;
          margin-right: 20px !important;

          &.full-width {
            width: 386px !important;
            margin-right: 0 !important;
          }
        }

        &.display-error {
          .MuiFormLabel {
            &-root {
              color: $red-error !important;
            }
          }
        }

        &.is-textarea {
          width: 440px;
          height: 90px;
          margin-bottom: 5px;

          div {
            width: 440px;
            height: 90px !important;
            padding: 0;

            textarea {
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-sm;
              color: $off-white;
              height: 90px;
            }
          }
        }

        .MuiInputBase {
          &-root {
            height: 40px !important;
          }

          &-input {
            background: transparent;
            padding-top: 13.5px !important;
            padding-bottom: 9.5px !important;
            line-height: 20px !important;
          }
        }

        .MuiFormLabel {
          &-root {
            top: -6px !important;
          }
        }

        .MuiInputLabel {
          &-shrink {
            transform: translate(12px, 3px) scale(0.85) !important;
          }
        }

        .MuiFormHelperText {
          &-root {
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-xs;
            color: $red-error;
          }
        }
      }

      &--checkbox {
        .MuiButtonBase {
          &-root {
            color: $white;
            padding-top: 6px;
          }
        }

        .MuiTypography {
          &-root {
            font-weight: normal;
            font-size: $font-size-md;
            color: $off-white;
          }
        }
      }

      &--reminder {
        color: $steel;
        font-weight: 500;
        font-size: $font-size-xxs;
        margin-bottom: 30px;
        margin-top: -10px;
      }

      &--button {
        position: absolute;
        right: 50px;
        top: 85px;
        background-color: $pine;
        color: $white;

        font-style: normal;
        font-weight: 600;
        font-size: $font-size-xs;
        height: 30px;
        width: auto;
        text-transform: none;
        box-shadow: unset;

        &:hover {
          background-color: $pine;
        }

        &.Mui-disabled {
          background-color: rgba(85, 85, 85, 0.5);
          color: $off-white;
        }
      }

      &--buttons {
        position: absolute;
        right: 50px;
        top: 85px;

        .back-button,
        .submit-button {
          font-style: normal;
          font-weight: 600;
          font-size: $font-size-xs;
          height: 30px;
          width: auto;
          text-transform: none;

          &.orange {
            background-color: $bronze !important;
          }
        }

        .submit-button {
          background-color: $pine;
          color: $white;

          &:hover {
            background-color: $pine;
            color: $light-gray;
          }

          &.Mui-disabled {
            background-color: rgba(85, 85, 85, 0.5);
            color: $off-white;
          }
        }

        .back-button {
          background-color: transparent;
          color: $off-white;
          margin-right: 25px;

          &:hover {
            color: $light-gray;

            img {
              filter: grayscale(100%) brightness(232%);
            }
          }

          img {
            margin-right: 5px;
          }
        }
      }

      &--qr {
        display: flex;
        margin-bottom: 30px;
        width: 690px;

        .qr-code {
          width: 128px;
          height: 128px;
          padding: 17px;
          background: $white;
          margin-right: 30px;
        }

        .line-code {
          width: 500px;
          height: 162px;
          border: 1px solid $steel;
          text-align: center;

          .desc {
            color: $light-gray;
            font-size: $font-size-xs;
            margin-top: 59px;
          }

          .code {
            color: $off-white;
            font-size: $font-size-md;
            word-wrap: break-word;
          }
        }
      }

      &--popup-block {
        width: 0;
        height: 0;
        display: inline-flex;
        position: relative;
      }
    }

    &__outer-exchanges {
      margin-left: 230px;
      overflow: auto;
    }

    &__exchanges {
      margin-left: auto;
      margin-right: auto;
      margin-top: 95px;
      width: 645px;

      .form--dark label {
        text-transform: none;
      }

      &--button {
        background-color: $bronze;

        font-style: normal;
        font-weight: 600;
        font-size: $font-size-xs;
        color: $white;
        height: 30px;
        width: 129px;
        margin-bottom: 15px;
        text-transform: none;

        &:hover {
          background-color: $bronze;
        }
      }

      &--table-title {
        font-weight: 600;
        font-size: $font-size-md;
        color: $off-white;
        margin-top: 40px;
        margin-bottom: 15px;
      }

      &--table {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        border-collapse: collapse;
        margin-bottom: 50px;

        .loading-row {
          filter: blur(3px);
        }

        tbody,
        thead {
          .amount {
            padding-right: 10px;
          }
        }

        tbody {
          tr {
            border-top: 1px solid $black;
          }

          td {
            padding-top: 17px;
            padding-bottom: 14px;
            padding-left: 20px;

            &:first-child {
              padding-top: 10px;
            }

            &:last-child {
              padding-left: 20px;
            }

            &.fee {
              border-right: 0 !important;
            }
          }
        }

        thead {
          th {
            color: $steel;
            padding-bottom: 5px;
            text-align: left;

            padding: 12px 16px;
          }
        }

        tbody {
          tr {
            border-top: 2px solid $black;

            &.clickable {
              &:hover {
                .action-menu-toggler {
                  visibility: visible !important;
                }
              }

              .name,
              .owner {
                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }

              .subscription-name {
                display: flex;
                overflow: visible;

                .syndication-name {
                  margin-right: auto;
                  color: $light-gray;
                }

                .action-menu {
                  position: relative;
                }

                .action-menu-toggler {
                  float: right;
                  width: 1px;
                  height: 1px;
                  border: 1px solid $off-white;
                  background: $off-white;
                  border-radius: 100px;
                  margin-left: 10px;
                  margin-top: 7px;
                  margin-right: 5px;
                  margin-bottom: 2px;
                  position: relative;
                  visibility: hidden;

                  &:after,
                  &:before {
                    width: 1px;
                    height: 1px;
                    border: 1px solid $off-white;
                    background: $off-white;
                    border-radius: 100px;
                    content: "";
                    position: absolute;
                    right: -1px;
                  }

                  &:after {
                    bottom: 5px;
                  }

                  &:before {
                    top: 5px;
                  }
                }

                .action-dropdown {
                  position: absolute;
                  top: 0;
                  z-index: 1;
                  background: $black;
                  display: flex;
                  flex-direction: column;
                  padding: 5px 0 5px 0;
                  border: 2px solid $off-black;
                  margin-top: 10px;
                  margin-left: 29px;
                  width: 158px;

                  button {
                    width: 100%;
                    text-align: left;
                    padding-top: 2px;
                    padding-bottom: 0;

                    &:hover {
                      background: $iron;

                      .action-option {
                        color: $white;
                      }
                    }
                  }

                  .action-option {
                    color: $off-white;
                    width: 100%;
                    margin-left: 15px;
                    margin-right: 15px;
                    white-space: nowrap;
                  }
                }
              }
            }
          }

          td {
            padding: 16px 16px;
            color: $off-white;
            overflow: hidden;
            text-overflow: ellipsis;

            &:last-child {
              padding-left: 20px;
            }

            &.fee {
              border-right: 0 !important;
            }

            &:first-child {
              padding: 10px 0 10px 0;

              &:not(.email) {
                width: 10px;
              }

              div:not(.reactTooltip) {
                width: 10px;
                height: 10px;
                border-radius: 10px;
                background-color: $error;

                &.ACTIVE {
                  background-color: #4cc48e;
                }

                &.PENDING {
                  background-color: $yellow;
                }
              }
            }

            &:not(:last-child):not(:first-child):not(.billing) {
              border-right: 2px solid $black;
            }

            &.name {
              .name-header {
                display: flex;
                white-space: nowrap;

                div:not(.reactTooltip):first-child {
                  width: 10px;
                  height: 10px;
                  margin-right: 8px;
                  margin-top: 3px;
                  border-radius: 10px;
                  background-color: $error;

                  &.ACTIVE {
                    background-color: #4cc48e;
                  }

                  &.PENDING {
                    background-color: $yellow;
                  }
                }

                .syndication-name {
                  font-weight: 600;
                  font-size: $font-size-md;
                  color: $light-gray;

                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                  }
                }
              }

              .name-item {
                margin-left: 18px;
                margin-top: 9px;
              }
            }

            &.menu {
              padding: 0 10px 0 0;
              width: 18px;

              &:hover {
                cursor: pointer;
              }
            }

            &.cost {
              text-align: right;
              display: flex;

              button {
                float: left;
                color: $error;

                font-style: normal;
                font-weight: normal;
                font-size: $font-size-xs;
                padding-left: 10px;
                margin: auto;
              }

              span {
                margin: auto;
              }
            }

            .actions {
              width: 75px;
              margin-left: 1px;

              button {
                display: block;
                margin-left: auto;
                margin-right: auto;
              }
            }

            &.action {
              white-space: nowrap;
              padding-right: 0;
              // width: 57px;
              padding: 0;
              padding-top: 8px;
              padding-bottom: 8px;
              padding-left: 8px;
              padding-right: 8px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              button {
                height: 30px;
                padding-top: 0px;
                padding-bottom: 0;
                padding-left: 15px;
                padding-right: 15px;
                background-color: $pine;
                border-radius: 5px;
                font-weight: 600;
                font-size: 12px;
                color: $white;

                border: none;
                cursor: pointer;

                &:hover {
                  opacity: 0.6;
                }
              }
            }

            &.amount {
              .amount-header {
                font-weight: 600;
                font-size: $font-size-md;
              }

              .amount-item {
                margin-top: 9px;
              }
            }

            &.source {
              white-space: nowrap;

              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &__subscriptions {
      display: flex;
      flex-direction: column;
      margin-left: 230px;
      margin-top: 60px;
      height: calc(100% - 177px);
      overflow-y: auto;

      &.invisible {
        height: 0;
        width: 0;
        margin: 0;
      }

      .panel {
        &__scrollable {
          margin: 0;
        }
      }

      &__title {
        display: flex;
        margin-bottom: 15px;

        &--left {
          margin-right: auto;

          font-style: normal;
          font-weight: 500;
          font-size: $font-size-md;
          color: $off-white;
        }

        &--right {
          color: $steel;

          font-style: normal;
          font-weight: 500;
          font-size: $font-size-sm;
        }
      }

      &__plan {
        display: flex;
        flex-direction: column;
        height: 100px;
        background: $black;
        margin: 0 0 25px 0;

        &--row {
          display: flex;
          margin: auto 30px;
          align-items: center;

          &:first-child {
            margin-top: 28px;
          }

          &:last-child {
            margin-bottom: 28px;
          }

          .tuned-logo {
            width: 90px;
            margin-bottom: 3px;
          }

          .type {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-mdlg;
            color: $light-gray;
            flex: 1 1;
          }

          .status {
            font-style: normal;
            font-weight: 600;
            font-size: $font-size-xs;
            color: #7db47b;
          }

          .billing-date {
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-xs;
            color: $steel;
            flex: 1 1;

            span {
              color: $off-white;
            }
          }

          .update-plan {
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-sm;
            color: $off-white;

            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }

            .credit-card-type {
              height: 20px;
              margin-bottom: -5px;
              margin-right: 5px;
            }
          }
        }
      }

      &__tuned,
      &__licenses {
        margin: 0 auto;
        width: 645px;
      }

      &__licenses {
        // margin: auto;

        table {
          width: 100%;
        }
      }

      &--table {
        margin: auto;
        border-collapse: collapse;

        th,
        td {
          text-align: left;

          &:not(:first-child) {
            border-left: 2px solid $black;
            width: 150px;

            & > * {
              margin-left: 15px;
            }
          }

          &:first-child {
            width: 175px;
          }
        }

        th {
          vertical-align: baseline;
          position: relative;

          &:first-child {
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-md;
            color: $steel;
            vertical-align: bottom;
            margin-bottom: 5px;
          }

          &:not(:first-child) {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-mdlg;
            color: $light-gray;
          }

          &.community {
            .price {
              text-decoration: line-through;

              span {
                text-decoration: line-through;
              }
            }

            .most-popular {
              visibility: visible;
            }
          }

          .change-plan,
          .current-plan,
          .coming-soon {
            margin-top: 5px;
            height: 30px;
            margin-bottom: 40px;
          }

          .change-plan,
          .coming-soon {
            width: 100px;
            text-transform: none;

            font-style: normal;
            font-weight: 500;
            font-size: $font-size-xs;
            color: $light-gray;
            padding-top: 8px;
          }

          .most-popular {
            margin-left: 0;
            width: 100%;
            min-height: 20px;
            text-align: center;

            font-style: normal;
            font-weight: 500;
            font-size: $font-size-wxs;
            line-height: 22px;
            background: $pine;
            visibility: hidden;
          }

          .current-plan {
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-xs;
            padding-top: 6px;

            img {
              margin-right: 5px;
              width: 12px;
            }
          }

          .change-plan {
            background: $bronze;
          }

          .coming-soon {
            background: $steel;
            opacity: 0.7;
          }

          .price {
            color: $off-white;

            span {
              font-size: $font-size-xxs;
              margin-left: 5px;
              margin-top: 1px;
              position: absolute;
            }
          }
        }

        td {
          padding-top: 5px;
          padding-bottom: 5px;

          &:first-child {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-md;
            color: $off-white;
          }

          &:not(:first-child) {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-sm;
            color: $light-gray;
          }

          .alloc-fee {
            text-decoration: line-through;
          }
        }

        tr {
          height: 40px;

          &:not(:last-child) {
            border-bottom: 2px solid $black;
          }
        }
      }
    }

    &__plans {
      min-height: calc(100vh - 120px);
      overflow-y: auto;

      &-loader {
        margin-top: 37px;
        margin-right: 40px;
        margin-left: 272px;
        min-height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-switch {
        margin-top: 37px;
        margin-left: 272px;

        &-item {
          background: transparent;
          border: 0;
          padding: 0;
          font-size: 12px;
          border: 1px solid #a7a7a7;
          color: #fff;
          text-align: center;
          min-width: 170px;
          padding: 7px 20px;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }

          &.active {
            background: #37515d;
            border-color: #37515d;

            &:hover {
              cursor: default;
              opacity: 1;
            }
          }

          & .extra {
            font-weight: 700;
            color: #7db47b;
            display: inline-block;
            margin-left: 4px;
          }
        }
      }

      &-table {
        margin-left: 272px;
        margin-right: 50px;
        margin-top: 60px;
        margin-bottom: 40px;

        table {
          border-collapse: collapse;
          width: 100%;
          table-layout: fixed;
        }

        td,
        th {
          padding: 15px;
          border: 1px solid #000;
        }

        tr {
          text-align: center;
        }

        th {
          text-align: left;
          border-left-color: transparent;
        }

        td:last-child {
          border-right-color: transparent;
        }

        & .header-row {
          th {
            border-top-color: transparent;
            vertical-align: bottom;
            color: #656262;
          }

          td {
            border-top-color: transparent;
          }

          &-title {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
          }
        }

        & .description-row {
          color: #656262;
          font-size: 11px;
          line-height: 12px;
          font-weight: 600;
          padding-bottom: 8px;
          text-align: left;
          text-transform: uppercase;
          border: 1px solid #000;
          border-left-color: transparent;
          border-right-color: transparent;

          & th {
            padding-top: 14px;
            padding-bottom: 8px;
            border: none;
          }
        }

        & .header-cell {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 4px;
          padding-top: 30px;
          position: relative;

          & .plan {
            &__badge {
              background: #b6782b;
              font-size: 11px;
              font-weight: 600;
              text-align: center;
              padding: 4px;
              width: 100%;
              box-sizing: border-box;
              position: absolute;
              top: 0;
              left: 0;
            }

            &__tariff {
              margin-bottom: 4px;
              font-size: 14px;
              color: #e5e5e5;
            }

            &__price {
              font-size: 22px;
              color: #a7a7a7;
              line-height: 28px;
              font-weight: 600;

              span:nth-child(2) {
                font-size: 10px;
                color: #656262;
                vertical-align: top;
                padding-left: 4px;
                font-weight: 400;
                display: inline-block;

                margin-top: -8px;
              }
            }

            &__cta {
              display: flex;
              flex-direction: column;
              gap: 8px;

              &--current-plan {
                height: 30px;
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 10px;
                font-weight: 600;
                margin-top: 8px;
                justify-content: center;
              }

              &--button {
                height: 30px;
                padding-top: 3px;
                padding-bottom: 3px;
                padding-left: 20px;
                padding-right: 20px;
                background-color: $pine;
                border-radius: 5px;
                font-weight: 600;
                font-size: 12px;
                color: $white;
                border: 1px solid $pine;
                display: inline-block;

                margin-top: 8px;
                cursor: pointer;

                &:hover {
                  opacity: 0.6;
                }

                &.downgrade {
                  background: transparent;
                }

                &.link {
                  min-height: 12px;
                  padding-top: 7px;
                  padding-bottom: 9px;
                  height: fit-content;
                }

                &:disabled {
                  opacity: 0.5;
                  pointer-events: none;
                  cursor: default;
                }
              }
            }
          }
        }

        & .data-row-header {
          color: #a7a7a7;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & .info-tooltip {
            display: block;
            width: 13px;
            height: 13px;

            img {
              width: 100%;
              height: auto;
              opacity: 0.4;
            }
          }

          & .reactTooltip {
            font-weight: 400;
            max-width: 200px;
          }
        }

        & .data-cell {
          color: #a7a7a7;
          font-weight: 600;
          font-size: 16px;

          &-checkmark {
            color: #a7a7a7;
            opacity: 0.6;
            width: 18px;
            height: 15px;
          }

          &-empty {
            width: 14px;
            height: 3px;
            background: #6c6e74;
            margin: auto;
            border-radius: 2px;
          }
        }

        &--mobile {
          display: grid;
          grid-template-columns: 1;
          gap: 20px;
          // margin-left: 16px;
          // margin-left: 16px;

          margin-right: 40px;
          margin-left: 272px;

          .container {
            padding: 20px;
            background: #2e2e2e;
            border-radius: 8px;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
          }

          .header-cell {
            padding-top: 10px;
            padding-bottom: 20px;
          }

          .plan__tariff {
            font-size: 1.25rem !important;
            font-weight: 600;
            margin-bottom: 12px !important;
          }

          .plan__cta {
            width: 100%;
          }

          .plan__badge {
            margin-top: -20px;
            margin-right: -20px;
            margin-left: -20px;
            width: calc(100% + 40px) !important;
            display: block;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }

          .content {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
          }

          .info-tooltip {
            margin-left: 4px;
          }

          .description-row {
            padding-top: 8px;
            padding-bottom: 8px;
            margin-top: 4px;
            margin-bottom: 4px;
          }

          .data-row-header {
            padding-top: 4px;
            padding-bottom: 4px;
          }

          .data-cell-empty {
            margin: 0;
          }
        }
      }

      &-faq {
        margin-left: 272px;
        margin-right: 50px;
        margin-top: 60px;
        margin-bottom: 40px;
        max-width: 560px;

        &-title {
          color: #a7a7a7;
          font-size: 15px;
          margin-bottom: 16px;
        }

        &-item {
          border-radius: 5px;
          padding: 16px 12px;
          background: #242424;
          margin-bottom: 8px;

          & .title {
            font-size: 14px;
            font-weight: 600;
            color: #e5e5e5;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .is-open {
              transform: rotate(180deg);
            }
          }

          & .content {
            color: #a7a7a7;
            padding-top: 12px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
          }
        }
      }
    }

    &__confirm-plan {
      display: flex;
      flex-direction: row;
      margin: 65px 25px 25px 400px;

      &--title {
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-mdlg;
        color: $off-white;
        margin-bottom: 10px;
      }

      &__billing {
        width: 66%;
        margin-right: 50px;

        &--payment {
          margin-top: 30px;

          button {
            margin-top: 0;
          }
        }

        &--option {
          height: 40px;
          background: $black;
          padding: 0 20px;
          margin-bottom: 10px;
          display: flex;

          &:hover {
            cursor: pointer;
          }

          .confirm-plan-radio {
            padding-left: 0;

            svg {
              width: 18px;
              height: 18px;
            }

            &.Mui {
              &-checked {
                svg {
                  fill: $bronze !important;
                  opacity: 1;
                }
              }
            }
          }

          span {
            align-self: center;

            font-style: normal;
            font-weight: 500;
            font-size: $font-size-sm;
            color: $light-gray;

            &:not(.billing-save):not(.confirm-plan-radio) {
              flex: 1 1;
            }

            &.billing-save {
              color: $light-green;
              font-weight: 600;
            }
          }
        }
      }

      &__details {
        width: 250px;

        &--list {
          background: $black;
          padding: 15px 15px 80px 15px;

          ul {
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-xs;
            color: $off-white;
            padding: 0 15px;
            margin: 0;

            li {
              padding-bottom: 15px;
            }
          }
        }
      }

      &--card {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-xs;
        color: $off-white;
        margin-right: 4px;
      }

      &--button {
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-s;
        color: $steel;
        padding-left: 0;
        padding-right: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__brand {
      display: inline-flex;
      width: 115px;
      margin-top: auto;
      margin-bottom: 50px;
      margin-left: 40px;

      &--header {
        font-weight: 300;
        letter-spacing: 0.375rem;
        line-height: 0px;
        width: 64px;
        margin: 0;
      }

      &--version {
        color: $dark-blue;
        font-weight: 500;
        font-size: 0.45rem;
        margin-left: 5px;
        background-color: $peacock;
        border-radius: 10px;
        height: 12px;
        text-align: center;
        vertical-align: middle;
        padding: 2px 4px 2px 4px;
        letter-spacing: 1px;
      }
    }

    &__side-panel {
      display: flex;
      flex-direction: column;
      width: 230px;
      height: 100%;
      position: absolute;
      box-shadow: 10px 1px 10px rgba(0, 0, 0, 0.3);

      &--profile-button {
        position: absolute;
        right: 45px;
        top: 47px;
        color: $off-white;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }

      &__header {
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-lg;
        margin-left: 30px;
        padding-top: 115px;
        color: $light-gray;
      }

      &__body {
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-xs;
        line-height: 19px;
        margin: 55px 0 55px 35px;
        color: $off-white;
        height: 350px;
        overflow: auto;

        &__item {
          margin-top: 30px;

          &.current-path {
            color: $bronze;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      &--photo {
        position: absolute;
        top: 25px;
        left: 30px;
        height: 64px;
        width: 64px;

        &.down-12 {
          top: 37px;
        }

        label {
          &:hover {
            cursor: pointer;

            .camera {
              opacity: 1;
            }
          }
        }

        .photo {
          height: 64px;
          width: 64px;
          border-radius: 64px;
        }

        div {
          line-height: 64px;
          height: 62px;
          width: 64px;
          padding-top: 2px;
          text-align: center;
          vertical-align: middle;
          border-radius: 64px;
          font-weight: bold;
          font-size: $font-size-xxl;
          text-transform: uppercase;
        }

        .camera {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 19px;
          opacity: 0.5;
        }

        input {
          display: none;
        }

        .loading {
          display: flex;
          background-color: $black;

          .spinner {
            padding: 0;
            margin: auto;
            height: 45%;
            opacity: 0.7;
          }
        }
      }
    }

    &__referrals {
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      width: 645px;
      max-width: 100%;

      &--header {
        color: $light-gray;

        font-style: normal;
        font-weight: 600;
        font-size: $font-size-md;
      }

      &--exchanges {
        display: flex;
        flex-wrap: wrap;

        a {
          margin-right: auto;
          margin-top: 10px;
          margin-bottom: 20px;
          padding-right: 10px;
        }

        .bybit-logo {
          height: 30px;
        }

        .binance-logo {
          height: 18px;
          padding-top: 5px;
        }

        .coinbase-logo {
          height: 20px;
        }

        .ftx-logo {
          height: 23px;
        }
      }

      &--info {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $off-white;
        display: flex;
        margin-bottom: 5px;
        max-width: 525px;

        img {
          height: 14px;
          margin: 2px 5px 0 0;
        }
      }
    }

    &__billing-info {
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 645px;
      width: 100%;

      color: #e5e5e5;

      &__container {
        background: #000000;
        padding: 24px 32px;
        margin-left: 16px;
        margin-right: 16px;
      }

      &__notification {
        background: #932027;
        padding: 12px 24px;
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: space-between;
        margin-left: 16px;
        margin-right: 16px;

        &.warning {
          background: $status-pending-color;
        }

        &-content {
          font-weight: 700;
          font-size: 12px;
          color: #fff;
        }

        &-button {
          background: #ffffff;
          border: none;
          cursor: pointer;
          padding: 7px 12px;
          color: #000000;
          font-weight: 600;
          border-radius: 4px;

          &:hover {
            opacity: 0.6;
          }
        }
      }

      &__plan {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &-header {
          color: #656262;
          font-size: 12px;
          line-height: 14px;
        }

        &-name {
          font-size: 18px;
          line-height: 21px;
        }

        &-button {
          color: #fff;
          text-decoration: underline;
          padding: 0;
          border: none;
          background: transparent;
          cursor: pointer;
          display: block;
          width: fit-content;

          &:hover {
            opacity: 0.6;
          }
        }

        &-status {
          font-size: 12px;
          line-height: 14px;
          font-weight: 600;
        }

        &-downgrade {
          margin-top: 8px;
          color: #a7a7a7;
          font-size: 12px;
        }

        &--left {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        &--right {
          margin-left: auto;
        }
      }

      &__dates {
        display: grid;
        grid-template-columns: 23% 23% 23% 31%;

        &-block {
          padding: 16px 12px;
          border: 1px solid #1c1c1e;
          display: flex;
          flex-direction: column;
          gap: 4px;
          justify-content: center;

          & span:nth-child(1) {
            font-size: 12px;
            color: #656262;
          }

          & span:nth-child(2) {
            font-size: 14px;
            color: #e5e5e5;
          }

          &.manage-button {
            padding-left: 4px;
            padding-right: 4px;
          }
        }

        &-button {
          background: #264e5a;
          padding: 10px 24px 10px 24px;
          font-size: 12px;
          line-height: 14px;
          color: #fff;
          border: none;
          border-radius: 4px;
          margin: auto;
          cursor: pointer;
          position: relative;

          &:hover {
            opacity: 0.6;
          }

          &.disabled {
            background: #264e5ab5;
            color: #ffffffb0;
            pointer-events: none;
          }

          & .spinner {
            padding: 0;
            position: absolute;
            top: calc(50% - 10px);
            left: calc(50% - 10px);
            height: 20px;
          }
        }
      }
    }

    &__invoice {
      &-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 6px;

        &:last-child {
          text-align: right;
        }

        & span:nth-child(1),
        & span:nth-child(3) {
          font-size: 13px;
          line-height: 15px;
          color: #a7a7a7;
        }

        & span:nth-child(2) {
          font-size: 16px;
          font-weight: 600;
          color: #e5e5e5;
        }
      }

      &-payments {
        margin-top: 0px;

        &-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 14px 0px;
          border-bottom: 1px solid #000;
          gap: 4px;

          &:last-child {
            border-bottom: none;
          }
        }

        &-title {
          font-size: 16px;
          color: #e5e5e5;

          &.total {
            margin-left: auto;
          }
        }

        &-value {
          font-size: 16px;
          color: #e5e5e5;
          font-weight: 700;
        }
      }
    }

    &__plans-modal {
      &--upgrade {
        width: 700px;
      }

      &--result {
        min-width: 500px;
        max-width: 500px;

        & .button--submit {
          width: 100% !important;
        }

        & .button--primary {
          width: 100% !important;
        }
      }

      &-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        & .button {
          background: $pine;
          width: fit-content;
          margin: 0 !important;
          font-weight: 600;
          font-size: 14px;

          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }

          &--submit {
            background: $bronze;

            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }
          }

          &--outlined {
            background: transparent;
            border: 2px solid $pine !important;
          }
        }
      }

      &-details {
        margin-bottom: 24px;
      }
      &-fees {
        & .row {
          display: flex;
          justify-content: space-between;
          padding: 12px 0px;
          gap: 16px;

          &--second {
            border-bottom: none;
            padding: 0;
            margin-top: -4px;
          }

          &--total {
            margin-top: 12px;
            justify-content: flex-end;
            border-top: 1px solid #000;
          }

          &:last-child {
            padding-bottom: 12px;
          }
        }

        & .description {
          display: flex;
          flex-direction: column;

          &-title {
            font-size: 14px;
            color: #e5e5e5;
            margin-bottom: 8px;
          }

          &-period {
            color: #a7a7a7;
            font-size: 13px;
            margin-bottom: 4px;
          }
        }

        & .price {
          font-size: 14px;
          font-weight: 600;
          color: #e5e5e5;

          &-col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          & .secondary {
            text-align: right;
            font-weight: normal;
            font-size: 13px;
            color: #a7a7a7;
          }
        }

        & .total-text {
          font-weight: 400 !important;
          padding-right: 4px;
          font-size: 14px;
        }
      }

      &-policy {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;
        margin-bottom: 20px;

        font-size: 13px;
        font-weight: 400;
      }

      &-diff {
        margin-top: 0px;
        margin-bottom: 24px;

        &-description {
          font-size: 13px;
          color: #e9e9e9;
          line-height: 22px;
          margin-top: 24px;
          margin-bottom: 16px;
          display: inline-block;
          font-weight: 400;
          letter-spacing: 0.25px;

          &-extra {
            display: inline-block;
            font-size: 13px;
            color: #e9e9e9;
            letter-spacing: 0.25px;
          }
        }

        &-container {
          & .block {
            background: #000000;
            padding: 16px 18px 16px 18px;
            margin-bottom: 10px;
          }

          & .title {
            color: #e9e9e9;
            font-size: 13px;
            line-height: 22px;
            margin-bottom: 12px;
            display: block;

            &--bold {
              font-weight: 700;
            }
          }

          & .item {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 4px;
            font-size: 13px;
            color: #a7a7a7;

            &:last-child {
              margin-bottom: 0;
            }

            &--positive {
              svg {
                color: $light-green;
              }
            }

            &--negative {
              svg {
                color: $red-error;
              }
            }
          }
        }
      }
    }

    &__billing-info-modal {
      & .container {
        height: calc(100dvh - 120px);
        display: flex;
        flex-direction: column;

        width: calc(100dvw - 120px);
        max-width: 1000px;
      }

      & .content {
        height: -webkit-fill-available;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      & .iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
      }

      & .settings__plans-modal-buttons {
        margin-top: auto;
      }
    }

    &__invoices {
      &-list {
        margin: 0 auto;
        padding-bottom: 40px;

        &-more-button {
          border: none;
          background: none;
          margin: 0 auto;
          padding: 0;
          font-size: 12px;
          cursor: pointer;
          color: #a7a7a7;
          display: block;

          &:hover {
            opacity: 0.6;
            text-decoration: underline;
          }
        }
      }

      &-table {
        margin-bottom: 20px;

        & .invoice-row {
          &:hover {
            opacity: 0.6;
            cursor: pointer;
          }
        }
      }

      &-modal {
        &-content {
          margin-top: 40px;
          min-width: fit-content;
        }
      }
    }
  }

  .MuiButton-label {
    line-height: 15px;
  }
}

@media (max-width: 1199.98px) {
  .settings {
    &__general {
      overflow: auto;

      &--body {
        padding: 0 20px 0 42px;
      }

      &--qr {
        display: block !important;
        width: 100% !important;
        margin-left: 0;

        .qr-code,
        .line-code {
          margin-left: auto;
          margin-right: auto !important;
        }

        .qr-code {
          margin-bottom: 30px;
        }
      }
    }

    &__outer-exchanges {
      overflow: scroll !important;
    }

    &__alert {
      left: calc(50% - 125px) !important;
    }
  }
}

@media (max-width: 997.98px) {
  .settings {
    &__exchanges {
      margin-left: 25px !important;
      padding-left: 0 !important;
    }

    &__subscriptions {
      width: auto;

      &__tuned,
      &__licenses {
        width: auto !important;
      }

      &__licenses {
        padding: 0 20px 0 42px;
      }

      &--table {
        padding: 0 20px;
        width: calc(100% - 40px);
      }
    }

    &__confirm-plan {
      margin: 65px 20px 25px 250px !important;

      &__billing {
        margin-right: 15px !important;
      }

      &__details {
        width: 30% !important;
      }
    }
  }

  .settings-page {
    .settings {
      &__billing-info {
        &__dates {
          grid-template-columns: 1fr 1fr;
        }
      }

      &__invoices {
        &-table {
          width: 100%;
        }

        &-list {
          margin-left: 16px;
          margin-right: 16px;
        }
      }

      &__general {
        max-height: 100%;
        margin-left: 0;

        &--body {
          padding-left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: auto;
          max-width: 300px;
          padding-right: 20px;
          padding-left: 20px;

          .MuiAutocomplete-root {
            display: block;
            width: 100%;
          }

          .full-width {
            width: 100%;
          }
        }

        &--header {
          margin-bottom: 30px;
        }

        &--field {
          width: 100%;
          margin-right: 0;

          &.dropdown-field {
            display: block;
          }

          .MuiAutocomplete-inputRoot {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .settings-page {
    .settings {
      &__toggler {
        display: unset;
        position: absolute;
        z-index: 99;
        margin-left: 195px;
        top: 15px;

        &.collapsed {
          margin-left: 10px;
          margin-top: 9px;
          width: 30px;
        }
      }

      &__header {
        margin-left: 0;
        width: 100%;

        &--breadcrumbs {
          margin-left: 50px;
          padding-top: 16px;
        }
      }

      &__title {
        &:not(.always-visible):not(.invoice-id) {
          display: none;
        }

        &.always-visible {
          margin-right: auto;
          margin-left: 25px;
          margin-top: 25px;

          span {
            display: none;
          }
        }
      }

      &__side-panel {
        position: absolute;
        z-index: 9;
        background: $black;

        &:not(.show) {
          display: none;
        }
      }

      &__invoices {
        &-list {
          margin-left: 0px;
          margin-right: 0px;
        }

        &-table {
          width: 100%;

          thead {
            display: none;
          }

          th {
            display: none;
          }

          tr {
            border: none;
            border-spacing: 4px;
            border-bottom: 8px solid #1c1c1e;
          }

          td {
            display: block;
            text-align: left;
            padding-left: 120px !important;
            position: relative;
            border: 1px solid #656262 !important;

            &.action {
              padding-left: 12px !important;
            }
          }

          td::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            top: calc(50% - 7px);
            width: 200px;
            padding-left: 20px;
            font-weight: bold;
          }
        }
      }

      &__general {
        max-height: 100%;
        margin-left: 0;

        &--body {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: auto;

          .MuiAutocomplete-root {
            display: block;
            width: auto;
          }
        }

        &--info {
          margin-left: 25px;
          margin-right: 25px;
          text-align: center;
        }

        &--field {
          margin-right: 0 !important;
          width: 300px;

          &.field-message {
            text-align: center;
          }

          &.is-textarea {
            max-width: 90% !important;

            div {
              max-width: 100% !important;
            }
          }

          .MuiAutocomplete-inputRoot {
            width: 100%;
          }

          &.full-width {
            max-width: 90% !important;
          }
        }

        &--button,
        &--buttons {
          top: 70px;
        }

        &--buttons {
          right: 0 !important;
          margin: 0 25px;
          width: calc(100% - 50px);
          display: flex;
          justify-content: space-between;

          .back-button {
            padding-left: 0;
            justify-content: flex-start;
          }
        }

        &--qr {
          .line-code {
            width: 95% !important;

            .desc {
              margin-top: 55px !important;
            }

            div {
              padding: 5px;
            }
          }
        }

        &--sub-header,
        &--max-length {
          max-width: 90% !important;
        }
      }

      &__outer-exchanges {
        margin-left: 0 !important;
      }

      &__exchanges {
        padding-left: 0 !important;
      }

      &__subscriptions {
        position: relative;
        overflow: scroll;
        // height: calc(100% - 111px);
        height: calc(100% - 71px);
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;

        &__plan {
          &--row {
            &:last-child {
              align-items: baseline !important;
              display: block;
            }

            .billing-date {
              font-size: $font-size-wxs !important;
            }

            .update-plan {
              font-size: $font-size-xs !important;
            }
          }
        }

        &__licenses {
          padding: 0 40px 0 20px;
        }
      }

      &__confirm-plan {
        margin: 65px 25px 25px 25px !important;
        flex-direction: column !important;
        overflow: scroll;
        height: calc(100% - 150px);

        &__billing {
          width: 100% !important;
          margin-right: 0 !important;

          &--option {
            span {
              font-size: $font-size-xs !important;
            }
          }
        }

        &__details {
          width: 100% !important;
          margin-top: 20px;

          &--list {
            padding-bottom: 15px !important;
          }
        }

        &--card {
          margin-right: 5px;
        }

        &--button {
          padding: 1px;
        }
      }

      &__alert {
        z-index: 1;
        margin-left: 25px;
        margin-right: 25px;
        left: 0 !important;
        margin-top: -60px;
      }

      &__referrals {
        height: 100%;
        padding: 0 20px;

        &--outer {
          margin-left: 0;
          max-height: calc(100% - 303px);
          display: flex;
          overflow: auto;
        }
      }

      &__plans {
        &-loader {
          margin-left: 16px;
          margin-right: 16px;
        }

        &-switch {
          margin-left: 16px;
          margin-right: 16px;
        }

        &-faq {
          margin-left: 16px;
          margin-right: 16px;
        }

        &-table {
          margin-left: 16px;
          margin-right: 16px;
        }
      }

      &__billing-info {
        &__dates {
          grid-template-columns: 1fr;
        }

        &__container {
          padding-left: 12px;
          padding-right: 12px;
        }
      }

      &__plans-modal {
        .header {
          font-size: 1.5rem !important;
        }

        &--upgrade {
          width: 100%;
        }

        &--result {
          min-width: auto;
        }

        &-buttons {
          flex-wrap: wrap;
          justify-content: center;

          .button {
            min-width: 260px;
          }
        }
      }

      &__billing-info {
        &__notification {
          margin-left: 0;
          margin-right: 0;
        }

        &__container {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &__invoice {
        &-row {
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &-info {
          margin-bottom: 20px;

          &:last-child {
            text-align: left;
          }
        }

        &--status {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          // max-width: 100%;
          max-width: 220px;
        }
      }
    }
  }
}
