@import "../../../styles/colors";
@import "../../../styles/typography";

$nav--secondary-spacing: 0.275rem;

.top-dropdowns {
  display: flex;
  background: $nav--secondary-background-color;
  border-bottom: 2px solid $black;

  .execution-navigation {
    display: flex;

    &.realized-profits {
      border-left: 2px solid $black;
    }

    &.hidden-name {
      .clear-button {
        padding-left: 10px;
      }
    }
  }

  .candle-picker-dropdown {
    &.disabled {
      pointer-events: none;
    }

    .candle-size {
      &:hover {
        cursor: pointer;
        color: $light-gray;
      }

      height: 30px;
      align-items: center;
      background: $off-black;
      border: none;
      display: flex;
      flex-wrap: wrap;
      width: 100px;
      text-align: center;
      color: $off-white;
      justify-content: space-evenly;
      font-size: $font-size-sm;
      font-weight: 500;
      margin-right: 2px;
      border-right: 2px solid $black;
      line-height: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    .candle-picker {
      display: inline-flex;
      margin-top: 2px;

      &--container {
        position: absolute;
        z-index: 1;

        @media (max-width: 767.98px) {
          left: 146px;
        }
      }

      &__input {
        background: $off-black;
        color: $off-white;
        width: 26px;
        margin-right: 2px;
        padding: 0 10px 0 10px;
        border: 0;
        -moz-appearance: textfield;

        &::-webkit-clear-button,
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          display: none;
        }
      }

      &__recent {
        background: $off-black;
        width: 148px;
        margin-top: 2px;
        padding-bottom: 5px;

        &--title {
          color: $steel;
          font-weight: 600;
          font-size: $font-size-ss;
          padding: 3px 18px 0;
        }

        &--option {
          height: 18px;
          font-size: $font-size-ss;
          color: $off-white;
          padding-left: 18px;

          &:hover {
            background: $steel;
            color: $light-gray;
            cursor: pointer;
          }
        }
      }

      .top-dropdown {
        border-right: 0;

        &__menu {
          width: 100px;
        }

        &__indicators {
          display: unset !important;
          height: 30px;
          width: 24px;
        }

        &__dropdown-indicator {
          padding: 5px;
        }

        &__control {
          &:hover {
            cursor: pointer;
          }

          &--menu-is-open {
            .top-dropdown {
              &__indicators {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    .checkmark {
      width: 30px;
      padding-top: 4px;
      background-color: $pine;

      &:not(:hover) {
        img {
          filter: grayscale(100%) brightness(55%);
        }
      }

      &.error {
        background-color: #9a5b66;
        &:active {
          animation: shake 3s;
        }
      }
    }
  }

  .top-dropdown {
    display: none;
    align-items: center;
    background: $nav--secondary-background-color;
    border: none;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    color: $off-white;
    justify-content: space-evenly;
    font-size: $font-size-sm;
    font-weight: 600;
    border-right: 2px solid $black;

    &.MuiAutocomplete-root {
      &:hover {
        cursor: pointer;

        .MuiInputBase-input {
          color: $light-gray !important;
        }
      }
    }

    &__control {
      width: 100%;
      background: $nav--secondary-background-color;
      border: none;
      border-radius: 0;
      min-height: 30px;

      &:hover {
        cursor: pointer;

        .top-dropdown__single-value {
          color: $light-gray;
        }
      }

      &--is-focused {
        box-shadow: none;
      }

      &--menu-is-open {
      }
    }

    &__value-container {
      background: none;
      text-align: center;
      padding-left: 12px;
      padding-right: 12px;
      flex-wrap: nowrap;

      @media (max-width: 332px) {
        padding-left: 6px;
        padding-right: 6px;
      }

      div {
        top: unset;
        left: unset;
        position: relative;
        transform: none;
        width: unset;
        max-width: unset;
        display: inline-block;
        line-height: 20px;
      }
    }

    &__input {
      background: none;
      text-align: center;
      color: $off-white !important;
    }

    &__menu {
      background: $nav--secondary-background-color;
      min-width: 5vw;
      white-space: nowrap;
      text-align: left;
      left: 0px;
      margin-top: 2px;
      border-radius: 0;
      padding-top: 5px;
      width: unset;
      max-width: 148px;
    }

    &__indicators {
      background: none;
      display: none !important;
    }

    &__placeholder {
      background: none;
    }

    &__option {
      background: none;
      padding-top: 1px;
      padding-bottom: 0;
      height: 20px;
      font-size: $font-size-xs;
      font-weight: 500;

      &:hover {
        color: $white;
        background: $steel;
      }
    }

    &__single-value {
      background: none;
      color: $off-white;
      font-weight: 500;
      text-align: center;
      width: calc(100% - 8px);
      top: calc(50% + 1px);
      margin: 0;
      left: 4px;
    }

    &.MuiAutocomplete {
      &-root {
        width: 110px;

        &[aria-expanded="false"] {
          .MuiInputBase {
            &-input {
              text-align: center;
            }
          }
        }

        .MuiInputBase {
          &-root {
            padding-right: 8px;
            background-color: unset;

            &:before,
            &:after {
              display: none;
            }
          }

          &-input {
            color: $off-white;
            justify-content: space-evenly;
            font-size: $font-size-sm;
            font-weight: 500 !important;
            line-height: 20px;
          }
        }

        .MuiAutocomplete {
          &-endAdornment {
            display: none;
          }
        }
      }
    }
  }

  .clear-button {
    padding-right: 10px;
    margin-left: 0px;

    &:hover {
      color: $light-gray;
      font-weight: 600;
      background-color: $pine;
    }

    img {
      height: 8px;
      width: 8px;
    }
  }

  .execution-name {
    white-space: nowrap;
    width: unset;
    height: 30px;
    border-right: 0;
    margin-left: 10px;
    margin-right: 5px;
    color: $light-yellow;
    line-height: 20px;

    @media (max-width: 767.98px) {
      display: none !important;
    }
  }

  &--minimize {
    margin-left: auto;
    list-style: none;
    padding: 0 0.5px 0 0.5px;
    border-left: 2px solid $black;
    height: 30px;
    background: $off-black;

    @media (max-width: 767.98px) {
      display: none;
    }

    .full-collapse {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 6px;
      height: 30px;
    }
  }
}

.top-dropdown-list {
  width: 150px !important;
  margin-left: 38px;

  .MuiAutocomplete {
    &-paper {
      background-color: $off-black !important;
      color: $off-white !important;
      font-weight: 500 !important;
      font-size: $font-size-xs !important;
      line-height: 20px !important;
      margin-top: 2px;
      border-radius: 0;

      ul {
        li {
          padding: 2px 12px 0px 12px !important;

          &[data-focus="true"] {
            background-color: $steel !important;
            color: $white !important;
          }

          &[aria-selected="true"] {
            color: $white !important;
          }
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(3px, 1px);
  }
  10% {
    transform: translate(-6px, 1px);
  }
  20% {
    transform: translate(6px, -1px);
  }
  30% {
    transform: translate(-6px, 1px);
  }
  40% {
    transform: translate(6px, -1px);
  }
  50% {
    transform: translate(-6px, 1px);
  }
  60% {
    transform: translate(6px, 1px);
  }
  70% {
    transform: translate(-6px, 1px);
  }
  80% {
    transform: translate(6px, -1px);
  }
  90% {
    transform: translate(-6px, 1px);
  }
  100% {
    transform: translate(3px, -1px);
  }
}
