.hero {
  position: static;

  &__graphic {
    &__container {
      transform: translate3d(-75%, -50%, 0);
      transform-origin: center;
      will-change: transform;
      position: absolute;
      top: 45%;
      left: 30%;
      width: 827.75px;
      height: 827.91px;
      pointer-events: none;

      @media (max-width: 767px) {
        opacity: 0.2;
        left: 413.875px;
      }

      svg {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__load-animation {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: center;
      animation: loadOpacity 2s cubic-bezier(0, 0.26, 0.47, 0.98),
        loadScale 10s cubic-bezier(0, 0.26, 0.47, 0.98);
      animation-fill-mode: forwards;
      will-change: transform, opacity;
    }

    &__rotate-animation {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: rotateInfinite 400s infinite;
      animation-fill-mode: forwards;
      animation-delay: 1s;
      transform-origin: center;
      will-change: transform;
    }
  }
}

@keyframes modalLoad {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  33% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  66% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadOpacity {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes loadScale {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotateInfinite {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
