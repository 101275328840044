@import "../../styles/colors";
@import "../../styles/typography";

.chart-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: $transparent-black;

  &__inner {
    position: absolute;
    width: 350px;
    bottom: 20px;
    left: 20px;
    padding: 0;
    display: flex;

    div {
      margin: 2px 0 0 0;
      font-size: $font-size-sm;
      color: $white-ish;
      font-weight: 500;
      align-self: center;
    }

    & .spinner {
      padding: 0;
      width: unset;
      height: 26px;
      margin-right: 10px;
      margin-left: 0;
    }
  }

  &__warning {
    color: $red-error !important;
  }
}
