@import "../../styles/colors";
@import "../../styles/typography";

.power-bar {
  position: relative;
  background-color: $off-black;
  width: 45px;
  margin-top: 2px;
  margin-right: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &--items {
    position: absolute;
  }

  &__item {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 45px;
    height: 60px;
    box-sizing: border-box;
    border-left: 2px solid $off-black;
    border-right: 2px solid $off-black;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:last-child {
      margin-bottom: 15px;
    }

    &:hover,
    &.active {
      &:not(.is-running-live) {
        .power-bar__item--icon {
          img {
            filter: grayscale(100%) brightness(232%);
          }
        }
      }

      cursor: pointer;
      background-color: $black-ish;
      border-right: 2px solid $black-ish;

      .power-bar__item {
        &--label {
          color: $light-gray;
        }
      }
    }

    &.live {
      &:hover,
      &.active {
        border-left: 2px solid $bold-orange;
      }

      .power-bar__item--icon {
        width: 22px;
        height: 22px;

        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    &.tests,
    &.editor {
      .power-bar__item--icon {
        width: 20px;
        height: 20px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.editor {
      &:hover,
      &.active {
        border-left: 2px solid $dark-red;
      }
    }

    &.tests {
      &:hover,
      &.active {
        border-left: 2px solid $bold-blue;
      }
    }

    &.setups {
      &:hover,
      &.active {
        border-left: 2px solid $aquamarine;
      }
    }

    &.bots {
      &:hover,
      &.active {
        border-left: 2px solid $bold-purple;
      }

      .power-bar__item--icon {
        width: 25px;
        height: 22px;

        img {
          width: 25px;
          height: 22px;
        }
      }
    }

    &.scripts {
      &:hover,
      &.active {
        border-left: 2px solid $bold-green;
      }

      .power-bar__item--icon {
        width: 20px;
        height: 20px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.traders {
      &:hover,
      &.active {
        border-left: 2px solid $light-red;
      }

      .power-bar__item--icon {
        width: 20px;
        height: 21px;

        img {
          width: 20px;
          height: 21px;
        }
      }
    }

    &--icon,
    &--label {
      margin: 0 auto;
    }

    &--icon {
      margin-top: 11px;
    }

    &--label {
      font-weight: 500;
      font-size: $font-size-s;
      line-height: $font-size-s;
      margin-top: auto;
      margin-bottom: 7px;
      color: $metal;
    }

    &--alert-amount {
      position: absolute;
      right: 5px;
      top: 7px;
      background: #aa3d3d;
      color: $white;
      height: 12px;
      width: 12px;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      vertical-align: middle;
      border-radius: 11px;
    }
  }

  &--scroll-up,
  &--scroll-down {
    display: flex;
    width: 45px;
    height: 15px;
    background-color: $off-black;
    z-index: 1;
    user-select: none;

    &:hover {
      cursor: pointer;

      img {
        filter: grayscale(100%) brightness(232%);
      }
    }

    img {
      width: 8px;
      height: 8px;
      margin: auto;
      user-select: none;
    }
  }

  &--scroll-up {
    position: sticky;
    top: 0;
    border-bottom: 2px solid $black;

    img {
      transform: rotate(180deg);
    }
  }

  &--scroll-down {
    position: fixed;
    bottom: 0;
    border-top: 2px solid $black;
    margin-top: auto;
  }
}
