@import "../../styles/colors";
@import "../../styles/typography";

.empty-state {
  position: absolute;
  color: $light-gray;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  left: 30px;
  top: 66px;
}

.empty-table {
  font-weight: 400;
  color: $off-white;
  font-size: $font-size-md;
  display: flex;
  flex-direction: column;
  margin-left: calc(30px - 0.75rem);
  margin-right: calc(30px - 0.75rem);

  .getting-started {
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-md;
    line-height: 22px;
    color: $light-gray;

    letter-spacing: 0.05em;
    margin-bottom: 15px;

    @media (max-width: 575.98px) {
      text-align: center;
    }
  }

  &__row {
    height: 40px;
    width: 595px;
    margin-bottom: 10px;
    background-color: #141617;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-sm;
    line-height: 21px;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 997.98px) {
      width: 100%;
    }

    @media (max-width: 575.98px) {
      flex-direction: column;
      height: 70px;
      line-height: 26px;
      font-size: $font-size-wxs;
    }

    &:hover {
      background-color: $pine;
      cursor: pointer;
    }

    &__left,
    &__right {
      margin-top: 2px;
    }

    &__left {
      flex: 1 1;
      margin-left: 44px;
      font-weight: 600;
      color: $off-white;

      @media (max-width: 575.98px) {
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 5px;
        line-height: 15px;
      }

      img {
        position: absolute;
        left: 20px;
        top: 15px;

        @media (max-width: 575.98px) {
          top: 29px;
        }
      }
    }

    &__right {
      flex: 1.5 1;
      text-align: left;
      color: $light-gray;

      @media (max-width: 575.98px) {
        line-height: 15px;
        text-align: center;
        vertical-align: middle;
        padding: 0 40px;
        margin-top: 3px;
      }
    }
  }

  .disabled {
    opacity: 0.9;
    pointer-events: none;

    div {
      opacity: 0.4;
    }
  }
}
