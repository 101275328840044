@import "../../styles/colors";
@import "../../styles/typography";

.code-editor {
  &__left-actions {
    .strategy-manager {
      &--button {
        width: 35px;
        height: 30px;
        margin: 2px 2px 2px 0;
        background: $off-black;
      }

      &__toggler {
        height: 100%;
        padding: 0 10px;

        &:hover {
          span {
            background: $off-white;

            &:before,
            &:after {
              background: $off-white;
            }
          }
        }

        &--icon {
          width: 15px;
          height: 2px;
          background: $steel;
          display: inline-block;
          vertical-align: middle;
          position: relative;

          &:before,
          &:after {
            width: 15px;
            height: 2px;
            background: $steel;
            position: absolute;
            left: 0;
            content: "";
          }

          &:after {
            margin-top: 5px;
          }

          &:before {
            margin-top: -5px;
          }
        }
      }
    }
  }
}

.strategy-manager {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  &--background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: $transparent-black;
  }

  &__modal {
    max-width: 95%;
    width: 900px;
    height: 500px;
    padding: 0;
    background-color: $off-black;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 250px);
    z-index: 5;
  }

  &__panel {
    height: 100%;
    display: flex;

    &--sidebar {
      width: 150px;
      box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);

      .tabs {
        font-style: normal;
        font-size: $font-size-xs;
        margin: 20px 0 20px 20px;

        div {
          &:first-child {
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $steel;
            margin-bottom: 5px;
            font-weight: 600;
          }

          &:not(:first-child) {
            color: $off-white;

            &:hover,
            &.current-tab {
              color: $light-gray;
              cursor: pointer;
            }
          }
        }
      }
    }

    &--main {
      width: calc(100% - 140px);
    }

    &--header {
      height: 52px;
      width: 100%;
      border-bottom: 1px solid $black;
      display: inline-flex;

      & > * {
        align-self: center;
      }

      .icon {
        position: absolute;
        right: 14px;
        padding: 4px 6px;
        height: 24px;
      }

      .strategy-count {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        margin-left: 20px;
        margin-top: 2px;
        color: $off-white;
      }

      .single-column {
        margin-left: 20px;
      }
    }

    &--input {
      height: 32px;
      width: 260px;
      padding-left: 20px !important;

      .MuiInputBase {
        &-root {
          height: 32px !important;
          padding-right: 10px;
        }

        &-input {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }
      }

      .MuiOutlinedInput {
        &-notchedOutline {
          border-color: $steel !important;
        }
      }

      .search-icon {
        margin-top: 0px;
        height: 16px;
        width: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &--language-toggle {
      height: 32px;
      margin-left: 20px;
      background-color: $off-black !important;

      .MuiButtonBase-root {
        height: 32px;
        padding-top: 3px;

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: $off-white;
        border-color: $steel;
        text-transform: none;

        &:hover {
          background-color: rgba(229, 229, 229, 0.1);
        }

        &.Mui-selected {
          color: $off-black;
          border-color: $light-gray;
          background-color: $light-gray !important;
        }

        img {
          margin-top: -3px;
          height: 12px;
        }
      }
    }

    .panel {
      height: calc(100% - 54px);
      width: calc(100% - 1px);
      border-top: 1px solid #272829;

      &__inner {
        margin: 0;
      }
    }

    &--table {
      margin: 0 0 0 20px;
      table-layout: fixed;
      width: calc(100% - 45px);
      border-collapse: collapse;

      &::before,
      &::after {
        content: "";
        display: block;
        width: 714px;
      }

      &::before {
        top: 49px;
        height: 1px;
        box-shadow: 5px 0px 4px 4px rgba(0, 0, 0, 0.5);
        clip-path: inset(0px 0px -5px -5px);
        position: sticky;
      }

      &::after {
        background: $off-black;
        top: 50px;
        height: 6px;
        position: absolute;
        z-index: 0;
      }

      thead {
        tr {
          height: 50px;

          th {
            padding-top: 10px !important;

            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $steel;
            position: sticky;
            z-index: 2;
            top: 0;
            background: $off-black;

            &.name {
              span {
                padding-left: 12px;
              }

              &.public-name {
                span {
                  padding-left: 23px;
                }
              }
            }

            &.sorted {
              color: $off-white;
            }

            &.sortable {
              &:hover {
                cursor: pointer;
                color: $light-gray;
              }

              i {
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 5px solid $off-white;
                display: inline-block;
                position: absolute;
                margin-top: 5px;
                margin-left: 10px;

                &.asc {
                  transform: rotate(-90deg);
                  -webkit-transform: rotate(-90deg);
                }
                &.desc {
                  transform: rotate(90deg);
                  -webkit-transform: rotate(90deg);
                }
              }
            }
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background: $charcoal;
            cursor: pointer;
          }

          &:not(:last-child) {
            border-bottom: 1px solid $black;
          }

          &:hover {
            .action-menu-toggler {
              visibility: visible;
            }
          }

          &.empty-row {
            height: 5px;
          }
        }
      }

      th,
      td {
        &:not(.notes) {
          text-align: left;
          padding: 0 12px;
        }
      }

      td {
        &:not(.Tags):not(.name):not(.notes) {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:not(:last-child) {
          &:not(.notes) {
            border-right: 1px solid $black;
          }
        }

        & > div {
          display: inline-flex;
          white-space: nowrap;
          height: 18px;

          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: $off-white;

          img {
            &:hover {
              cursor: pointer;
            }
          }
        }

        &.name {
          padding-left: 0;
        }

        &.Tags {
          .tags-column {
            padding: 3px 0;
            width: 100%;
            height: unset;
          }

          .execution-tags,
          .editable-tags {
            &:hover {
              cursor: pointer;
            }
          }
        }

        &.author {
          img {
            padding-right: 5px;
          }
        }

        &.times-copied {
          width: 30px;
          text-align: center;
        }

        &.clickable {
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      .notes {
        width: 10px;
        padding: 0 10px;
      }

      .name {
        width: 215px;

        img {
          margin-right: 10px;
        }

        .language {
          width: 18px;
          vertical-align: middle;
          margin-right: 5px;

          &.pine {
            width: 16px;
            padding: 0 1px;
          }
        }

        .script-name {
          width: 185px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 2px;

          &:not(.public-script) {
            width: 165px;
          }
        }

        .public-script {
          margin-left: 12px;
          width: 200px;
        }

        .add-public-script {
          opacity: 0.5;
          margin-left: 5px;

          &:hover {
            opacity: 1;
          }
        }

        .action-menu {
          position: relative;

          &:hover {
            cursor: pointer;
          }

          &-toggler {
            visibility: hidden;
            float: right;
            width: 1px;
            height: 1px;
            border: 1px solid $off-white;
            background: $off-white;
            border-radius: 100px;
            margin-left: 10px;
            margin-top: 8px;
            margin-right: 5px;
            margin-bottom: 8px;
            position: relative;

            &:after,
            &:before {
              width: 1px;
              height: 1px;
              border: 1px solid $off-white;
              background: $off-white;
              border-radius: 100px;
              content: "";
              position: absolute;
              right: -1px;
            }

            &:after {
              bottom: 5px;
            }

            &:before {
              top: 5px;
            }
          }

          .action-dropdown {
            position: absolute;
            left: 35px;
            z-index: 1;
            background: $black;
            display: flex;
            flex-direction: column;
            padding: 5px 0 5px 0;
            border: 2px solid $off-black;

            button {
              width: 100%;
              text-align: left;
              padding-top: 2px;
              padding-bottom: 0;

              &.disabled {
                user-select: none;
                cursor: default;

                .action-option {
                  color: $steel;
                }
              }

              &:hover:not(.disabled) {
                background: $iron;

                .action-option {
                  color: $white;
                }
              }
            }

            .action-option {
              color: $off-white;
              width: 100%;
              margin-left: 15px;
              margin-right: 15px;
            }
          }
        }
      }

      .version {
        width: 40px;
      }

      .last-update {
        width: 135px;
      }

      .author {
        width: 150px;
      }

      .times-copied {
        width: 30px;
      }

      .Tags {
        width: 180px;
      }

      .language {
        img {
          height: 12px;
          padding-top: 1px;
          vertical-align: middle;
        }
      }
    }

    &--table-container {
      .empty-table {
        margin-left: 50px;
      }
    }
  }
}
