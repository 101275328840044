@import "../../../styles/colors";

.multivariantProgress {
  border-radius: 10px;
  overflow: hidden;

  span {
    display: block;
  }

  .progressBar {
    flex: 1;
    display: flex;
    height: 7px;
  }
}
