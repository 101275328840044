@import "../../../styles/colors";
@import "../../../styles/typography";

.execution_share {
  .share_content {
    padding-right: 50px;

    button {
      font-size: $font-size-md;
      text-decoration: underline;
      color: $off-white;
      &:hover {
        color: $pale-gray;
      }
    }

    .MuiSwitch {
      &-root {
        margin-left: -10px;

        .Mui-checked + .MuiSwitch-track {
          background-color: $white !important;
          opacity: 0.7 !important;
        }
      }

      &-track {
        background-color: $gray !important;
      }

      &-switchBase:not(.Mui-checked) {
        color: $gray !important;
      }
    }
  }
}
