@import "../../styles/colors";
@import "../../styles/typography";

td {
  &.Tags,
  &.TAG_ID {
    padding: 0 !important;
    position: relative;
    color: $light-gray;

    &:hover {
      .empty-tag-list {
        visibility: visible !important;
      }
    }

    .tags-column {
      height: 29px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .execution-tags-container {
      height: 180px;

      .panel {
        &__inner {
          margin: 0;
        }
      }

      .track-horizontal {
        height: 5px !important;
      }

      .track-vertical {
        display: none;
      }
    }

    .user-tags-container {
      height: 140px;
      top: 28px;
      position: absolute;
      width: 100%;
      border-top: 1px solid $charcoal;

      .panel {
        &__inner {
          margin: 0;
        }
      }

      .track-horizontal {
        display: none;
      }
    }

    .execution-tags,
    .user-tags {
      padding: 6px 10px 5px 10px;
      width: calc(100% - 20px);
    }

    & > .execution-tags {
      text-overflow: ellipsis;
      overflow: hidden;
      height: 25px;

      & > .tag-message {
        margin-top: 1px;
      }
    }

    .execution-tags {
      display: flex;

      .loading-tag {
        color: $light-gray;
        margin-top: 6px !important;
      }
    }

    .tag,
    .tag-message {
      height: 17px;
      border-radius: 3px;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-wxs;
      align-self: center;
    }

    .tag {
      margin-right: 4px;
      padding: 0 7px 2px 7px;
      text-align: center;
      color: $light-gray;

      &:hover {
        cursor: pointer;
      }
    }

    .tag-message {
      padding: 0;
      margin: 0 8px 0 0;
      font-size: $font-size-xs;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
      color: $off-white;
    }

    .user-tags {
      & > * {
        margin-bottom: 5px;
        color: $light-gray;
      }

      .tag {
        padding-bottom: 0;
        padding-top: 2px;

        img {
          padding: 7px 5px 8px 5px;
          margin-top: -1px;
        }
      }
    }

    .editable-tags {
      position: absolute;
      top: 0;
      z-index: 1;
      background: $off-black;
      border: 1px solid $charcoal;
      box-sizing: border-box;
      width: 100%;
      text-overflow: ellipsis;

      &:hover {
        cursor: default;
      }

      .tag {
        padding-right: 17px;
      }

      .execution-tags {
        background: $off-black;
        padding: 2px 10px;

        & > * {
          margin-top: 3px;
          margin-bottom: 3px;
        }
      }

      img {
        padding: 6px 5px 5px;
        position: absolute;
      }

      .tag-input-container {
        margin-bottom: 0;
        margin-top: 2px;
        flex: 1 1;
        display: inline-flex;

        input {
          min-width: 100%;
        }
      }

      input {
        background: transparent;
        border: 0;
        min-width: 10px;
        color: $light-gray;
        font-size: $font-size-wxs;
        padding-top: 3px;
      }

      .tag-error {
        top: 5px;
        left: 10px;
        position: absolute;
      }
    }

    .empty-tag-list {
      visibility: hidden;
    }

    .remove-user-tag-confirmation {
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-xxs;
      margin-top: 3px;

      span {
        margin-right: 3px;
      }

      img {
        height: 8px;
        padding-top: 4px;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }
}

.single-column {
  position: relative;

  .tags-label {
    position: absolute;
    margin-left: 15px;
    margin-top: 8px;
    font-size: $font-size-xs;
    color: $off-white;
    transform-origin: left top;
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;

    &.is-focused {
      transform: translate3d(-5px, -70%, 0) scale(0.85);
      z-index: 1;
      background: $off-black;
      padding: 0 5px;
    }
  }

  .tags-column {
    height: 30px;
    position: relative;
    border: 1px solid $steel;
    border-radius: 5px;
    width: 258px;

    &.disabled {
      pointer-events: none;
      border-color: $steel;
    }

    &.is-focused {
      border: 1px solid $steel;
    }

    .input-container__tooltip {
      position: absolute;
      top: -1px;
      right: -17px;
    }

    .tag-message {
      color: $light-gray;
      font-size: $font-size-xs;
      font-weight: 500;

      &.empty-tag-list {
        margin-top: 6px;
      }
    }

    .tag {
      height: 17px;
      border-radius: 3px;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-wxs;
      align-self: center;
      margin-right: 4px;
      padding: 0 7px 2px 7px;
      text-align: center;
      white-space: nowrap;
      z-index: 1;

      &:hover {
        cursor: pointer;
      }

      img {
        padding-left: 5px;
        padding-bottom: 1px;
      }
    }

    .panel {
      &__inner {
        margin: 0;
      }
    }

    .remove-user-tag-confirmation {
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-xxs;
      margin-top: 3px;

      span {
        margin-right: 3px;
      }

      img {
        height: 8px;
        padding: 0 4px;
      }
    }

    .composer-tags-container {
      height: 182px;

      .track-horizontal {
        height: 5px !important;
      }

      .track-vertical {
        display: none;
      }

      .composer-tags {
        overflow: unset;

        .tag-input-container {
          flex: 1 1;
          display: inline-flex;

          input {
            min-width: 100%;
          }
        }
      }
    }

    .editable-tags {
      .composer-tags {
        &:hover {
          cursor: default;
        }
      }
    }

    .composer-tags {
      height: 30px;
      width: calc(100% - 20px);
      margin: 0 10px;
      display: inline-flex;
      overflow: hidden;

      &:hover {
        cursor: text;
      }

      input {
        height: 28px;
        background: transparent;
        color: $light-gray;
        border: 0;
        font-size: $font-size-sm;
      }
    }

    .user-tags-container {
      height: 140px;
      top: 31px;
      position: absolute;
      width: 100%;
      background: $black;
      z-index: 3;

      .track-horizontal {
        display: none;
      }
    }

    .user-tags {
      padding: 6px 10px 5px;

      .tag {
        padding-bottom: 0;
        padding-top: 2px;
      }
    }

    .tag-input {
      font-size: $font-size-sm;
      margin-top: 7px;
      margin-left: 2px;
    }
  }
}

.strategy-manager-input {
  .tag-input {
    color: $off-white;

    &-container {
      input {
        color: $off-white !important;
        margin-top: -1px;
      }
    }
  }

  .composer-tags {
    width: calc(100% - 40px) !important;

    .tag {
      margin-top: 1px;
    }
  }

  .editable-tags {
    .composer-tags {
      .tag {
        margin-top: 0 !important;
        margin-bottom: 1px;
      }
    }
  }

  .search-icon {
    float: right;
    height: 16px;
    width: 16px;
    position: absolute;
    right: 5px;
    top: 7px;

    &:hover {
      cursor: pointer;
    }
  }
}

.strategy-manager__panel--table {
  .user-tags-container {
    top: 35px !important;
  }

  .editable-tags {
    .tag {
      margin-top: 6px !important;
      margin-bottom: 6px !important;
    }
  }

  .tag-input-container {
    margin-top: 4px !important;
  }
}
