@import "../../styles/colors";
@import "../../styles/typography";

.pending-page {
  height: 100%;
  display: flex;
  background-color: $off-black;

  .subscription-modal {
    &__loading {
      margin: auto;
    }
  }
}
