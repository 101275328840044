@import "../../styles/colors";
@import "../../styles/typography";

.columns-customizer {
  height: calc(100% - 80px) !important;

  &__modal {
    background-color: $off-black !important;
    width: 410px;
    height: 608px;
    overflow-y: hidden !important;
  }

  &--close {
    position: absolute;
    right: 20px;
    top: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &--title {
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-mdlg;
    color: $off-white;

    position: absolute;
    left: 50px;
    top: 50px;
  }

  &__list {
    margin-top: 80px !important;
    padding-top: 0 !important;
    height: 100%;

    .MuiListItemSecondaryAction {
      &-root {
        right: 35px;
      }
    }

    .panel {
      height: 100%;
    }

    .MuiListItem {
      &-container {
        width: 410px;
        margin: auto;

        .MuiIconButton {
          &-label {
            border: 1px solid $black;

            &:hover {
              border: 1px solid $steel;
            }
          }
        }
      }
    }
  }

  &__column {
    padding: 3px 20px 3px 10px !important;
    background: $black;
    margin-bottom: 5px;

    .undraggable {
      .MuiTypography {
        &-root {
          margin-left: 33px;
        }
      }
    }

    .MuiTypography {
      &-root {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-sm;
        color: $light-gray;
        margin-left: 15px;
      }
    }

    .draggable-item {
      &:hover {
        cursor: grab;
      }

      &:active {
        cursor: grabbing;
      }

      &--icon {
        float: right;
        width: 1px;
        height: 1px;
        border: 1px solid $off-white;
        background: $off-white;
        border-radius: 100px;
        margin-left: 10px;
        margin-top: 10px;
        margin-right: 5px;
        margin-bottom: 10px;
        position: relative;

        &:after,
        &:before {
          width: 1px;
          height: 1px;
          border: 1px solid $off-white;
          background: $off-white;
          border-radius: 100px;
          content: "";
          position: absolute;
          right: -1px;
        }

        &:after {
          bottom: 5px;
        }

        &:before {
          top: 5px;
        }

        &:first-child {
          margin-left: 0;
        }

        & + span {
          margin-right: 2px;
          margin-left: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    height: 100% !important;

    &--title {
      left: 2.75rem;
    }

    &__list {
      margin-top: 60px !important;

      .MuiListItemSecondaryAction {
        &-root {
          right: 10px;
        }
      }

      .MuiListItem {
        &-container {
          width: unset;
        }
      }

      .MuiListItemText {
        &-root {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &__column {
      .draggable-item {
        width: 18.5px;
      }
    }
  }
}

.columns-customizer__modal {
  &.can-save-presets {
    width: 730px;

    .columns-customizer {
      display: flex;

      &__list {
        width: calc(410px + 1.5rem);
      }

      &__preset {
        margin-left: 25px;
        margin-top: 85px;

        &--header {
          color: $light-gray;
          font-weight: 500;
          font-size: $font-size-md;
          margin-bottom: 12px;
        }

        &__field {
          display: flex;

          &--select {
            width: 200px;
            height: 40px;
            margin-right: 10px;

            .MuiInputBase {
              &-root {
                height: 40px !important;
                background-color: transparent;
                border: 1px solid $steel;
                border-radius: 5px;

                &:before,
                &:after {
                  display: none;
                }
              }

              &-input {
                color: $light-gray !important;
                font-size: $font-size-sm;
                font-weight: normal !important;
                padding-top: 6px !important;
                padding-bottom: 2.5px !important;
              }
            }

            .MuiAutocomplete-popupIndicator {
              color: $iron;
            }
          }

          &--delete {
            &:hover {
              filter: grayscale(100%) brightness(232%);
              cursor: pointer;
            }
          }
        }

        &__info {
          display: flex;
          margin-top: 6px;

          &__checkbox {
            display: flex;

            &:hover {
              cursor: pointer;

              .columns-customizer__preset__info__checkbox--field {
                border: 1px solid $bronze;
              }
            }

            &--field {
              padding: 0;
              margin: 8px 8px 8px 0;
              border: 1px solid $off-black;
              border-radius: 0;
            }

            &--title {
              color: $off-white;
              font-weight: 500;
              font-size: $font-size-sm;
              padding-top: 9px;
              line-height: 17px;
            }
          }

          &--input {
            height: 25px;
            margin-top: 4px;
            margin-left: 9px;

            input {
              width: 120px;
              height: 23px;
              background-color: $black;
              border: 0;
              font-weight: 500;
              font-size: $font-size-sm;
              font-style: normal;
              color: $off-white;
              padding: 2px 5px 0;
            }
          }
        }
      }

      @media (max-width: 767.98px) {
        flex-direction: column-reverse;

        &__list {
          width: 100%;
          margin-top: 0 !important;
        }

        &__preset {
          margin-top: 59px;
          margin-left: 0.75rem;
          margin-bottom: 30px;
          flex-shrink: 0;

          &--header {
            text-align: left;
          }

          &__field {
            &--delete {
              margin-right: auto;
            }
          }

          &__info {
            flex-direction: column;
            margin-top: 11px;

            &--input,
            &__checkbox {
              margin-left: 0;
            }

            &--input {
              margin-top: 1px;
            }
          }
        }
      }
    }
  }
}
