div.status_pending {
  background: $status-pending-color;
}

div.status_running,
div.status_active {
  background: $status-running-color;
}

div.status_ended {
  background: $status-ended-color;

  &.progress-bar {
    background: $status-running-color;
  }
}

div.status_failed,
div.status_expired {
  background: $status-failed-color;
}

div.status_stopping,
div.status_stopping_closemarket,
div.status_stopping_nextclose {
  background: $status-stopping-color;
}

div.status_liquidated {
  background: $status-liquidated-color;
}

div.status_cancelled {
  background: $status-cancelled-color;
}

div.status_archived {
  background: $status-ended-color;
}

span.status_pending {
  color: $status-pending-color;
}

span.status_running {
  color: $status-running-color;
}

span.status_ended {
  color: $status-ended-color;

  &.progress-bar {
    color: $status-running-color;
  }
}

span.status_failed {
  color: $status-failed-color;
}

span.status_stopping,
span.status_stopping_closemarket,
span.status_stopping_nextclose {
  color: $status-stopping-color;
}

span.status_cancelled {
  color: $status-cancelled-color;
}

span.status_liquidated {
  color: $status-liquidated-color;
}
