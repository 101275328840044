@import "../../../styles/colors";
@import "../../../styles/typography";

.script-info {
  padding: 0 20px;

  textarea {
    background: transparent;
    color: $off-white;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-mdlg;
    line-height: 25px;
    margin-top: 25px;
    width: 100%;
    height: 100vh;
    resize: none;
    border: 0;
  }

  p {
    font-weight: 400;
    color: $off-white;
    font-size: $font-size-md;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    background-color: $off-black;

    tr {
      border-top: solid 9px $black;
    }

    td {
      padding: 10px;
    }

    &__name {
      color: #656262;
      font-weight: 600;
      font-size: $font-size-sm;
      text-align: left;
      padding-left: 10px;
    }

    &__value {
      color: $light-gray;
      font-size: $font-size-md;
      text-align: right;
      padding-right: 10px;
    }
  }

  &__validation-status {
    font-size: $font-size-xxl;
    line-height: $font-size-xxl;
    padding: 30px 0;
  }

  &__subtitle {
    color: #656262;
    text-transform: uppercase;
    font-weight: 600;
    font-size: $font-size-sm;
  }

  &__validation-valid {
    color: $pale-green;
  }

  &__validation-invalid {
    color: $red;
  }

  &__label {
    color: $light-gray;
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: $font-size-md;
  }

  &__parameters {
    margin-bottom: 2rem;
  }

  &__plotName {
    display: flex;
    background: $off-black;
    margin-bottom: 0.25rem;
    padding-left: 0.5rem;
  }

  &__error {
    color: $red;
    font-family: "Droid Sans Mono";
    font-size: $font-size-s;
    max-width: 100%;
    white-space: pre-wrap;

    &--link {
      color: $bronze;
      font-family: "Droid Sans Mono";
      font-size: $font-size-s;
      white-space: pre-wrap;
      text-decoration: underline;

      &:hover {
        color: $yellow;
      }
    }
  }

  &__warn {
    color: $yellow;
    font-family: "Droid Sans Mono";
    font-size: $font-size-s;
    max-width: 100%;
    white-space: pre-wrap;
  }

  &__info {
    color: $pale-blue;
    font-family: "Droid Sans Mono";
    font-size: $font-size-s;
    max-width: 100%;
    white-space: pre-wrap;
  }

  .script-error {
    color: $red;
    white-space: pre-wrap;
    word-wrap: break-word;
    tab-size: 4;
  }
}
