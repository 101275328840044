@import "../../styles/colors";
@import "../../styles/typography";

.profile-photo {
  label {
    &:hover {
      cursor: pointer;

      .camera {
        opacity: 1;
      }
    }
  }

  .photo {
    height: 64px;
    width: 64px;
    border-radius: 64px;
  }

  div {
    line-height: 64px;
    height: 62px;
    width: 64px;
    padding-top: 2px;
    text-align: center;
    vertical-align: middle;
    border-radius: 64px;
    font-weight: bold;
    font-size: $font-size-xxl;
    text-transform: uppercase;
  }

  .camera {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 19px;
    opacity: 0.5;
  }

  input {
    display: none;
  }

  .loading {
    display: flex;
    background-color: $black;

    .spinner {
      padding: 0;
      margin: auto;
      height: 45%;
      opacity: 0.7;
    }
  }
}

.image-crop {
  position: relative;
  width: calc(100% - 80px);
  height: calc(100% - 172px);

  &--container {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(17, 17, 17, 0.9);
    padding: 40px;
    top: 0;
    left: 0;
  }

  &__controls {
    width: calc(100% - 120px);
    background-color: $white;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;

    &--slider {
      color: $off-black !important;
    }

    &__buttons {
      display: inline-flex;
      margin: 0 auto 10px;

      &--button {
        height: 30px;
        background-color: $bronze !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: $font-size-xs !important;
        color: $light-gray !important;
        padding-top: 8px !important;
        text-transform: none !important;
        box-shadow: unset !important;

        &.cancel-button {
          margin-right: 25px;
          background-color: $pine !important;
        }
      }
    }
  }
}
