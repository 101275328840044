@import "../../../styles/colors";
@import "../../../styles/typography";

.nav__item {
  list-style-type: none;

  &--bordered {
    border-left: 1px solid $nav__link-border-color;
    border-right: 1px solid $nav__link-border-color;
  }
}

.nav__link {
  color: $nav__link-color;
  display: block;
  font-size: $font-size-md;
  font-weight: 400;
  height: 100%;
  line-height: 1.5rem;
  padding: 1.25rem;
  text-align: center;
  transition: 0.25s background ease-out;

  &:hover {
    background: lighten($nav--primary-background-color, 10%);

    .editor--img {
      filter: brightness(150%);
    }
  }

  &--active {
    background: $nav__link--active-background-color;
    color: $nav__link--active-color;
    font-weight: 600;

    &:hover {
      background: lighten($nav__link--active-background-color, 10%);

      .editor--img {
        filter: brightness(150%);
      }
    }
  }
}
