.favorite {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
  padding-top: 7px;

  svg {
    vertical-align: middle;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}
