@import "../../styles/colors";
@import "../../styles/typography";

.user-page {
  background-color: $black;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .MuiOutlinedInput {
    &-notchedOutline {
      border-color: $steel !important;
    }
  }

  &.modal {
    height: auto;
    width: auto;
    background-color: transparent !important;
    padding: 0 !important;

    & > div {
      height: auto;
      width: auto;
      position: static !important;
    }
  }

  .panel {
    width: 100%;

    &__inner {
      margin: 0;
    }
  }

  &--body {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &--error {
    padding: 20px;

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: $light-gray;
    text-align: center;

    &.public-owner {
      display: flex;
      margin: 0 auto;
      flex-direction: column;

      button {
        color: $white;
        background-color: $bronze;
        height: 50px;
        width: 148px;
        padding: 2px 28px 0;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
        text-transform: uppercase;
        margin: 50px auto 0;
      }
    }
  }

  &--sign-in-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 9;
  }

  &__navigation {
    background-color: $black;
    height: 65px;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    align-items: center;

    .tuned-logo {
      margin-left: 40px;
      height: 16px;
      width: 92px;

      &.back-arrow {
        height: 20px;
        width: unset;
        margin-right: 81px;

        &:hover {
          cursor: pointer;
          filter: grayscale(100%) brightness(232%);
        }
      }
    }

    &--searchbar {
      vertical-align: middle !important;
      height: 40px;
      width: 100%;
      max-width: 600px;
      background-color: $off-black;
      border-radius: 5px;

      input {
        height: 28px;
        width: unset;
        flex: 1 1;
        padding: 0 5px;
        color: $light-gray !important;

        &.MuiInputBase-inputAdornedEnd {
          padding-top: 0 !important;
        }

        &[placeholder] {
          text-overflow: ellipsis;
        }
      }

      fieldset {
        border-width: 0 !important;
      }

      .advanced-search-button {
        color: $light-gray !important;
        font-weight: 400 !important;
        line-height: 28px !important;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .MuiInputBase-root {
        height: 40px !important;
      }
    }

    &--searchbar-container {
      position: relative;
      margin-left: 35px;
      margin-right: 90px;
      flex: 1 1;
    }

    &__search-results {
      position: absolute;
      z-index: 2;
      background-color: $black;
      width: 100%;
      max-height: 400px;
      overflow-y: auto;

      &--users,
      &--syndications {
        .syndication-result,
        .user-result {
          height: 28px;
          line-height: 30px;
          padding: 2px 15px 0;
          font-size: $font-size-sm;

          font-style: normal;
          font-weight: 500;
          color: $light-gray;
          width: calc(100% - 30px);
          display: inline-flex;

          &.selected {
            cursor: pointer;
            background-color: #202020;
          }

          div {
            &:first-child {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: calc(100% - 76px);
              padding-right: 5px;
            }

            &:last-child {
              font-style: normal;
              font-weight: 600;
              float: right;
              width: 76px;
              text-align: right;
            }
          }
        }
      }

      &--users {
        .user-result {
          div {
            &:last-child {
              color: #cab25c;
            }
          }
        }
      }

      &--syndications {
        .syndication-result {
          div {
            &:last-child {
              color: #5ca2ca;
            }
          }
        }
      }

      &--empty {
        height: 30px;
        line-height: 30px;
        padding: 15px 15px 11px;
        font-size: $font-size-sm;

        font-style: normal;
        font-weight: 500;
      }

      &--count {
        position: sticky;
        top: 0;
        padding: 6px 14px 4px;
        background-color: $black;
        color: $off-white;

        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        text-align: right;
      }
    }

    &--menu {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .user-menu-button {
        height: 28px;
        margin-right: 40px;
        display: inline-flex;
        vertical-align: middle;

        & + .user-menu-button {
          margin-left: -30px;
          img {
            margin: auto;
          }
        }

        .user-name {
          font-style: normal;
          font-weight: 500;
          font-size: $font-size-md;
          color: $light-gray;
          padding-top: 2px;
        }

        .user-dropdown-arrow {
          margin: auto 0 auto 5px;
          padding-top: 2px;
        }
      }

      .sign-in-button {
        color: $off-white;
        width: 135px;

        &:hover {
          color: $light-gray;
        }
      }

      .sign-up-button {
        color: $light-gray;
        width: 130px;
        background-color: $bronze;

        &:hover {
          color: $white;
        }
      }

      .sign-in-button,
      .sign-up-button {
        font-size: $font-size-sm;
        height: 65px;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-md;
        text-align: center;
      }
    }

    &__dropdown {
      position: absolute;
      top: 73px;
      right: 46px;
      z-index: 3;
      background-color: $black;

      &--option {
        height: 40px;
        font-size: $font-size-sm;
        padding: 0 1.25rem;
        line-height: 40px;
        color: $white-ish;
        font-weight: 400;

        &:hover {
          cursor: pointer;
          background-color: $steel;
        }
      }
    }

    &--away {
      color: $light-gray;
      font-weight: 500;
      font-size: $font-size-md;
      margin-right: 35px;
      margin-top: 1px;

      &:hover {
        color: $camel;
      }
    }
  }

  &__overview {
    width: 210px;
    height: calc(100vh - 110px);
    margin-left: 100px;
    padding: 20px 30px;
    flex-shrink: 0;

    &--photo {
      height: 60px;
      width: 64px;
      border-radius: 64px;
      line-height: 64px;
      padding-top: 4px;
      margin-bottom: 15px;
      color: $light-gray;
      font-style: normal;
      font-weight: 500;
      font-size: $font-size-xxxl;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
      position: relative;

      &.photo {
        height: 64px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__info {
      margin-bottom: 29px;

      &--display-name {
        color: $silver;
        font-size: 22px;
        font-style: normal;
        font-weight: normal;

        &:hover {
          cursor: pointer;
        }

        .nickname {
          color: $cantaloupe;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          margin-bottom: 10px;
        }
      }

      &--date {
        color: $off-white;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
      }

      &--exchanges {
        margin-top: 5px;
        margin-bottom: 3px;

        img {
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }

      &--country {
        color: $off-white;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        padding-left: 2px;

        img {
          margin-right: 5px;
          margin-bottom: -2px;
        }
      }
    }

    &__syndications {
      font-style: normal;
      font-size: $font-size-mdlg;
      padding-left: 5px;

      &--header {
        color: $off-white;
        font-weight: 600;
      }

      &--count {
        color: $light-gray;
        font-weight: 500;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  &__syndications {
    margin-right: auto;
    padding-left: 45px;
    height: 100%;
    width: 100%;

    &.no-links {
      padding-right: 0;
    }

    .panel {
      height: 100%;

      &__inner {
        margin-left: 0;
        width: calc(100% - 280px);
      }
    }

    &__description {
      padding-bottom: 40px;

      &--header {
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-xlg;
        color: $light-gray;
      }

      &--body {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $light-gray;
        width: 70%;

        &.expanded {
          div {
            max-height: unset;
            white-space: normal;
          }
        }

        div {
          max-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          color: $steel;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    &__list {
      display: inline-flex;
      flex-wrap: wrap;

      &__header {
        display: flex;
        width: 100%;

        @media (max-width: 1919.98px) {
          flex-direction: column;

          &__info {
            padding-right: 0 !important;
          }

          &__filters {
            margin-bottom: 20px;
          }
        }

        &__info {
          padding-right: 20px;
          margin-right: auto;
        }

        &__filters {
          display: flex;

          @media (max-width: 991.98px) {
            flex-direction: column;

            .user-page__advanced-search__filters--multi-field {
              margin-bottom: 20px;
            }
          }

          .user-page__advanced-search__filters--multi-field {
            margin-right: 20px;
          }

          .checkbox-field {
            margin-top: 0;
            width: unset;

            .MuiFormControlLabel {
              &-label {
                white-space: nowrap;
              }
            }
          }
        }
      }

      &--active-count {
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-sm;
        color: $light-gray;
        width: 100%;
        margin-bottom: 5px;
      }

      &--description {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $off-white;
        margin-bottom: 15px;
        width: 100%;
      }

      .syndication-card {
        width: 470px;
        height: 230px;
        background-color: $off-black;
        margin-right: 30px;
        margin-bottom: 30px;
        padding: 15px 25px;
        position: relative;
        border-left: 6px solid $bright-yellow;
        border-radius: 15px;

        &:hover {
          cursor: pointer;
          background-color: $dark;
        }

        &.fully-allocated {
          border-left: 6px solid $bright-green;
          animation: yellowToGreenBorder 2s;
        }

        &.subscribed {
          padding: 14px 24px;
          border: 1px solid $cantaloupe;
        }

        &--name {
          display: flex;
          flex-wrap: wrap-reverse;

          font-style: normal;
          font-weight: 500;
          font-size: $font-size-mdlg;
          color: $light-gray;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .syndication-card--fees {
            margin-left: auto;

            .fees {
              img {
                margin-left: 5px;
              }
            }
          }
        }

        &--tags-container {
          width: calc(100% - 50px);
        }

        &--tags {
          display: inline-flex;
          position: absolute;
          overflow: hidden;
          white-space: nowrap;

          margin-top: 5px;

          &:hover {
            overflow: visible;
            flex-wrap: wrap;

            .syndication-tag {
              margin-bottom: 5px;
            }
          }
        }

        &--row {
          display: flex;
        }

        &--profitability {
          margin-top: 45px;
          width: 140px;
          padding-right: 8px;

          &:not(:first-child) {
            margin-left: auto;
          }

          &:not(:last-child) {
            margin-right: auto;
          }

          .header {
            color: $off-white;

            font-style: normal;
            font-weight: 500;
            font-size: $font-size-sm;
          }

          .profitability {
            color: $light-gray;

            font-style: normal;
            font-weight: 500;
            font-size: $font-size-xlg;

            &.pos {
              color: $light-green;
            }

            &.neg {
              color: $red;
            }
          }
        }

        &--subscriber-count {
          color: $cantaloupe;

          font-style: normal;
          font-weight: 500;
          font-size: $font-size-sm;
          margin-top: 10px;
          visibility: hidden;
        }

        &--runtime {
          color: $off-white;

          font-style: normal;
          font-weight: 500;
          font-size: $font-size-sm;
          line-height: $font-size-sm;
        }

        &--allocated-bar {
          height: 6px;
          margin-top: 5px;
          background-color: #4e5252;
          border-radius: 10px;
          position: relative;

          .highlighted-bar {
            position: absolute;
            height: 6px;
            border-radius: 10px;

            .bar {
              height: 6px;
              border-radius: 10px;
              background-color: $bright-yellow;
              animation: fullyIncrease 2s;
            }

            &.fully-allocated {
              .bar {
                background-color: $bright-green;
                animation: yellowToGreen 2s, fullyIncrease 2s;
              }
            }
          }
        }

        &--allocation {
          font-style: normal;
          font-weight: 500;
          font-size: $font-size-sm;
          display: flex;
          margin-top: 5px;

          .allocation-percent {
            color: $light-gray;
            font-weight: 600;
            margin-right: auto;

            &.fully-allocated {
              color: $bright-green;
              animation: lightGrayToGreenColor 2s;

              img {
                height: 16px;
              }
            }

            img {
              margin-bottom: -2.5px;
              margin-right: 5px;
            }
          }

          .allocation-fraction {
            color: $off-white;

            .fully-allocated {
              color: $light-green;
              animation: yellowToGreenColor 2s;
            }

            .partially-allocated {
              color: $light-yellow;
            }
          }
        }
      }
    }

    &--load-button {
      display: flex;

      .load-button {
        width: unset;
        margin: 0 auto 30px;
      }
    }
  }

  &__links {
    width: 200px;
    float: right;
    display: flex;
    flex-direction: column;
    margin-top: 115px;
    margin-bottom: auto;
    padding: 30px 40px;
    background-color: $off-black;
    flex-shrink: 0;
    position: absolute;
    right: 0;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;

    img {
      width: 28px;
      height: 28px;
      margin-bottom: -8px;
      margin-right: 10px;

      &.linkedin {
        width: 32px;
        margin-right: 6px;
      }
    }

    a {
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-sm;
      color: $off-white;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .user-photo {
    width: 27px;
    height: 27px;
    border-radius: 26px;
    color: $light-gray;

    font-style: normal;
    font-weight: 500;
    font-size: $font-size-mdlg;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;

    &.empty {
      height: 25px;
      line-height: 25px;
      padding-top: 2px;
    }
  }
}

@media (max-width: 1599.98px) {
  .user-page {
    &__overview {
      margin-left: 0;
    }

    &__syndications {
      .panel {
        &__inner {
          width: calc(100% - 240px);
        }
      }
    }

    &__links {
      padding: 15px 20px;
    }
  }
}

@media (max-width: 1199.98px) {
  .user-page {
    &__navigation {
      &--searchbar-container {
        margin-right: 20px;
      }

      &--away {
        margin-right: 20px;
      }
    }

    &__links {
      padding: 15px 20px;
      width: unset;

      a {
        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .user-page {
    &__overview {
      padding: 15px 20px;
      height: calc(100vh - 95px);
    }

    &__navigation {
      &--away {
        display: none;
      }

      &--link {
        .tuned-logo {
          margin-left: 20px;
        }
      }

      &--menu {
        .user-menu-button {
          .mobile-dropdown {
            width: 31px;
            height: 26px;
          }

          & + .user-menu-button {
            margin-right: 20px;
          }
        }
      }
    }

    &__syndications {
      padding-left: 20px;

      .panel {
        &__inner {
          width: calc(100% - 78px);
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .user-page {
    &__navigation {
      height: 50px;

      &__dropdown {
        right: 0;
        top: 50px;
        width: 100%;
        background-color: $off-pine;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid $peacock;

        &--option {
          font-size: $font-size-mdlg;
          font-weight: 600;
          color: $off-white;
          padding: 2px 10px 0;
          height: 38px;
        }
      }

      &--menu {
        .sign-in-button,
        .sign-up-button {
          width: 95px;
        }

        .user-menu-button {
          .mobile-dropdown {
            width: 31px;
            height: 27px;
          }

          & + .user-menu-button {
            margin-right: 14px;
          }

          .user-dropdown-arrow {
            display: none;
          }
        }
      }

      &--searchbar-container {
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 3;
        padding: 10px 20px;
        margin: 0;
        width: calc(100% - 40px);
        background-color: $black;
        border-top: 1px solid $off-black;
      }

      &--searchbar {
        max-width: unset;
      }

      &--mobile-search-container {
        display: flex;
        margin-left: auto;
        margin-right: 4px;
      }

      &--mobile-search-icon {
        color: $off-white;

        &.enabled {
          color: $white;
        }
      }

      &__search-results {
        width: calc(100% - 50px);
      }

      &--link {
        .tuned-logo {
          margin-left: 20px;
        }
      }

      .user-menu-button {
        margin-right: 22px;

        .user-name {
          display: none;
        }

        & + .user-menu-button {
          margin-left: -10px;
          img {
            margin: auto;
          }
        }
      }
    }

    &--body {
      flex-direction: column;
    }

    &__overview {
      width: unset;
      height: unset;
      text-align: center;
      position: relative;
      background-color: $off-black;

      &--photo {
        margin: auto;
      }

      &__info {
        margin-bottom: 0;

        &__mobile {
          display: flex;
          flex-direction: column;

          &--row {
            display: flex;

            .left {
              margin-right: auto;
              text-align: left;
              display: flex;
              flex-direction: column;

              &:last-child {
                margin-right: 0;
                width: 100%;

                .country {
                  margin: auto;
                }
              }

              .country {
                margin-right: 10px;
              }
            }

            .right {
              margin-left: auto;
              text-align: right;
              color: $off-white;
              font-weight: 500;
              font-size: 13px;

              &:first-child {
                margin-left: 0;
                display: flex;
                width: 100%;

                .count {
                  margin: auto;
                }
              }

              .count {
                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }
          }
        }

        &--display-name {
          .nickname {
            display: none;
          }
        }

        &--date {
          margin-bottom: 10px;
        }

        &--exchanges {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &__syndications {
      width: 100%;
      padding-right: 0;
      padding-left: 0;

      &__description {
        &--body {
          width: 100%;
        }
      }

      &__list {
        .syndication-card {
          width: calc(100% - 50px);
          height: unset;
          margin-right: 0;
        }

        .load-button {
          width: unset;
          height: 37px;
        }
      }

      .panel {
        &__inner {
          width: 100%;

          & > * {
            &:not(.user-page__overview) {
              margin-left: 20px;
              margin-right: 20px;
            }
          }
        }

        .track-vertical {
          right: 0;
        }
      }
    }

    &__links {
      margin-top: 0;
      flex-direction: row;
      margin: auto;
      position: relative;
      border-bottom-left-radius: 0;
      border-top-right-radius: 15px;

      a {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 15px;
        }

        img {
          margin-right: 0 !important;
        }
      }
    }

    .user-photo {
      margin-right: 0;
    }
  }
}

@keyframes fullyIncrease {
  0% {
    width: 80%;
  }

  100% {
    width: 100%;
  }
}

@keyframes yellowToGreen {
  0% {
    background-color: $bright-yellow;
  }

  100% {
    background-color: $bright-green;
  }
}

@keyframes yellowToGreenBorder {
  0% {
    border-left: 6px solid $bright-yellow;
  }

  100% {
    border-left: 6px solid $bright-green;
  }
}

@keyframes lightGrayToGreenColor {
  0% {
    color: $light-gray;
  }

  100% {
    color: $bright-green;
  }
}

@keyframes yellowToGreenColor {
  0% {
    color: $bright-yellow;
  }

  100% {
    color: $bright-green;
  }
}
