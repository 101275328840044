@import "../../styles/colors";
@import "../../styles/typography";

.welcome {
  &.modal {
    position: absolute !important;
    display: flex;
    padding: 0 !important;
    width: 100%;
    height: 100%;
    max-height: unset !important;
  }

  .welcome-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    background: url("../../images/shapes2.svg");
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    max-width: 705px;
    max-height: 560px;

    &--logo {
      position: absolute;
      height: 17px;
      top: 0;
      left: 0;
    }

    &--close {
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        cursor: pointer;
        filter: grayscale(100%) brightness(232%);
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      margin: auto 0;

      &--title {
        font-size: $font-size-xxl;

        span {
          font-weight: 600;
        }
      }

      &--subtitle {
        font-weight: 500;
        font-size: $font-size-md;
        color: $light-gray;
      }

      &__choices {
        margin: 50px 0;

        &--choice,
        &--user-type {
          display: block;
          background-color: $dark;
          border-radius: 20px;
          border: 1px solid $off-dark;
          font-weight: 500;
          font-size: $font-size-mdlg;
          color: $light-gray;

          &:first-child {
            margin-bottom: 15px;
          }

          &:hover {
            cursor: pointer;
            background-color: $pine-muted;
            border: 1px solid $pine-muted;
          }

          img {
            margin-right: 30px;
          }

          span {
            margin-right: 28px;
          }
        }

        &--choice {
          height: 68px;
          line-height: 70px;
          padding: 0 40px;
        }

        &--user-type {
          display: flex;
          flex-direction: column;
          min-height: 60px;
          padding: 30px 40px;

          .welcome-choice-header {
            color: $light-gray;
            font-size: $font-size-mdlg;
            margin-bottom: auto;
          }

          .welcome-choice-info {
            color: $off-white;
            font-size: $font-size-md;
          }
        }
      }

      &--button {
        width: 370px;
        height: 50px;
        margin: 0 auto auto;
        color: $white;
        font-weight: normal;
        font-size: $font-size-mdlg;
        background-color: $bronze;
        border-radius: 5px;
      }

      &--footer {
        font-size: $font-size-md;
        color: $steel;
        margin: auto;
      }
    }
  }
}
