@import "../../../../styles/colors";
@import "../../../../styles/typography";

.user-card {
  width: 415px;
  height: 165px;
  padding: 20px;
  margin-right: 25px;
  margin-bottom: 25px;
  border-radius: 15px;
  background-color: $off-black;

  &:hover {
    cursor: pointer;
    background-color: $dark;
  }

  &__header {
    display: flex;

    &--photo {
      height: 58px;
      width: 60px;
      border-radius: 64px;
      line-height: 58px;
      padding-top: 2px;
      margin-bottom: 15px;
      color: $light-gray;
      font-style: normal;
      font-weight: 500;
      font-size: 2.1rem;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
      position: relative;
      flex-shrink: 0;
    }

    &--user-info {
      margin-left: 20px;

      .display-name {
        color: $light-gray;
        font-size: $font-size-lg;
        font-weight: 600;
        line-height: 23px;

        &.empty {
          height: 23px;
          width: 100px;
          border-radius: 5px;
          background-color: $charcoal;
        }
      }

      .trading-date {
        color: $off-white;
        font-size: $font-size-sm;
        font-weight: 500;
      }

      .exchanges {
        display: flex;
        flex-wrap: wrap;
        height: 32px;

        img {
          margin-right: 8px;

          &.ftx-logo {
            margin-top: 1px;
          }

          &.bybit-logo {
            margin-top: 2px;
          }
        }
      }
    }
  }

  &__syndication-details {
    display: flex;
    margin-top: 15px;
    margin-bottom: 4px;

    &--info {
      flex: 1 1;

      &:not(:last-child) {
        margin-right: 25px;
      }

      .body {
        color: $light-gray;
        font-weight: 600;
        font-size: $font-size-xlg;
        line-height: 20px;
      }

      .title {
        color: $off-white;
        font-weight: 500;
        font-size: $font-size-sm;
        white-space: nowrap;
      }

      .empty {
        height: 18px;
        border-radius: 5px;
        background-color: $charcoal;

        & + .empty {
          margin-top: 5px;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    width: auto;

    &--syndications {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      width: auto;

      .profitable-syndications-count {
        height: 13px;
        display: flex;

        &:first-child {
          margin-bottom: 3px;
        }

        .invisible {
          visibility: hidden;
        }

        img {
          margin-right: 2px;
        }

        div {
          color: $light-gray;
          font-weight: 500;
          font-size: $font-size-xxs;
          margin-top: -2px;
          margin-left: 2px;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .user-card {
    margin-right: 0;
  }
}

@media (max-width: 464.98px) {
  .user-card {
    height: unset;

    &__syndication-details {
      flex-wrap: wrap;

      &--info {
        margin-bottom: 10px;
      }
    }
  }
}
