@import "../../styles/colors";
@import "../../styles/typography";

.mobile-blocker {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../images/shapes.svg"),
    linear-gradient(150.17deg, #1d3a45 -40.97%, #0f1416 84.89%);
  background-size: cover;

  &--header {
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 45px;
    margin-left: 25px;

    img {
      height: 18px;

      &.non-pro {
        width: 105px;
      }
    }
  }

  &__body {
    width: 100%;

    &__info {
      margin-left: 25px;
      margin-right: 25px;

      &--title {
        margin-bottom: 5px;
        line-height: 22px;

        &.bottom-gap {
          margin-bottom: 20px;
        }

        span {
          font-weight: 500;
          font-size: $font-size-mdlg;
          color: $light-gray;
        }
      }

      &--subtitle {
        line-height: 22px;

        & + .mobile-blocker__body__info--subtitle {
          margin-top: 5px;
        }

        span {
          font-weight: 400;
          font-size: $font-size-md;
          color: $off-white;
        }
      }
    }

    &__buttons {
      margin-top: 60px;

      &--info {
        font-weight: 500;
        font-size: $font-size-sm;
        color: $light-gray;
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 10px;
      }

      &--container {
        display: flex;
        flex-direction: column;
      }

      &--button {
        &:not(:first-child) {
          margin-top: 10px;
        }

        a,
        button {
          display: block;
          font-weight: 500;
          font-size: $font-size-sm;
          margin-left: 25px;
          margin-right: 25px;
          text-align: left;
          background-color: $darkish-pine;
          border: 2px solid #3d3d3d;
          border-radius: 10px;
          cursor: pointer;
          box-sizing: border-box;
          padding: 11px 20px 10px;

          img {
            position: absolute;
            margin-top: 2px;
            width: 14px;
            height: 14px;

            & + .text {
              margin-left: 30px;
            }
          }

          .text {
            color: $light-gray;
          }

          .info {
            color: $off-white;
            margin-top: 10px;
          }

          &:hover {
            background-color: $white;

            .text {
              color: $off-white;
            }
          }
        }

        button {
          width: calc(100% - 50px);
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 35px;

    &--button {
      a,
      div {
        color: $off-white;
        text-decoration: underline;
        font-size: $font-size-ss;

        &:hover {
          cursor: pointer;
          color: $light-gray;
        }
      }
    }

    &__steps {
      display: flex;
      margin-top: 25px;
      margin-left: auto;
      margin-right: auto;

      &--step {
        height: 6px;
        width: 6px;
        border-radius: 6px;
        background-color: $steel;

        &.active {
          background-color: $light-gray;
        }

        &:first-child {
          margin-right: 3px;
        }
      }
    }
  }
}
