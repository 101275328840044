.modal.pine-transpiler {
  width: 500px;
  height: 500px;

  .form__field--code {
    height: 300px;
    margin-bottom: 55px !important;

    pre,
    textarea {
      padding: 15px 35px !important;
    }
  }
}
