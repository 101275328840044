@import "../../../styles/colors";
@import "../../../styles/typography";

.portfolio-chart {
  height: calc(100% - 60px);
  max-height: calc(100vh - 120px);
  width: 100%;
  padding: 30px 0;

  &.performance-chart {
    &.bar-chart {
      .portfolio-chart {
        &--tooltip {
          color: $light-yellow;
        }
      }
    }
  }

  &.empty-chart {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767.98px) {
    margin-top: auto;
    height: calc(100% - 70px);
    padding-top: 40px;
  }

  &--container {
    overflow: hidden;

    &:not(.bar-chart-container) {
      background: repeating-linear-gradient(
        0deg,
        $black,
        $black 60px,
        $off-black 62px,
        $black 1px
      );
    }

    @media (max-width: 767.98px) {
      display: flex;
    }
  }

  &--tooltip {
    font-weight: 600;
    font-size: $font-size-xs;
    color: $off-white;
    user-select: none;
  }

  .recharts-area-area {
    fill-opacity: 0;
  }

  .recharts-dot {
    r: 0;
  }

  .recharts-tooltip-cursor {
    stroke: #4f4b47;
  }

  .recharts-tooltip-wrapper {
    top: unset !important;
    bottom: 14px;
  }

  .recharts-cartesian-axis-tick-line {
    display: none;
  }

  .recharts-cartesian-axis-ticks {
    font-size: $font-size-sm;
  }
}
