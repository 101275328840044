@import "../../styles/colors";
@import "../../styles/typography";

.new-user-experience {
  position: absolute;
  z-index: 2;
  background-color: $white;
  width: 210px;
  border-left: 10px solid $pine;
  display: flex;
  flex-direction: column;

  &::after {
    content: " ";
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid $white;
    display: inline-block;
    position: absolute;
    bottom: calc(50% - 2.5px);
    right: -5px;
    width: 0;
    height: 0;
  }

  &.bottom,
  &.top {
    &::after {
      right: calc(50% - 2.5px);
    }

    &.right {
      &::after {
        right: 10%;
      }
    }

    &.left {
      &::after {
        left: 10%;
      }
    }
  }

  &.bottom {
    &::after {
      bottom: -7px;
      transform: rotate(90deg);
    }
  }

  &.top {
    &::after {
      top: -7px;
      transform: rotate(-90deg);
    }
  }

  &--info {
    color: $steel;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    padding: 0 10px;
    line-height: 16px;

    p {
      margin: 5px 0;
    }

    a {
      color: $bronze;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &--close {
    border-top: 1px solid #e7e7e7;
    align-items: center;
    display: flex;
    height: 23px;

    button {
      height: 16px;
      padding: 1px 7px;
      color: $light-gray;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      background-color: #d88113;
      border-radius: 3px;
      margin-left: auto;
      margin-right: 5px;
      line-height: 15px;
    }
  }
}

.code-editor__type {
  .new-user-experience {
    bottom: 70px;
    right: 2px;
  }
}

.composer-container {
  .new-user-experience {
    top: 40px;

    &--info {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.action-menu {
  .new-user-experience {
    margin-top: 35px;
    margin-left: -203px;
    white-space: normal;
    overflow: visible;

    &--info {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.settings {
  &__side-panel {
    .new-user-experience {
      margin-top: 110px;
      margin-left: 29px;
    }
  }

  &__general {
    &--popup-block {
      .new-user-experience {
        margin-top: 33px;

        @media (max-width: 767.98px) {
          margin-top: 50px;
          margin-left: -100px;
        }
      }
    }

    &--max-length {
      .new-user-experience {
        text-align: left;
      }
    }
  }
}
