@import "../../styles/colors";
@import "../../styles/typography";

.login-page:not(.modal) {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $sacramento;
  font-style: normal;
  font-weight: normal;
}

.login-modal {
  animation: modalLoad 1s cubic-bezier(0, 0.26, 0.12, 0.97);
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;

  @media (min-width: 992px) {
    width: 732px;
    height: 432px;
  }

  @media (max-width: 991.98px) and (min-width: 768px) {
    width: 432px;
    height: 332px;
  }

  @media (max-width: 767.98px) {
    width: calc(100vw - 80px);
  }

  .form__field {
    animation: modalLoad 1s cubic-bezier(0, 0.26, 0.12, 0.97) !important;

    @media (max-width: 767.98px) {
      width: calc(100vw - 146px) !important;

      input {
        width: calc(100vw - 146px) !important;
        min-width: 0 !important;
      }
    }
  }

  .button {
    animation: modalLoad 1s cubic-bezier(0, 0.26, 0.12, 0.97) !important;
  }
}

.login-page {
  &__wrapper {
    @media (min-width: 992px) {
      width: 30%;
      position: relative;
      top: calc(50% - 80px);
      left: 63%;
      transform: translate(-50%, -50%);
      padding: 2.1875rem;
    }

    @media (max-width: 991.98px) and (min-width: 768px) {
      width: 50%;
      position: relative;
      top: calc(50% - 80px);
      left: 69.25%;
      transform: translate(-50%, -50%);
      padding: 2.1875rem;
    }

    @media (max-width: 767.98px) {
      width: 90%;
      margin: auto;
    }

    .spinner {
      animation: modalLoad 2s cubic-bezier(0, 0.26, 0.12, 0.97) !important;
    }

    .tuned-logo {
      width: 17.5%;
      margin-bottom: 45px;
    }

    &__title {
      color: $silver;
      font-size: 22px;
      line-height: 35px;

      @media (max-width: 767.98px) {
        text-align: center;
      }
    }

    &__sub {
      animation: titleLoad 2.2s cubic-bezier(0, 0.26, 0.47, 0.98);

      .lock {
        @media (max-width: 767.98px) {
          margin: auto;
          display: table;
        }
      }
    }
  }

  .form__field {
    position: relative;
    animation: modalLoad 4s cubic-bezier(0, 0.26, 0.12, 0.97);
    margin-top: 10px;

    &:not(:first-child) {
      margin-top: 25px;
    }

    &__error {
      position: absolute;
      margin-top: 3px;
    }

    input {
      position: relative;
      z-index: 1;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $pale-gray;
      color: $pale-gray;
      display: block;
      width: 100%;
      height: 50px;
      transition: background-color 0.125s, border-color 0.125s;
      padding: 15px 10px 0;
      font-style: normal;
      font-weight: normal;
      /* this font doesn't update from the parent */
      font-size: 14px;
      line-height: 22px;

      letter-spacing: 0.05em;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: $pale-gray;
      -webkit-box-shadow: 0 0 0px 1000px rgba(56, 64, 68, 1) inset;
    }

    input.input-focus {
      outline: none;
    }

    input.input-focus + label {
      transform: translate3d(5px, -105%, 0) scale(0.85);
      margin-bottom: 0px;
      z-index: 1;
    }

    label {
      &:not(.login-checkbox) {
        pointer-events: none;
      }

      user-select: none;
      font-size: 14px;
      font-weight: 500;
      color: $pale-gray;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      bottom: 0;
      backface-visibility: hidden;
      left: 0;
      transform-origin: left top;
      transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
      will-change: transform;
      cursor: text;
      text-transform: uppercase;
    }
  }

  .button {
    height: 50px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    display: inline-block;
    font-weight: lighter;
    padding: 5px 5px 1px 5px;
    margin-top: 20px;
    position: relative;
    z-index: 1;
    animation: modalLoad 4s cubic-bezier(0, 0.26, 0.12, 0.97);
  }

  .user-access {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;

    & + .user-access {
      margin-top: 10px;
    }
  }

  .access-button {
    font-size: $font-size-xs;
    line-height: 19px;
    font-weight: 600;
    color: $off-white;
    animation: modalLoad 4s cubic-bezier(0, 0.26, 0.12, 0.97);

    &:not(.forgot-password) > span {
      color: $cantaloupe;
    }

    &.back-button {
      @media (max-width: 767.98px) {
        position: fixed;
        top: 70px;
      }
    }

    &:hover {
      cursor: pointer;

      & > span {
        text-decoration: underline;
      }
    }
  }

  .recaptcha-warning {
    margin-top: 30px;
    width: 70%;
    height: 36px;
    font-size: $font-size-wxs;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: $iron;
  }

  .hidden {
    visibility: hidden;
  }
}

@keyframes modalLoad {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  33% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  66% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
  }
}
