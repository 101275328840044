@import "../colors";
@import "../typography";
@import "../statuscolors";

.simulations-table {
  .status {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin: auto;
    margin-right: 10px;
    margin-left: 5px;
    top: 6px;
    position: relative;
    display: inline-block;
    float: left;

    &_running-backtest {
      border: 3px solid $black;
      top: 3px;
      background-color: $green;
      margin-left: 2px;
      margin-right: 7px;

      &--circle {
        width: 14px !important;
        height: 14px !important;
        position: absolute;
        top: -3px;
        left: -3px;
        color: $green;
        transform: rotate(-90deg) !important;
      }
    }
  }

  .neg {
    color: $red;
  }

  .pos {
    color: $pale-green;
  }

  .actions,
  .table-actions {
    display: flex;
    flex-direction: row-reverse;
    border-spacing: 0;
    margin-right: 10px;

    td {
      padding: 0px !important;
      border: 0px !important;
    }
    button {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }

    position: relative;
    top: 2px;

    a:not(:last-child) {
      margin-right: 0.5rem;
    }

    .show-settings,
    .delete {
      display: inline-block;

      img {
        display: block;
        width: 17px;
        height: 17px;
      }
    }

    .show-settings,
    .version {
      @media (max-width: 767.98px) {
        display: none;
      }
    }

    .stop {
      display: inline-block;
      width: 15px;
      height: 15px;
      background: $gray;

      position: relative;
      margin-right: 8px;
    }

    .restart {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 7.5px solid transparent;
      border-bottom: 7.5px solid transparent;
      border-left: 9px solid $gray;

      position: relative;
      top: -5px;
      margin-right: -3px;
    }

    .paper-trade-action {
      margin-top: 3px;
    }
  }

  .active {
    background: $dark-gray !important;
  }
}
