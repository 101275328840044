@import "../../styles/colors";
@import "../../styles/typography";

.form-body {
  &.execution-percentages {
    .non-percentage {
      margin-bottom: 10px;
    }

    .single-column:not(.non-percentage) {
      display: inline-flex;

      &.execution-info {
        &--container {
          background-color: #b3a0861a;
          border-radius: 5px;
          margin-top: 5px;
          margin-bottom: 5px;
          padding: 10px;

          .input-container {
            margin-top: 20px;
          }
        }
      }

      .execution-info {
        width: 150px;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-ss;
        color: $off-white;
        padding-right: 16px;

        &__strategy-name {
          display: flex;
          margin-bottom: 8px;
        }

        &.error {
          .strategy-name,
          .strategy-pair {
            color: $red-error;
          }
        }

        &.info-for-syndication {
          width: 240px;
          background-color: #b3a0861a;
          border-radius: 5px;
          padding: 10px;

          .strategy-name {
            max-width: 180px;
          }
        }

        .strategy-name {
          max-width: 135px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
          color: $white;
        }

        .strategy-version,
        .strategy-pair {
          font-weight: 500;
          color: $off-gray;

          span {
            color: $light-yellow;
          }
        }

        .input-container {
          &__tooltip {
            padding-top: 4px !important;
            flex: 1 1;
            margin-right: auto;
            max-width: 16px;
          }
        }
      }

      .input-container {
        width: 70px;
        padding-right: 5px;

        .MuiInputBase {
          &-root {
            width: 70px;
            padding-right: 10px;
            color: $off-white !important;

            input {
              padding-top: 9.5px !important;
            }
          }
        }

        .MuiFormHelperText {
          &-root {
            width: 120px;
            margin-left: -60px;
            text-align: right;
          }
        }
      }

      .pack-percentage {
        display: flex;
        width: 260px;
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-ss;
        text-align: center;
        padding-top: 5px;

        &.error {
          .info {
            color: $red-error;
          }
        }

        .title {
          margin-right: auto;
          color: $off-gray;
        }

        .info {
          margin-left: auto;
          color: $white;
        }
      }

      .remove {
        position: absolute;
        right: 7px;
        top: 8px;

        &:hover {
          cursor: pointer;
          filter: brightness(150%);
        }

        &.remove-for-syndication {
          margin-top: 2px;
        }
      }
    }
  }

  &.rebalance {
    .input-container {
      margin-top: -6px;
    }

    .MuiFormControlLabel {
      &-root {
        margin-right: 0;
      }
    }
  }
}

.execution-info {
  &__tooltip {
    padding: 10px;

    &--body {
      padding-bottom: 10px;
    }
  }
}
