@import "../../../styles/colors";
@import "../../../styles/typography";

.allow-list {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
  padding: 60px 0;

  @media (max-width: 767.98px) {
    padding: 30px 0;
    height: calc(100% - 60px);
  }

  .panel {
    &__inner {
      margin: 0;
      padding-left: 70px;
      padding-right: 70px;

      @media (max-width: 767.98px) {
        padding-left: 40px;
        padding-right: 55px;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &--modal {
    padding: 0 !important;
    background-color: $off-black !important;
    width: 620px;
    height: 600px;

    @media (max-width: 767.98px) {
      max-height: 100% !important;
    }

    &.mini {
      height: 380px;
    }
  }

  &__header {
    padding-left: 70px;
    padding-right: 70px;

    @media (max-width: 767.98px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    &--title {
      color: $light-gray;
      font-weight: 500;
      font-size: $font-size-xxl;
      line-height: 25px;
      margin-bottom: 10px;
    }

    &--subtitle {
      color: $off-white;
      font-size: $font-size-mdlg;
      width: 380px;
      max-width: 100%;
      line-height: 19px;
    }

    &__input {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      &--field {
        width: 340px;
        height: 40px;
        margin-right: 20px !important;
        margin-top: 10px !important;

        .MuiInputBase {
          &-root {
            height: 40px !important;
          }

          &-input {
            padding-top: 13px;
            padding-bottom: 11px;
          }
        }
      }

      &--button {
        &.MuiButtonBase-root {
          background-color: $pine !important;
          text-transform: none;
          color: $light-gray !important;
          height: 40px;
          width: 120px;
          margin-top: 10px;
          box-shadow: none;
        }

        &.Mui-disabled {
          opacity: 0.5;
        }

        .loading {
          color: $white;
        }

        img {
          margin-right: 5px;
        }
      }
    }

    &__share-button {
      &.MuiButtonBase-root {
        background-color: $pine !important;
        text-transform: none;
        color: $light-gray !important;
        height: 40px;
        padding: 8px 15px 4px;
        margin-top: 30px;
        box-shadow: none;
      }

      .loading {
        color: $white;
      }

      img {
        margin-right: 5px;
        margin-top: -5px;
      }
    }

    &__revoke-button {
      &.MuiButtonBase-root {
        text-transform: none;
        text-decoration: underline !important;
        color: $light-gray !important;
        height: 40px;
        padding: 8px 0 4px;
        box-shadow: none;
      }

      .loading {
        color: $white;
      }
    }
  }

  &__list {
    flex: 1 1;

    &--item {
      display: flex;
      flex-wrap: wrap;

      &:first-child {
        margin-top: 30px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $steel;
      }

      &:last-child {
        margin-bottom: 50px;
      }

      .nickname {
        color: $light-gray;
        height: 30px;
        font-size: $font-size-mdlg;
        margin: 10px auto auto 0;
      }

      .subscribed {
        color: $off-white;
        font-size: $font-size-sm;
        font-weight: 600;
        margin-top: 12px;
        margin-left: 10px;
      }

      .remove-button {
        &.MuiButtonBase-root {
          background-color: $pine;
          text-transform: none;
          color: $light-gray;
          height: 30px;
          padding-top: 8px;
          padding-bottom: 4px;
          box-shadow: none;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 10px;
          width: 90px;
        }

        &.Mui-disabled {
          opacity: 0.5;
        }

        .loading {
          color: $white;
        }
      }
    }
  }

  .close-button {
    background-color: $bronze !important;
    text-transform: none;
    font-size: $font-size-lg;
    font-weight: normal;
    color: $white;
    height: 45px;
    padding-top: 8px;
    padding-bottom: 4px;
    box-shadow: none;
    margin: auto 70px 0;

    @media (max-width: 767.98px) {
      margin: auto 40px 0;
    }
  }
}
