@import "../../styles/colors";
@import "../../styles/typography";

.infoIcon {
  display: inline-block;
  background-color: $gray;
  border: 1px solid $gray;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  font-size: 0.55rem;
  margin-left: 5px;
  padding: 0px;
  font-weight: bold;
  position: relative;
  opacity: 0.5;
}

#buy-hold-ratio {
  p {
    text-align: center;
  }
}

#risk-score {
  div {
    text-align: center;

    div {
      display: inline-flex;
    }

    .squared {
      transform: scale(0.7) translateY(-5px);
    }

    .multiplication {
      transform: scale(0.7);
    }
  }
}
