@import "../../styles/colors";
@import "../../styles/typography";

img {
  &.exchange-logo {
    & + .exchange-logo {
      margin-left: 10px;
    }
  }
  &.binance-logo {
    height: 14px;
  }

  &.bitmex-logo {
    height: 10px;
    padding: 2px 0;
  }

  &.bybit-logo {
    height: 9px;
    margin-top: 2px;
  }

  &.coinbase-logo {
    height: 14px;
  }

  &.binance-futures-logo {
    height: 16px;
  }

  &.ftx-logo {
    height: 12px;
    margin-bottom: 1px;
  }

  &.kraken-logo {
    margin-top: 1px;
    height: 12px;
  }

  &.huobi-global-logo {
    height: 13px;
    margin-bottom: 1px;
  }

  &.crypto-dot-com-logo {
    height: 15px;
  }
}

div {
  &.exchange-logo {
    margin-right: 15px;

    .transparent {
      opacity: 0.5;
      font-weight: 400;
      font-size: 11px;
      letter-spacing: 0.11em;
      text-transform: uppercase;
    }
  }

  &.bitmex-logo,
  &.binance-logo,
  &.bybit-logo,
  &.coinbase-logo,
  &.ftx-logo,
  &.kraken-logo,
  &.huobi-global-logo,
  &.crypto-dot-com-logo {
    img {
      padding: 0;
      margin-right: 5px;
    }

    span {
      font-size: 13px;
      font-weight: 600;
      color: $light-gray;
    }
  }

  &.binance-logo,
  &.coinbase-logo,
  &.crypto-dot-com-logo {
    img {
      margin-bottom: -2px;
    }
  }

  &.bybit-logo {
    img {
      margin-bottom: 0px;
    }
  }

  &.ftx-logo,
  &.kraken-logo {
    img {
      margin-bottom: -1px;
    }
  }

  &.huobi-global-logo {
    img {
      margin-bottom: 0;
    }
  }
}
