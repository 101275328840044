@import "../../../styles/colors";
@import "../../../styles/typography";

.script-info {
  &__metrics {
    & .script-info__metric {
      display: flex;
      padding: 10px;
      background-color: $off-black;
      margin-bottom: 10px;

      & .script-info__metric__label {
        display: inline;
        color: #656262;
        text-transform: uppercase;
        font-weight: 600;
        font-size: $font-size-sm;
        flex-grow: 1;
      }

      & .script-info__metric__value {
        color: $light-gray;
        text-align: right;
        font-size: $font-size-sm;

        .pos {
          color: $pale-green;
        }

        .neg {
          color: $red;
        }

        .liquidated {
          color: $blue;
        }
      }
    }

    &--header {
      display: flex;
      padding-top: 30px;
      padding-bottom: 30px;

      & .script-info__metric {
        display: block;
        flex: 1;
        background-color: transparent;
        margin-bottom: 0;
        padding: 10px 0;

        & .script-info__metric__label {
          display: block;
          flex-grow: unset;
        }

        & .script-info__metric__value {
          color: $light-gray;
          text-align: left;
          display: block;
          font-size: $font-size-xxl;
          line-height: $font-size-lg;
          padding-bottom: 10px;
          float: none;

          @media (max-width: 991.98px) {
            font-size: $font-size-xlg;
          }
        }
      }
    }
  }
}
