@import "../../../styles/colors";
@import "../../../styles/typography";

.user-page {
  .syndication {
    display: flex;
    width: calc(100% - 370px);
    flex-wrap: wrap;

    &__chart {
      width: calc(100% - 432px);
      height: 200px;
      position: relative;
      margin: 10px 40px 50px;

      &--tooltip {
        font-weight: 600;
        font-size: $font-size-xs;
        color: $off-white;
      }

      .recharts-area-area {
        fill-opacity: 0;
      }

      .recharts-dot {
        r: 8.5;
        stroke-width: 0;
        fill: #ffae48;
      }

      .recharts-tooltip-cursor {
        stroke: #4f4b47;
      }
    }

    &__chart-info {
      display: flex;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 26px;
      flex-wrap: wrap;
      width: calc(100% - 440px);

      & > * {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      &--redirect-button {
        display: flex;
        height: 30px;
        font-weight: 600;
        font-size: 12px;
        color: $light-gray;
        margin: 7px 10px 5px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        @media (max-width: 767.98px) {
          display: none;
        }

        img,
        div {
          margin-top: auto;
          margin-bottom: auto;
        }

        img {
          margin-right: 5px;
        }
      }

      &--button {
        height: 30px;
        background-color: $steel;
        color: $light-gray;
        font-weight: 500;
        padding: 2px 10px 0;
        margin-right: 10px;
        margin-top: 7px;
        text-transform: none;

        font-style: normal;
        border-radius: 5px;

        &.active {
          border: 1px solid $light-gray;
          height: 28px;
          background-color: transparent;
          color: $light-gray;
        }

        &:not(.active) {
          &:hover {
            background-color: $steel;
          }
        }
      }

      &__controls {
        display: flex;
        padding-top: 6px;
        height: 25px;

        &--button {
          font-weight: 600;
          padding-top: 1px;
          padding-left: 3px;
          padding-right: 3px;
          font-size: $font-size-xs;
          color: $steel;

          &:hover {
            cursor: pointer;
            color: $off-white;
          }

          &:not(:last-child) {
            margin-right: 29px;
          }

          &.active {
            color: $light-gray;
            border-bottom: 2px solid #9d6832;
          }
        }
      }

      &__returns {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding-left: 5px;
        padding-right: 5px;

        &--header {
          color: $light-gray;
          margin-right: 20px;
          font-weight: 500;
          font-size: $font-size-xs;
          padding-top: 7px;
        }

        &--percent {
          font-size: $font-size-xlg;
          color: $off-white;
          padding-top: 3px;
          margin-left: auto;

          &.pos {
            color: $light-green;
          }

          &.neg {
            color: $red;
          }
        }
      }
    }

    &__details {
      margin-right: auto;
      padding-left: 40px;
      padding-right: 40px;
      width: calc(100% - 440px);

      &__header {
        display: flex;
        justify-content: space-between;

        &--left {
          margin-right: 10px;

          .syndication-name {
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-xlg;
            color: $light-gray;
            padding-top: 20px;
            margin-bottom: 12px;

            img {
              vertical-align: middle;
              margin-top: -5px;
              margin-right: 5px;
            }
          }

          .syndication-subheader {
            display: flex;
            flex-wrap: wrap;

            .syndication-exchange {
              margin-right: 8px;
              margin-top: -5px;
            }

            .syndication-tags {
              .syndication-tag {
                font-style: normal;
                margin-bottom: 5px;
              }
            }
          }
        }

        &--right {
          background-color: $off-black;
          padding: 10px 20px;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          font-style: normal;
          font-weight: 500;
          font-size: $font-size-sm;
          color: $light-gray;
          flex-shrink: 0;
          height: 42px;
          border-radius: 15px;
          margin-top: 20px;

          &.no-leverage {
            height: 21px;
          }

          .syndication-leverage {
            display: flex;

            .icon {
              border: 1px solid #ede704;
              height: 14px;
              width: 14px;
              border-radius: 16px;
              color: #d1ce08;
              font-size: 12px;
              text-align: center;
              line-height: 16px;
              margin-top: 1px;
              margin-right: 15px;
              margin-left: 3px;
            }
          }

          .syndication-equity {
            img {
              margin-right: 13px;
              margin-bottom: -1px;
            }
          }

          .infoIcon {
            margin-top: 2px;
          }
        }
      }

      &__description {
        margin-top: 7px;

        &--body {
          color: $light-gray;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          margin-top: 15px;

          p {
            overflow: hidden;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: $bronze;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      &__overview {
        margin-top: 26px;
        display: flex;
        flex-wrap: wrap;

        &--row {
          margin-bottom: 10px;
        }

        &--header {
          width: 100%;

          font-style: normal;
          font-weight: 500;
          font-size: $font-size-mdlg;
          color: $light-gray;
          margin-bottom: 15px;
        }

        &--info {
          display: flex;
          align-items: center;
          width: 215px;
          margin-right: 40px;

          .bullet {
            height: 9px;
            width: 6px;
            background-color: $light-pine;
            margin-top: -3px;
            margin-right: 5px;
          }

          .label {
            color: $off-white;

            font-style: normal;
            font-weight: 500;
            font-size: $font-size-sm;
            margin-right: auto;
            padding-bottom: 1px;
            display: inline-flex;
            white-space: nowrap;

            .infoIcon {
              margin-top: 2px;
            }
          }

          .body {
            color: $light-gray;

            font-style: normal;
            font-weight: normal;
            font-size: $font-size-mdlg;
            margin-left: 10px;
          }
        }
      }
    }

    &__side {
      height: 238px;
      width: 320px;
      padding: 30px 20px 20px;
      margin-top: 30px;
      background-color: $off-black;
      flex-shrink: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;

      &.subscribed {
        height: 355px;
      }

      &--left {
        &.subscribers {
          visibility: hidden;
        }
      }

      &--right {
        .info,
        .header {
          text-align: right;
        }
      }

      &--container {
        width: 360px;
      }

      &--allocated-bar {
        height: 12px;
        margin-bottom: 10px;
        background-color: #4e5252;
        border-radius: 10px;
        position: relative;

        .highlighted-bar {
          position: absolute;
          height: 12px;
          border-radius: 10px;

          .bar {
            height: 12px;
            border-radius: 10px;
            background: repeating-linear-gradient(
              -45deg,
              $bright-yellow,
              $bright-yellow 5px,
              #dfddc8 5px,
              #dfddc8 10px
            );
            animation: fullyIncrease 2s;
          }

          &.fully-allocated {
            .bar {
              background: $bright-green;
              animation: yellowToGreen 2s, fullyIncrease 2s;
            }
          }
        }
      }

      &--allocation {
        color: $off-white;

        font-style: normal;
        font-weight: 500;
        text-align: right;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        .allocation-percent {
          color: $light-gray;
          font-weight: 600;

          &.fully-allocated {
            color: $bright-green;
            animation: lightGrayToGreenColor 2s;

            img {
              height: 16px;
            }
          }

          img {
            margin-bottom: -2.5px;
            margin-right: 5px;
          }
        }

        .allocation-fraction {
          color: $off-white;

          .fully-allocated {
            color: $light-green;
            animation: yellowToGreenColor 2s;
          }

          .partially-allocated {
            color: $light-yellow;
          }
        }
      }

      &--row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .info {
          color: $light-gray;

          font-style: normal;
          font-weight: normal;
          font-size: $font-size-xlg;
          margin-bottom: 5px;
        }

        .header {
          color: $off-white;

          font-style: normal;
          font-weight: 500;
          font-size: $font-size-md;
        }
      }

      &--subscribed {
        width: calc(100% - 40px);
        color: $off-white;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        background-color: #264e5a4d;
        line-height: 13px;
        align-self: center;
        padding: 9px 20px 6px;

        &.has-live-runs {
          padding-left: 9px;
          padding-right: 9px;
          width: calc(100% - 18px);
        }

        span {
          text-decoration: underline;

          &:hover {
            color: $white;
            cursor: pointer;
          }
        }
      }

      &--license-button {
        height: 45px;
        width: 100%;
        background-color: $bronze;
        color: $white;

        font-style: normal;
        font-weight: normal;
        font-size: $font-size-mdlg;
        text-transform: none;
        padding-top: 7px;
        padding-bottom: 5px;
        margin-top: auto;
        box-shadow: unset;
        border-radius: 5px;

        &:hover {
          background-color: $bronze;
        }

        &.disabled {
          user-select: none;
          pointer-events: none;
          background-color: rgba(85, 85, 85, 0.5);
          color: $off-white;
        }

        &.has-subscriptions {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          & + .syndication__side--subscribed {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
      }

      &--info {
        display: flex;
        color: $off-white;

        font-style: normal;
        font-weight: 500;
        font-size: $font-size-sm;

        img {
          margin-bottom: auto;
          margin-top: 18px;
        }

        div {
          padding: 15px;
        }
      }

      &--close-button {
        position: absolute;
        top: 15px;
        right: 15px;
      }

      .infoIcon {
        transform: translateY(-1px);
      }
    }
  }

  .syndication-tags,
  .syndication-card--tags {
    display: inline-flex;
    flex-wrap: wrap;

    .syndication-tag {
      height: 16px;
      background-color: $light-orange;
      color: $dark-pine;
      font-size: $font-size-sm;
      padding: 0 5px;
      margin-right: 5px;
      font-weight: 600;

      &.gray {
        background-color: $steel;
      }

      &.white {
        background-color: $white;
      }

      &.multi-coin {
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: 1599.98px) {
  .user-page {
    .syndication {
      width: calc(100% - 260px);
    }
  }
}

@media (max-width: 1474.98px) {
  .user-page {
    .syndication {
      width: calc(100% - 270px);

      &__chart {
        width: calc(100% - 72px);
      }

      &__chart-info {
        width: calc(100% - 80px);
      }

      &__details {
        &__overview {
          &--row {
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .user-page {
    .syndication {
      &__details {
        &__header {
          flex-direction: column;

          &--left {
            .syndication-name {
              margin-bottom: 5px;
            }
          }

          &--right {
            margin-bottom: 0px;
            margin-top: 10px;
            margin-right: auto;
          }
        }

        &__description {
          margin-top: 14px;
        }
      }

      &__side {
        padding: 25px 15px 15px;

        &--container {
          width: 350px;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .user-page {
    .syndication {
      width: calc(100% - 250px);

      &__details {
        width: calc(100% - 80px);
        padding-right: 40px;
      }

      &__side {
        margin: auto;
        padding: 35px 20px 20px;
        max-width: calc(100% - 40px);
        border-radius: 0;
        height: unset !important;

        &--container {
          position: fixed !important;
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 2;
          background-color: rgba(17, 17, 17, 0.9);
        }

        &--info {
          display: none;

          img {
            margin-top: 8px;
          }

          div {
            padding: 5px 5px 5px 15px;
          }
        }
      }

      &__mobile-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 75px;
        background-color: #0c171c;
        display: flex;
        align-items: center;

        &--allocation-container {
          margin-right: auto;
          margin-left: 5%;
          width: 35%;

          .allocation-percent {
            color: $light-gray;
            font-weight: 600;

            &.fully-allocated {
              color: $bright-green;
              animation: lightGrayToGreenColor 2s;

              img {
                height: 16px;
              }
            }

            img {
              margin-bottom: -2.5px;
              margin-right: 5px;
            }
          }
        }

        &--allocated-bar {
          height: 6px;
          margin-top: 5px;
          background-color: #4e5252;
          border-radius: 10px;
          position: relative;

          .highlighted-bar {
            height: 6px;
            border-radius: 10px;
            position: absolute;

            .bar {
              height: 6px;
              border-radius: 10px;
              background-color: $bright-yellow;
              animation: fullyIncrease 2s;
            }

            &.fully-allocated {
              .bar {
                background-color: $bright-green;
                animation: yellowToGreen 2s, fullyIncrease 2s;
              }
            }
          }
        }

        &--license-button {
          height: 45px;
          background-color: $bronze;
          color: $white;

          font-style: normal;
          font-weight: normal;
          font-size: $font-size-mdlg;
          text-transform: none;
          border-radius: 50px;
          margin-right: 5%;
          margin-top: 1px;
          margin-left: auto;
          padding-top: 9px;

          &:hover {
            background-color: $bronze;
          }

          &.disabled {
            user-select: none;
            pointer-events: none;
            background-color: rgba(85, 85, 85, 0.5);
            color: $off-white;
          }
        }
      }

      &__chart {
        margin-bottom: 125px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .user-page {
    .syndication {
      width: 100%;

      &__details,
      &__chart-info {
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% - 40px);
      }

      &__details {
        &__overview {
          &--info,
          &--row {
            width: 100%;
            margin-right: 0;
          }
        }
      }

      &__chart {
        margin-left: 20px;
        margin-right: 20px;
        width: calc(100% - 40px);
      }
    }
  }
}

@media (max-width: 515.98px) {
  .user-page {
    .syndication {
      &__chart-info {
        &__returns {
          margin-left: 0;
        }
      }
    }
  }
}
