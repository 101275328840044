@import "../../styles/colors";
@import "../../styles/typography";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $transparent-black;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .modal {
    &:focus-within,
    &:focus {
      outline: none;
    }

    padding: 2em;
    position: relative;
    background-color: $black;
    overflow-y: auto;
    z-index: 100;
    max-height: calc(100% - 80px);

    .button {
      margin: auto;

      &.button--,
      &.bottom-gap {
        margin-bottom: 1em;
      }
    }

    .label {
      font-weight: 600;
      padding-bottom: 2em;
      display: block;
    }

    .form__field {
      width: 100%;
      margin-bottom: 20px;

      label {
        display: block;
        text-transform: none;
        font-size: $font-size-sm;
        color: $gray;
        position: relative;
        white-space: nowrap;
      }

      input {
        min-width: 300px;
        color: $off-white;
      }
    }

    &__close-btn {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
      position: absolute;
      top: 20px;
      right: 20px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    &.confirmation-modal {
      border-radius: 5px;
      background-color: $off-black;
      padding: 60px 70px;

      @media (max-width: 997.98px) {
        padding: 20px 20px;
      }

      &.small {
        padding: 40px;
      }

      &.extra-width {
        width: 100%;
        max-width: 400px;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 20px;

        &:hover {
          cursor: pointer;
        }
      }

      .header {
        font-size: $font-size-xxl;
      }

      .label {
        font-size: $font-size-md;
        font-weight: normal;
        color: $off-white;
        padding-top: 10px;
        line-height: 1.35;

        span {
          font-weight: 600;
          color: $light-gray;
        }
      }

      .button-group {
        @media (min-width: 768px) {
          display: flex;

          .button {
            width: unset;
            min-width: 200px;
            margin: 0;
            border-radius: 5px;

            &--primary {
              margin-right: 20px;
            }
          }
        }

        @media (max-width: 767.98px) {
          .button {
            &--primary {
              margin-bottom: 10px;
            }
          }
        }
      }

      .button {
        border: 0;
        border-radius: 5px;
        padding: 10px 40px;
        color: $light-gray;
        height: 40px;

        &--secondary {
          background-color: $red;
        }

        &.close-button {
          background-color: $bronze;
          max-width: 480px;
        }
      }

      .footer {
        font-size: $font-size-md;
        color: $off-white;
        margin-bottom: 75px;

        .bold {
          font-weight: bold;
        }
      }
    }

    &.settings-page {
      height: auto;
      width: auto;
    }

    &.billing-info-modal {
      padding: 20px 20px;
    }
  }
}
