@import "../../styles/colors";
@import "../../styles/typography";

.recaptcha-container {
  margin-top: 20px;
  z-index: 1;
  position: relative;
  animation: modalLoad 4s cubic-bezier(0, 0.26, 0.12, 0.97);

  &.top-gap {
    margin-top: 40px;
  }
}
