@import "../../styles/colors";
@import "../../styles/typography";

.subscription-congratulations {
  width: 370px;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 767.98px) {
    width: 100%;
  }

  &--container {
    background-color: $white !important;
  }

  &--header {
    color: $bold-pine;
    font-weight: 600;
    font-size: $font-size-xxl;
  }

  &--subheader {
    color: $steel;
    font-weight: 500;
    font-size: $font-size-md;
    margin-bottom: 25px;
  }

  &--description {
    color: $steel;
    font-size: $font-size-xs;
    text-align: left;
    margin-bottom: 20px;

    span {
      font-weight: bold;
    }
  }

  &--footer {
    color: $off-white;
    font-size: $font-size-ss;
    text-align: left;
    margin-bottom: 30px;
  }

  &--button {
    width: 115px;
    height: 30px;
    margin: auto !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: $font-size-ss !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    background-color: $pine !important;
    color: $white !important;
    text-transform: none !important;
    box-shadow: unset !important;

    &:hover {
      background-color: $cantaloupe !important;
    }
  }

  &--image {
    height: 62px;
  }
}
