@import "../../../styles/colors";
@import "../../../styles/typography";

.user-page {
  &__breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;

    &--link {
      font-size: $font-size-md;
      font-weight: 500;

      a {
        color: $light-gray;
      }

      &:hover {
        text-decoration: underline;
      }

      &.current {
        a {
          color: $off-white;
        }
      }

      img {
        margin: 0 10px;
      }
    }
  }
}
