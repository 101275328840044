@import "../../styles/colors";
@import "../../styles/typography";

.batch-limit-container {
  width: 900px;
  height: 550px;
  background-color: $off-black !important;
  padding: 0 !important;
}

.batch-limit {
  padding: 65px 10px 10px 10px;

  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  div {
    text-align: center;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: $light-gray;
  }

  .sub-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $off-white;
  }

  .body {
    background: $black;
    width: 270px;
    height: 270px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 25px;

    .plan {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      padding-top: 35px;
      padding-bottom: 16px;
      color: #dadada;
    }

    .price {
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 52px;
      text-decoration: line-through;
      color: #dadada;
    }

    .period {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      color: #dadada;
    }

    .info {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: $off-white;
    }

    .change-plan {
      width: 200px;
      margin-top: 22px;

      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      background: $bronze;
      color: $white;
      text-transform: none;
    }
  }

  .footer {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: $steel;
  }

  .current-plan {
    position: absolute;
    left: 10px;
    bottom: 5px;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: $off-white;

    span {
      font-weight: 500;
      color: $camel;
    }
  }
}
