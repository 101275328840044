@import "../../../styles/colors";
@import "../../../styles/typography";

.modal-overlay {
  .modal {
    &.confirmation-modal {
      .header {
        & + .download-option {
          margin-top: 60px;
        }
      }

      .download-option {
        display: flex;
        padding-bottom: 20px;

        @media (max-width: 767.98px) {
          flex-direction: column;
        }

        &:not(.hide-border) {
          border-bottom: 1px solid $steel;
          margin-bottom: 20px;
        }

        &.hide-border {
          margin-bottom: 60px;
        }

        &__info {
          margin-right: auto;
          max-width: 300px;

          @media (min-width: 768px) {
            padding-right: 50px;
          }

          &--title {
            font-weight: 600;
            font-size: $font-size-mdlg;
          }

          &--description {
            color: $off-white;
            font-size: $font-size-md;
          }
        }

        &--button {
          &.button {
            height: 40px;
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
            margin-top: 15px;
            margin-left: 0;
            margin-right: 0;
            flex-shrink: 0;

            @media (max-width: 767.98px) {
              flex-shrink: 1;
              height: unset;
              margin-bottom: auto;
            }

            & + .download-option--button {
              @media (min-width: 768px) {
                margin-left: 10px;
              }
            }

            img {
              margin-bottom: -2px;
              margin-right: 8px;
            }

            div {
              display: inline-block;
              padding-top: 1px;
            }
          }
        }
      }
    }
  }
}
