@import "../../styles/colors";
@import "../../styles/typography";

.connect-exchange {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  padding: 0 15px;

  &:not(.wide) {
    width: 285px;
  }

  &--title {
    color: $white;
    font-size: 20px;
    font-weight: 600;
    margin: 0 auto;
  }

  &--subtitle {
    color: $off-white;
    font-size: 13px;
    font-weight: 500;
    margin: 0 auto 20px;
  }

  &--info {
    color: $off-white;
    font-size: $font-size-mdlg;
    font-weight: 600;
    margin: 0 auto 20px;

    img {
      height: 19px;
      width: 19px;
      margin-bottom: -3px;
      margin-right: 10px;
    }
  }

  &--button {
    height: 30px;
    border-radius: 5px;
    background-color: $bronze;
    color: $white;
    font-size: $font-size-ss;
    font-weight: 600;
    padding: 0 35px;
    margin: 0 auto;

    &:hover {
      background-color: $yellow;
    }
  }
}
