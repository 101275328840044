@import "../../styles/colors";

@import "fields/boolean/boolean";
@import "fields/labeled";

.form {
  &--reversed {
    display: flex;
    flex-direction: column-reverse;
  }

  &--dark {
    &.create-announcement {
      textarea {
        min-height: 100px;
      }
    }

    span.error {
      color: $red;

      &--block {
        display: block;
      }
    }

    label {
      display: block;
      color: $gray;
      font-size: $font-size-sm;
      letter-spacing: 0.035rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      font-weight: 600;
    }

    input,
    textarea {
      background: $form__input--dark-background-color;
      border: none;
      box-sizing: border-box;
      color: $white;
      font-size: $font-size-sm;
      letter-spacing: 0.05rem;
      padding: 0.75rem 1rem;
      width: 100%;
      resize: none;
    }
  }

  &__field {
    &__desc,
    &__error {
      font-size: $font-size-sm;
      font-weight: 500;
    }

    &__error {
      color: $red;
    }

    &__desc {
      color: $steel;
    }

    &--code {
      pre {
        counter-reset: line;
      }

      pre,
      textarea {
        color: $white;
        font-size: $font-size-sm;
        font-family: "Droid Sans Mono";
      }

      .editor_line:before {
        opacity: 0.25;
        position: absolute;
        margin-left: -40px;
        user-select: none;
        counter-increment: line;
        content: counter(line);
        text-align: right;
        width: 25px;
      }
    }

    &--scroll-wrapper {
      flex: 1;
      background: $off-black;
      position: relative;
      width: 100%;
      height: 100%;

      color: $off-white;
      font-family: "Droid Sans Mono";
      font-size: $font-size-sm;
    }
  }
}
